import { IOrder } from "application/models/IOrder";
import { useState } from "react";
import { CustomerDetails } from "ui/models/customerDetails";

export function useOrderSlice() {
  const [currentOrder, setCurrentOrder] = useState<IOrder>();
  const [customerDetails, setCustomerDetails] = useState<CustomerDetails>();

  const clearOrder = () => {
    setCustomerDetails(undefined);
    setCurrentOrder(undefined);
  };

  const slice: IOrderSlice = {
    state: {
      currentOrder,
      customerDetails,
    },
    dispatch: {
      updateCustomerDetails: setCustomerDetails,
      setCurrentOrder,
      clearOrder
    },
  };

  return slice;
}

export interface IOrderSlice {
  state: IOrderState;
  dispatch: IOrderDispatch;
}

export interface IOrderState {
  customerDetails: CustomerDetails | undefined;
  currentOrder: IOrder | undefined;
  
}

export interface IOrderDispatch {
  updateCustomerDetails: (data: CustomerDetails | undefined) => void;
  setCurrentOrder: (data: IOrder | undefined) => void;
  clearOrder: () => void;
}

import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IProductBomItem } from "../../../../application/models/IBomItem";
import { ROUTES } from "../../../app-routes/routeConfig";
import { priceDisplay } from "../../../helpers/priceDisplay";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import { useWorkPanelContext } from "../context";
import UP_ARROW_ICON from "assets/bxs_up-arrow.svg";
import DOWN_ARROW_ICON from "assets/bxs_down-arrow.svg";

type ChildEntry = IProductBomItem & {
  id: string;
};

export function OrderSummary() {
  const context = useWorkPanelContext();
  const navigate = useNavigate();

  const { setScreen, customerDetails } = context;

  const [bomItems, setBomItems] = useState<IProductBomItem[]>([]);
  const [expand, setExpand] = useState<string>("");
  const [dropdown, setDropdown] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      const bom = await context.getBoM();

      if (bom.length > 0 && bomItems.length <= 0) {
        setBomItems((prev) => [...bom]);
      }
    })();
  }, [context.getSortedWorkstationInstances()]);

  const confirmSale = async () => {
    if (customerDetails == null) {
      alert("Please fill out customer details!!!");
    } else {
      await context.handleSendOrder();
      navigate(ROUTES.SALES_PROCESS.REVIEW.ORDER_SUCCESS.path);
    }
  };

  const formatSummary = () => {
    const output: {
      [key: string]: ChildEntry[];
    } = {};

    bomItems.forEach((childItem) => {
      const { productId } = childItem;

      const matchingParent = context.workstationTypes.find((parentItem) => {
        return parentItem.components.some(
          (component) => component.productId === productId
        );
      });

      if (matchingParent) {
        const { name, id } = matchingParent;
        const newItem = { id, ...childItem };

        if (!output[name]) {
          output[name] = [newItem];
        } else {
          output[name].push(newItem);
        }
      }
    });

    return output;
  };

  return (
    <>
      <div className="cart-items">
        <ul>
          {Object.keys(formatSummary()).map((item) => (
            <li>
              {formatSummary()[item].length > 1 ? (
                <>
                  <Box
                    component={"div"}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setExpand(item);
                      setDropdown(!dropdown);
                    }}
                  >
                    <img
                      src={
                        expand === item && dropdown
                          ? DOWN_ARROW_ICON
                          : UP_ARROW_ICON
                      }
                      alt="arrow"
                    />
                    <Typography
                      component={"h5"}
                      sx={{
                        fontSize: 16,
                        fontWeight: "bold",
                        marginLeft: "5px",
                      }}
                    >
                      {item} ({formatSummary()[item].length})
                    </Typography>
                    <Typography component={"p"} sx={{ textAlign: "end" }}>
                      {formatSummary()[item].reduce((a, b) => a + b.total, 0)}{" "}
                      USD
                    </Typography>
                  </Box>
                  <Box
                    component={"div"}
                    sx={{
                      paddingLeft: 2.5,
                      paddingBottom: 1,
                    }}
                  >
                    {formatSummary()[item].map((items) => (
                      <Box
                        component={"div"}
                        sx={{
                          display:
                            expand === item && dropdown ? "flex" : "none",
                          alignItems: "center",
                          justifyContent: "space-between",
                          borderLeft: "1px solid #cccccc",
                          paddingLeft: 1.5,
                        }}
                      >
                        <p>
                          {items.title} ({items.quantity})
                        </p>
                        <p>
                          {priceDisplay({
                            amount: items.total,
                            currencySymbol: items.currencySymbol,
                          })}
                        </p>
                      </Box>
                    ))}
                  </Box>
                </>
              ) : (
                <Box
                  component={"div"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    component={"h5"}
                    sx={{ fontSize: 16, fontWeight: "bold" }}
                  >
                    {item} ({formatSummary()[item][0].quantity})
                  </Typography>
                  <Typography component={"p"} sx={{ textAlign: "end" }}>
                    {priceDisplay({
                      amount: formatSummary()[item][0].total,
                      currencySymbol: formatSummary()[item][0].currencySymbol,
                    })}
                  </Typography>
                </Box>
              )}
            </li>
          ))}
        </ul>
        <div className="total-amount">
          <h5>Total</h5>
          <h5>{priceDisplay(context.productionLineTotalPrice)}</h5>
        </div>
      </div>
      <div className="review-footer">
        <Button onClick={confirmSale}>Confirm and Send</Button>
        <div className="go-back">
          <Link
            to={"#"}
            onClick={() => {
              setScreen("equipmentInstanceList");
            }}
          >
            <ChevronLeft color="info" /> Go Back to Cart
          </Link>
        </div>
      </div>
    </>
  );
}

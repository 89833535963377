import CLOSE from "../../../assets/close.svg";
import { BaseHeader } from "./BaseHeader";
import { Box, Button } from "@mui/material";
import { useHistoryGoBack } from "../hooks/historyGoBack";

export function SalesProcessHeader() {
  const goBack = useHistoryGoBack();
  return (
    <BaseHeader title={"New Project"}>
      <Box
        component={"div"}
        sx={{ textAlign: "end", display: "flex", alignItems: "center" }}
      >
        <Button onClick={() => goBack()} className="header-button">
          <img src={CLOSE} alt="close" className="img-fluid" /> Cancel
        </Button>
      </Box>
    </BaseHeader>
  );
}

export default SalesProcessHeader;

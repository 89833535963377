import { useState } from "react";
import { IWorkstationInstance } from "../../../../application/models/IWorkstationInstance";
import { IProductionLineService } from "../../../../application/services/ProductionLineService";
import { IWorkstationInstanceService } from "../../../../application/services/WorkstationInstanceService";
import { generateUUID } from "three/src/math/MathUtils";
import { IConfigurationService } from "application/services/ConfigurationService";
import { User } from "ui/auth/User";
import OrthonormalVector from "common/math/OrthonormalVector";
import { useNavigate } from "react-router-dom";
import { configurationMapper } from "application/mappers/configurationMapper";

export function useWorkstationInstanceSlice(
  user: User | undefined,
  services: {
    workstationInstanceService: IWorkstationInstanceService;
    productionLineLayoutService: IProductionLineService;
    configurationService: IConfigurationService;
  }
): IWorkstationInstanceSlice {
  const navigate = useNavigate();

  const {
    workstationInstanceService,
    productionLineLayoutService,
    configurationService,
  } = services;

  const [pending, setPending] = useState(false);
  const [selectedWorkstationInstanceId, updateSelectedWorkstationInstanceId] =
    useState<string | null>(null);
  const [focusedWorkstationId, updateFocusedWorkstationId] = useState<string>();
  const [workstationInstances, updateWorkstationInstances] = useState<
    IWorkstationInstance[]
  >([]);
  const [selectedConnector, setSelectedConnector] = useState<{
    workstationId: string;
    direction: OrthonormalVector;
  }>();

  function handleStationRemove(workstationInstanceId: string) {
    const result = productionLineLayoutService.removeEquipmentInstance(
      workstationInstanceId,
      workstationInstances
    );

    updateWorkstationInstances(result);
  }

  function handleStationRotationChange(id: string, rad: number) {
    updateWorkstationInstances((prev) => {
      const updated = prev.map((x) => {
        if (x.id === id) {
          x.rotationRad = rad;
        }
        return x;
      });

      return updated;
    });
  }

  function repositionWorkstationInstance(
    x: number,
    z: number,
    workstationId: string
  ): void {
    updateWorkstationInstances((prev) => {
      const updated = prev.map((item) => {
        if (item.id === workstationId) {
          item = workstationInstanceService.reposition(item, x, z);
        }

        return item;
      });

      return updated;
    });
  }

  async function handleStationTypeChange(
    workstationInstanceId: string,
    workstationTypeId: string
  ): Promise<void> {
    (async () => {
      const updated: IWorkstationInstance[] = [];

      for (const x of workstationInstances) {
        let updatedInstance = x;

        if (x.id === workstationInstanceId) {
          updatedInstance = await workstationInstanceService.changeStationType(
            x,
            workstationTypeId
          );
        }

        updated.push(updatedInstance);
      }

      updateWorkstationInstances(updated);
    })();
  }

  function setConfiguration(equipments: IWorkstationInstance[]) {
    updateWorkstationInstances(equipments);
  }

  async function loadConfiguration(configurationId: string, asClone?: boolean) {
    let configuration = await configurationService.get(
      configurationId,
      user!.id
    );

    if (asClone && configuration) {
      configuration = configurationMapper.cloneConfiguration(configuration);
    }

    if (configuration) {
      updateWorkstationInstances(configuration.equipments);
    } else {
      alert("error loading configuration!");
    }
  }

  function clearConfiguration() {
    updateWorkstationInstances([]);
  }

  function handleConnectorClick(
    workstationId: string,
    direction: OrthonormalVector
  ) {
    setSelectedConnector({ workstationId, direction });
  }

  const state: IWorkstationInstanceState = {
    focusedWorkstationId,
    pending,
    selectedWorkstationInstanceId,
    workstationInstances,
    selectedConnector,
  };

  return {
    state,
    dispatch: {
      handleConnectorClick,
      handleStationRemove,
      handleStationRotationChange,
      handleStationTypeChange,
      repositionWorkstationInstance,
      updateFocusedWorkstationInstanceId: updateFocusedWorkstationId,
      updateSelectedWorkstationInstanceId,
      updateWorkstationInstances,
      setConfiguration,
      loadConfiguration,
      clearConfiguration,
      setSelectedConnector,
    },
  };
}

export interface IWorkstationInstanceDispatch {
  handleConnectorClick: (
    workstationId: string,
    direction: OrthonormalVector
  ) => void;
  repositionWorkstationInstance(
    x: number,
    z: number,
    workstationId: string
  ): void;
  updateWorkstationInstances: React.Dispatch<
    React.SetStateAction<IWorkstationInstance[]>
  >;
  handleStationRemove: (workstationInstanceId: string) => void;
  handleStationRotationChange: (
    workstationInstanceId: string,
    rad: number
  ) => void;
  updateSelectedWorkstationInstanceId: (id: string | null) => void;
  updateFocusedWorkstationInstanceId: (x: string) => void;
  handleStationTypeChange: (
    workstationInstanceId: string,
    workstationTypeId: string
  ) => void;
  setConfiguration: (equipments: IWorkstationInstance[]) => void;
  loadConfiguration: (id: string, asNew?: boolean) => Promise<void>; //TODO - configuration id should not be a concern for config template
  clearConfiguration: () => void;
  setSelectedConnector: (
    connector:
      | {
          workstationId: string;
          direction: OrthonormalVector;
        }
      | undefined
  ) => void;
}

export interface IWorkstationInstanceState {
  workstationInstances: IWorkstationInstance[];
  focusedWorkstationId: string | undefined;
  selectedWorkstationInstanceId: string | null;
  pending: boolean;
  selectedConnector:
    | {
        workstationId: string;
        direction: OrthonormalVector;
      }
    | undefined;
}

export interface IWorkstationInstanceSlice {
  dispatch: IWorkstationInstanceDispatch;
  state: IWorkstationInstanceState;
}

import { useState } from "react";
import { generateUUID } from "three/src/math/MathUtils";
import { ROUTES } from "../../app-routes/routeConfig";
import { WorkstationList } from "./components/WorkstationList";
import { WorkstationTypeTiles } from "./components/WorkstationTypeTiles";
import { useWorkPanelContext, WorkPanelContextProvider } from "./context";

import { ReviewOrder } from "./components/ReviewOrder";
import { useParentMatch } from "../../shared/hooks/useParentRouteMatch";
import ReviewTemplate from "./components/ReviewTemplate";

type Variant = "template" | "sales";

export default function WorkPanel() {
  return (
    <WorkPanelContextProvider>
      <Layout />
    </WorkPanelContextProvider>
  );
}

function Layout() {

  const isTemplate = useParentMatch(ROUTES.CATALOGUE.CONFIGURATION_TEMPLATE);
  const isSales = useParentMatch(ROUTES.SALES_PROCESS);

  const { selectedScreen } = useWorkPanelContext();

  let variant: Variant | undefined;

  if (isTemplate) {
    variant = "template";
  }

  if (isSales) {
    variant = "sales";
  }

  switch (variant) {
    case "sales":
      break;

    case "template":
      break;

    default:
      break;
  }

  //setting draggable key will reset it to original position
  const [draggableKey, setDraggableKey] = useState<string>(generateUUID());

  let contents: JSX.Element | undefined;

  contents = <WorkstationList />;

  switch (selectedScreen) {
    case "equipmentDefinitionTiles":
      contents = <WorkstationTypeTiles />;
      break;

    case "equipmentInstanceList":
      contents = <WorkstationList />;
      break;

    case "orderReview":
      contents = <ReviewOrder />;
      break;

    case "templateReview":
        contents = <ReviewTemplate />;
        break;

    default:
      break;
  }

  return contents;
}
import { createContext, useContext, useState } from "react";
import { ROUTES } from "../app-routes/routeConfig";
import { useNavigate } from "react-router-dom";
import { AuthenticateResponse, RoleEnum, UserApi } from "../../infrastructure/api-client";
import { get as lsGet, set as lsSet, remove as lsRemove } from "local-storage";
import { commonApiConfiguration } from "../../infrastructure/hooks/commonApiConfiguration";
import { User } from "./User";

const LS_SESSION_KEY = "auth_session";

interface IAuthContext {
  user: User | undefined;
  loginWithSso: () => Promise<void>;
  authenticateStoredSession: () => Promise<{ isSuccess: boolean }>;
  refreshToken: () => Promise<{ isSuccess: boolean }>;
  getAccessTokenExpirationTS: () => Date | undefined;
  logout: (isRedirect?: boolean) => void;
}

const AuthContext = createContext<IAuthContext | undefined>(undefined);

interface Session {
  userId: string;
  tokenExpiry: Date | string;
  userName: string;
}

export function AuthContextProvider(props: {
  children: JSX.Element | JSX.Element[];
}) {
  const navigate = useNavigate();
  const { children } = props;
  const [currentUser, setCurrentUser] = useState<User>();

  const setSessionData = (session: Session) => {
    lsSet<Session>(LS_SESSION_KEY, session);
  };

  const getSessionData = () => {
    const session = lsGet<Session | undefined>(LS_SESSION_KEY);

    if (session) {
      session.tokenExpiry = new Date(session.tokenExpiry);
    }

    return session;
  };

  const getAccessTokenExpirationTS = () => {
    const expiry = getSessionData()?.tokenExpiry;

    if (expiry) {
      return new Date(expiry);
    }

    return undefined;
  };

  const logout = (isRedirect = true) => {
    lsRemove(LS_SESSION_KEY);
    setCurrentUser(undefined);
    if (isRedirect) navigate(ROUTES.LOGOUT.path);
  };

  const configuration = commonApiConfiguration;

  const [userApi] = useState(new UserApi(configuration));

  const authenticateStoredSession = async () => {
    const oldSession = getSessionData();

    try {
      let loginData: AuthenticateResponse | undefined;

      const response = await userApi.userRefreshTokenPost();
      loginData = response.data;

      if (
        response.status == 200 &&
        loginData?.userId != null &&
        loginData?.accessTokenExpiration != null &&
        oldSession
      ) {
        const newSession = {
          tokenExpiry: loginData.accessTokenExpiration,
          userId: loginData.userId.toString(),
          userName: loginData.userName ?? "user",
        };

        if (oldSession.userId != newSession.userId) {
          //userId changed. can't continue this session
        } else {
          setSessionData(newSession);

          setCurrentUser({
            id: newSession.userId,
            loginName: newSession.userName,
            role: loginData.role
          });

          return { isSuccess: true };
        }
      }
    } catch {
      //TODO: intercept error
    }

    return { isSuccess: false };
  };

  const loginWithSso = async () => {
    lsRemove(LS_SESSION_KEY);

    const url = new URL(window.location.href);
    const params = url.searchParams;

    const tokenString = params?.get("token");

    if (tokenString && tokenString.length > 0) {
      try {
        const loginResponse = await userApi.userSsoLoginPost({
          headers: {
            Authorization: tokenString,
          },
        });

        const loginData = loginResponse.data;

        if (
          loginResponse.status == 200 &&
          loginData?.userId != null &&
          loginData?.accessTokenExpiration != null
        ) {
          const session = {
            tokenExpiry: loginData.accessTokenExpiration,
            userId: loginData.userId.toString(),
            userName: loginData.userName ?? "user",
          };

          setSessionData(session);

          setCurrentUser({
            id: session.userId,
            loginName: session.userName,
            role: loginData.role
          });

          navigate(ROUTES.WELCOME_SCREEN.path);
          return;
        }
      } catch (ex) {

      }
      logout();
    }
  };

  return (
    <>
      <AuthContext.Provider
        value={{
          user: currentUser,
          loginWithSso,
          authenticateStoredSession,
          refreshToken: authenticateStoredSession,
          getAccessTokenExpirationTS,
          logout,
        }}
      >
        {children}
      </AuthContext.Provider>
    </>
  );
}

export function useAuthContext() {
  const ctx = useContext(AuthContext);

  if (!ctx) {
    throw new Error(
      "Auth Context must be requested from AuthContextProvider!!!"
    );
  }

  return ctx;
}

interface SSOToken {
  username: string;
  userId: string;
}

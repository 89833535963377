import { injected } from "brandi";
import { Price } from "../../common/models/price";
import DI_TOKENS from "../../DI_TOKENS";
import { IWorkstationInstance } from "../models/IWorkstationInstance";
import { IProductService } from "./ProductService";
import { IWorkstationTypeService } from "./WorkstationTypeService";

export interface IPriceCalculationService {
  getProductPrice(
    id: string
  ): Promise<{ amount: number; currencySymbol: string } | null>;

  getWorkstationInstancePrice(
    model: IWorkstationInstance
  ): Promise<{ amount: number; currencySymbol: string }>;

  getProductionLineTotalPrice(
    instances: IWorkstationInstance[]
  ): Promise<Price>;
}

export class PriceCalculationService implements IPriceCalculationService {
  private _productService: IProductService;
  private _workstationTypeService: IWorkstationTypeService;

  constructor(
    productService: IProductService,
    workstationTypeService: IWorkstationTypeService
  ) {
    this._productService = productService;
    this._workstationTypeService = workstationTypeService;
  }

  async getWorkstationInstancePrice(
    model: IWorkstationInstance
  ): Promise<{ amount: number; currencySymbol: string }> {
    const wsType = await (
      await this._workstationTypeService.GetAll()
    ).find((x) => x.id === model.workstationTypeId);

    const includedComponents = model.assembly.filter((x) => x.isIncluded);

    const products = await this._productService.getAll();

    let totalPrice = 0;

    let currencySymbol = products.at(0)?.currencyIso ?? "USD";

    if (currencySymbol.length <= 0){
      currencySymbol = 'USD';
    }

    if (wsType) {
      const baseProducts = wsType.components
        .filter((c) => !c.isConfigurable)
        .map((c) => products.find((p) => p.id == c.productId))
        .filter((p) => p != null)
        .map((p) => p!);

      const baseProductPriceTotal = baseProducts
        .map((x) => x.price)
        .reduce((a, b) => a + b, 0);

      totalPrice += baseProductPriceTotal;
    }

    const componentDefinitions = wsType?.components ?? [];

    includedComponents.forEach((c) => {
      const productId = componentDefinitions.find(
        (x) => x.id == c.definitionId
      )?.productId;

      if (productId) {
        const componentPrice = products.find((p) => p.id == productId)?.price;

        if (componentPrice) {
          totalPrice += componentPrice;
        }
      }
    });

    return {
      amount: totalPrice,
      currencySymbol: currencySymbol,
    };
  }

  async getProductionLineTotalPrice(
    instances: IWorkstationInstance[]
  ): Promise<Price> {
    let totalPrice = 0;

    let currencySymbol = "";

    for (const instance of instances) {
      const instancePrice = await this.getWorkstationInstancePrice(instance);

      if (currencySymbol === "") {
        currencySymbol = instancePrice.currencySymbol;
      }

      totalPrice += instancePrice.amount;
    }

    return {
      amount: totalPrice,
      currencySymbol: currencySymbol,
    };
  }

  async getProductPrice(
    id: string
  ): Promise<{ amount: number; currencySymbol: string } | null> {
    const product = await this._productService.get(id);

    if (product) {
      return {
        amount: product.price,
        currencySymbol: product.currencyIso,
      };
    }

    return null;
  }
}

injected(
  PriceCalculationService,
  DI_TOKENS.IProductService.Value,
  DI_TOKENS.IWorkstationTypeService.Value
);

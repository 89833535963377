import { generateUUID } from "three/src/math/MathUtils";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IConfiguration } from "../../../../application/models/IProject";
import { ROUTES } from "../../../app-routes/routeConfig";
import { User } from "../../../auth/User";
import { useServices } from "../../hooks/useServices";

export function useProjectSlice(
  user: User | undefined,
  getScreenshotRef: React.MutableRefObject<
    | (() => {
        imgUrl: string;
      })
    | undefined
  >
): IProjectSlice {
  const navigate = useNavigate();
  const { configurationService: projectService } = useServices();

  //projectService.getProjects(user.id).then((x) => setProjects(x));

  const [currentProjectId, setCurrentProjectId] = useState<string>(
    generateUUID()
  );
  const [currentProjectName, setCurrentProjectName] = useState(
    "Untitled Configuration"
  );
  const [projects, setProjects] = useState<
    (IConfiguration & { totalAmount: number })[]
  >([]);
  const [isNewProject, setIsNewProject] = useState(true);

  const [pending, setPending] = useState(false);

  function handleNewProjectOpen() {
    setCurrentProjectId(generateUUID());
    setCurrentProjectName("Untitled Project");
    setIsNewProject(true);
    //updateWorkstationInstances([]);
    navigate(ROUTES.WELCOME_SCREEN.path);
  }

  const deleteProject = async (id: string) => {
    projectService.requestConfigurationDelete(id, user!.id).then(() => {
      refreshProjectList();
    });
  };

  const refreshProjectList = async () => {
    const result = await projectService.getConfigurations(user?.id!);
    setProjects(result);
  };

  const getProjects = useCallback(() => {
    const res = projects;
    return res;
  }, [projects]);

  const fetch = () => {
    setPending(true);
  };

  useEffect(() => {
    if (user && pending) {
      refreshProjectList().then(() => setPending(false));
    }
  }, [user, pending]);

  return {
    state: {
      currentProjectId,
      currentProjectName,
      projects,
      isNewProject
    },
    dispatch: {
      deleteProject,
      fetch,
      getProjects,
      handleNewProjectOpen,
      setCurrentProjectName,
      refreshProjectList,
      setIsNewProject
    },
  };
}

export interface IProjectState {
  currentProjectName: string;
  isNewProject: boolean;
  projects: IConfiguration[];
  currentProjectId: string;
}

export interface IProjectDispatch {
  refreshProjectList(): unknown;
  getProjects: () => (IConfiguration & { totalAmount: number })[];
  setCurrentProjectName: (name: string) => void;
  deleteProject: (id: string) => Promise<void>;
  handleNewProjectOpen: () => void;
  fetch: () => void;
  setIsNewProject: (isSaved: boolean) => void;
}
export interface IProjectSlice {
  state: IProjectState;
  dispatch: IProjectDispatch;
}

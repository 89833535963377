import { IOrderEmail as ISendOrderRequest } from "application/models/IOrderEmail";
import emailjs from "@emailjs/browser";
import { generateUUID } from "three/src/math/MathUtils";
import { IEquipmentBomItem, IProductBomItem } from "application/models/IBomItem";

//todo: to adapters?
export interface ISendOrderService {
  postOrder(order: ISendOrderRequest): Promise<void>;
}

const EMAILJS_SERVICE_ID = "service_krza2se";
const EMAILJS_TEMPLATE_ID = "template_1j5zkkl";

export class EmailOrderService implements ISendOrderService {
  postOrder(order: ISendOrderRequest): Promise<void> {

    const htmlBom = this.buildHtmlBom(order.bom);

    emailjs.send(
      EMAILJS_SERVICE_ID,
      EMAILJS_TEMPLATE_ID,
      {
        first_name: order.firstName,
        last_name: order.lastName,
        from_email: order.email,
        bom: htmlBom,
        order_id: generateUUID(),
        total_price: order.totalPrice.amount,
        telephone: order.telephone,
      },
      "svPvPTxSmh3waLU-u"
    );

    return Promise.resolve();
  }

  private buildHtmlBom(bom: IEquipmentBomItem[]) {
    const sorted = bom.sort((a, b) => b.totalAmount - a.totalAmount);

    const rows = sorted.map((item, i) => {
      const htmlRow = `<tr>
        <td style="padding: 10px; ">${i + 1}</td>
        <td style="padding: 10px; ">${item.title}</td>
        <td style="padding: 10px; ">${item.quantity}</td>
        <td style="padding: 10px; text-align: right;">$${item.totalAmount}</td>
    </tr>\r\n`;

      return htmlRow;
    });

    const htmlBom = rows.reduce((body, row) => body.concat(row), "");

    return htmlBom;
  }
}

import React, { useContext } from "react";
import { IAppContext } from "./IAppContext";

export const AppContext = React.createContext<IAppContext | undefined>(
  undefined
);

export function useAppContext() {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error("App Context must be obtained within AppContextProvider")
  }

  return context;
}

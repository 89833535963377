export function groupBy<T, K extends keyof any>(
    array: T[],
    keySelector: (item: T) => K
  ): { key: K; items: T[] }[] {
    return array.reduce((accumulator, currentItem) => {
      const key = keySelector(currentItem);
      // Find an existing group or create a new one
      let group = accumulator.find(g => g.key === key);
      if (!group) {
        group = { key, items: [] };
        accumulator.push(group);
      }
      group.items.push(currentItem);
      return accumulator;
    }, [] as { key: K; items: T[] }[]);
  }
import { Checkbox } from "@mui/material";

export function CheckboxCell(props: {
  value: boolean;
  onChange?: (value: boolean) => void;
}) {
  const { value, onChange } = props;

  return (
    <Checkbox
      checked={value}
      onChange={(e) => {
        const checked = e.target.checked;
        onChange && onChange(checked);
      }}
    />
  );
}

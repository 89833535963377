export function rad2deg(rad: number) {

    const deg = rad*360/(2*Math.PI);

    return deg;
}

export function deg2rad(deg: number) {

    const rad = deg*(2*Math.PI)/360;

    return rad;
}
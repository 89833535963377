import { useEffect, useState } from "react";
import { IWorkstationType } from "../../../../../application/models/IWorkstationType";
import { useEquipmentDefinitionTableContext } from "../context";
import { StandardForm } from "../../../../library/Form";
import { FieldBuilder } from "../../../../library/Form/fieldMeta/FieldBuilder";
import * as yup from "yup";
import { DataTable } from "../../../../library/DataTable";
import { ColumnBuilder } from "../../../../library/DataTable/ColumnBuilder";
import { CheckboxCell } from "../../../../library/DataTable/CheckboxCell";
import { OptionalRecord } from "../../../../../common/types/OptionalRecord";
import { Object3D } from "three";
import { EditorMode } from "../../../../../common/enums/EditorMode";
import { generateUUID } from "three/src/math/MathUtils";
import { FormModel } from "../models/FormModel";
import { ComponentDefinition } from "../models/ComponentDefinition";
import { UploadButton } from "./ModelUploadButton";
import { ModelViewer } from "./geometryModel/ModelViewer";
import {
  ModalOptionButtons,
  submitButtonStyle,
} from "../../../../library/AppModal/ModalOptionButtons";
import RoutedModal, {
  RoutedModalApi,
} from "../../../../library/Modals/RoutedModal";
import { FieldMeta } from "../../../../library/Form/fieldMeta/fieldMeta";
import { ROUTES } from "../../../../app-routes/routeConfig";
import { useTypedParams } from "react-router-typesafe-routes/dom";
import { useNavigate } from "react-router-dom";
import { useParentMatch } from "ui/shared/hooks/useParentRouteMatch";
import { AppModal } from "ui/library/AppModal";
import { Button, Typography } from "@mui/material";
import { AutocompleteCell } from "ui/library/DataTable/AutocompleteCell";

export function EquipmentDefintionDetail() {
  const isEdit = useParentMatch(ROUTES.ADMIN.EQUIPMENT_DEFINITION.LIST.EDIT);
  const isCreate = useParentMatch(
    ROUTES.ADMIN.EQUIPMENT_DEFINITION.LIST.CREATE
  );

  let editorMode: EditorMode | undefined;

  if (isEdit) editorMode = EditorMode.Update;
  if (isCreate) editorMode = EditorMode.Create;

  const isModalOpen = isEdit || isCreate;

  if (!isModalOpen) {
    return <></>;
  } else {
    return <Modal editorMode={editorMode!} />;
  }
}

/** this is a routed modal - it is always open if the route is matched */
function Modal({ editorMode }: { editorMode: EditorMode }) {
  const {
    getDefinition,
    getModelUri,
    products,
    uploadToBucket,
    handleEquipmentDefinitionSubmit,
  } = useEquipmentDefinitionTableContext();

  let { id: idFromRoute } = useTypedParams(
    ROUTES.ADMIN.EQUIPMENT_DEFINITION.LIST.EDIT
  );
  const id = editorMode === EditorMode.Create ? generateUUID() : idFromRoute; //todo use custom method to generateUuid

  const navigate = useNavigate();

  if (id == null) {
    throw new Error(
      "Could not obtain id for the edited item in equipment defintion modal"
    );
  }

  const [equipmentDefinition, setEquipmentDefinition] =
    useState<IWorkstationType>();
  const [status, setStatus] = useState<"idle" | "loading" | "done">("idle");
  const [uploadedObj3d, setUploadedObj3d] = useState<Object3D>();
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [errors, setErrors] = useState<string | null>(null);
  const productDropdownOptions = products.map((x) => {
    return {
      key: x.id,
      label: `${x.name} - ${x.sku}`,
    };
  });

  const submitButtonText =
    editorMode === EditorMode.Update ? "Save changes" : "Add Equipment";
  const modalTitle =
    editorMode === EditorMode.Update ? "Edit Equipment" : "Add Equipment";
  const modalsubTitle =
    editorMode === EditorMode.Update
      ? "Modify an equipment definition"
      : "Add your equipment to the catalogue";

  useEffect(() => {
    if (id && status != "done") {
      setStatus("loading");
      getDefinition(id).then((definition) => {
        if (definition) setEquipmentDefinition(definition);
        setStatus("done");
      });
    }
  }, [status]);

  const SubComponentTable = (props: {
    value: ComponentDefinition[];
    error:
      | string
      | OptionalRecord<keyof ComponentDefinition, string>[]
      | undefined;
    handleChange: (value: ComponentDefinition[]) => void;
  }) => {
    const { value, handleChange, error } = props;
    return (
      <>
        <DataTable<ComponentDefinition>
          data={value}
          handleRowClick={(x) => {}}
          handleDelete={async (record) => {}}
          editable
          columns={[
            "name",
            "nodeId",
            new ColumnBuilder<ComponentDefinition>().createColumn(
              "productId",
              (params) => (
                <AutocompleteCell
                  options={productDropdownOptions}
                  {...params}
                />
              )
            ),
            new ColumnBuilder<ComponentDefinition>().createColumn(
              "isConfigurable",
              (params) => <CheckboxCell {...params} />
            ),
          ]}
          handleChange={(rows) => {
            handleChange(rows);
          }}
          footer={false}
          errors={error}
          addRow
        />
      </>
    );
  };

  const initialModelUri = equipmentDefinition
    ? getModelUri(equipmentDefinition.modelId)
    : undefined;

  const isDataReady = equipmentDefinition || editorMode == EditorMode.Create;

  const formFields: FieldMeta<FormModel>[] = [
    fieldBuilder.createField("modelId", (fieldState) => {
      const { handleChange, value } = fieldState;

      return (
        <>
          <UploadButton
            onModelUploaded={(m, meta) => {
              const obj = m.toObject3d();
              if (obj) {
                setUploadedObj3d(obj);
                uploadToBucket(m, meta.fileName).then((uploadResult) => {
                  handleChange(uploadResult.modelId);
                });
              }
            }}
          />
          {(initialModelUri || uploadedObj3d) && (
            <ModelViewer gltfUrl={initialModelUri} object3d={uploadedObj3d} />
          )}
          {errors && (
            <Typography component="p" textAlign="center" color="error">
              {errors}
            </Typography>
          )}
        </>
      );
    }),
    {
      key: "name",
      field: "text-input",
    },
    {
      key: "description",
      field: "text-input",
    },
    fieldBuilder.createField("components", (fieldState) => {
      return <SubComponentTable {...fieldState} />;
    }),
  ];

  const modalContents = (modalApi: RoutedModalApi) => {
    return (
      <StandardForm<FormModel>
        onSubmit={async (data) => {
          await handleEquipmentDefinitionSubmit(id, data, editorMode);
          modalApi.close();
          setConfirmModal(true);
        }}
        fields={formFields}
        initialValues={{
          ...equipmentDefinition,
          components: equipmentDefinition?.components ?? [],
        }}
        validationSchema={formValidationSchema}
        submitButton={false}
      >
        {(formBody, formApi) => {
          return (
            <>
              {formBody}
              <ModalOptionButtons
                handleSubmitClick={formApi.submit}
                handleCancelClick={modalApi.close}
                submitButtonText={submitButtonText}
              />
            </>
          );
        }}
      </StandardForm>
    );
  };

  return isDataReady ? (
    <>
      <RoutedModal title={modalTitle} subtitle={modalsubTitle}>
        {modalContents}
      </RoutedModal>
      {confirmModal && (
        <AppModal
          isOpen={confirmModal}
          handleClose={() => setConfirmModal(!confirmModal)}
          title="Equipments"
          subTitle="Successfully created equipment"
          type="confirm"
        >
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={submitButtonStyle}
            onClick={() =>
              navigate(ROUTES.ADMIN.EQUIPMENT_DEFINITION.LIST.path)
            }
          >
            Continue
          </Button>
        </AppModal>
      )}
    </>
  ) : (
    <div>LOADING</div>
  );
}

const subComponentTableSchema: yup.SchemaOf<ComponentDefinition> = yup
  .object()
  .shape({
    isConfigurable: yup.boolean().required(),
    id: yup.string().required(),
    name: yup.string().required(),
    nodeId: yup.string().notRequired(),
    productId: yup.string().required(),
    description: yup.string().notRequired(),
  });

const formValidationSchema: yup.SchemaOf<FormModel | {}> = yup.object().shape({
  modelId: yup.string().required(),
  name: yup.string().required(),
  imageUri: yup.string().notRequired(),
  components: yup.array(subComponentTableSchema).required(),
  description: yup.string().notRequired(),
  isPublic: yup.boolean().notRequired(),
});

const fieldBuilder = new FieldBuilder<FormModel>();

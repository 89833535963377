import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { IWorkstationInstance } from "../../../../application/models/IWorkstationInstance";
import { Price } from "../../../../common/models/price";
import { useWorkPanelContext } from "../context";
import { RotationControl } from "./RotationControl";
import { deg2rad, rad2deg } from "../../../../common/math/angle";
import { priceDisplay } from "../../../helpers/priceDisplay";
import UP_ARROW_ICON from "../../../../assets/bxs_up-arrow.svg";
import DOWN_ARROW_ICON from "../../../../assets/bxs_down-arrow.svg";
import DELETE_ICON from "../../../../assets/delete.svg";

export function WorkstationListItem(props: {
  workstation: IWorkstationInstance & { typeName: string; index: number };
  assembly: PricedAssemblyItem[];
  isRemovable: boolean;
}) {
  const context = useWorkPanelContext();

  const { workstation: model, isRemovable, assembly } = props;

  const [totalPrice, setTotal] = useState<Price | null>(null);

  useEffect(() => {
    (async () => {
      const price = await context.getWorkstationInstancePrice(model.id);

      if (price) {
        setTotal(price);
      }
    })();
  }, [
    JSON.stringify(
      assembly.map((x) => {
        return { id: x.definitionId, isIncluded: x.isIncluded };
      })
    ),
  ]);

  const displayedTotal = useMemo(() => priceDisplay(totalPrice), [totalPrice]);

  const isDoubleClickRef = useRef(false);

  const handleSingleClick = () => {
    setTimeout(() => {
      if (!isDoubleClickRef.current)
        context.updateSelectedWorkstationInstanceId!(
          model.id == context.selectedWorkstationInstanceId ? "0" : model.id
        );
    }, 200);
  };

  const handleDoubleClick = () => {
    isDoubleClickRef.current = true;
    context.updateFocusedWorkstationInstanceId!(model.id);
    context.updateSelectedWorkstationInstanceId!(model.id);
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    isDoubleClickRef.current = false;

    if (e.detail === 2) {
      e.preventDefault();
      handleDoubleClick();
    } else {
      handleSingleClick();
    }
  };

  const calculateSubparts = () => {
    const filter = assembly.filter((item) => item.isIncluded);
    const parts = filter.map((item) => item.name);
    const price = filter.map((item) => item.price);
    const sum = price.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

    return (
      <>
        <span>{parts.join(", ")}</span> <span>+${sum}</span>
      </>
    );
  };

  return (
    <Paper
      key={model.id}
      component="div"
      sx={{ m: 2, p: 1, userSelect: "none" }}
      className="equipment-single-list"
    >
      <Box
        onClick={handleClick}
        display="flex"
        alignItems={"center"}
        component="div"
        sx={{ flexGrow: 1 }}
      >
        <div className="arrows">
          <img
            src={
              model.id == context.selectedWorkstationInstanceId
                ? DOWN_ARROW_ICON
                : UP_ARROW_ICON
            }
            alt="arrow"
          />
          <div>
            <Typography component="p" sx={{ flexGrow: 1, textAlign: "left" }}>
              Equipment {model.index + 1} - {model.typeName}
            </Typography>
            {assembly.length > 0 &&
              model.id != context.selectedWorkstationInstanceId && (
                <Typography
                  component="p"
                  sx={{ flexGrow: 1, textAlign: "left" }}
                  className="sub-equipments"
                >
                  - {calculateSubparts()}
                </Typography>
              )}
          </div>
        </div>
        {model.id == context.selectedWorkstationInstanceId &&
        assembly.length > 0 ? (
          <></>
        ) : (
          <div className="price-delete">
            <p className="price">{displayedTotal}</p>
            {isRemovable ? (
              <IconButton
                aria-label="delete"
                color="error"
                onClick={() => context.handleStationRemove(model.id)}
              >
                <img src={DELETE_ICON} alt="Delete" />
              </IconButton>
            ) : null}
          </div>
        )}
      </Box>

      {model.id == context.selectedWorkstationInstanceId ? (
        <FormContent
          rotationDeg={rad2deg(model.rotationRad)}
          handleRotationChange={(rotationDeg) => {
            const rad = deg2rad(rotationDeg);

            context.handleStationRotationChange(model.id, rad);
          }}
          assembly={assembly}
          workstationTypeId={model.workstationTypeId}
          workstationInstanceId={model.id}
          displayedTotal={displayedTotal || ""}
          handleStationRemove={context.handleStationRemove}
        />
      ) : null}
    </Paper>
  );
}

export interface PricedAssemblyItem {
  definitionId: string;
  isIncluded: boolean;
  name: string;
  displayedPrice: string;
  price: number;
}

function FormContent({
  ...props
}: {
  handleRotationChange: (rotationDeg: number) => void;
  workstationInstanceId: string;
  workstationTypeId: string;
  assembly: PricedAssemblyItem[];
  rotationDeg: number;
  displayedTotal: string;
  handleStationRemove: (workstationInstanceId: string) => void;
}) {
  const context = useWorkPanelContext();

  const handleAssemblyToggleChange = (
    workstationInstanceId: string,
    partInstanceId: string,
    newValue: boolean
  ) => {
    const workstationInstances = context.getSortedWorkstationInstances();

    const workstation = workstationInstances.find(
      (x) => x.id == workstationInstanceId
    );

    const match = workstation?.assembly.find(
      (x) => x.definitionId == partInstanceId
    );

    if (match) {
      match.isIncluded = newValue;

      context.updateWorkstationInstances!([...workstationInstances]);
    }
  };

  const handleStationTypeChange = (
    workstationInstanceId: string,
    workstationTypeId: string
  ) => {
    context.handleStationTypeChange(workstationInstanceId, workstationTypeId);
  };

  const workstationTypeOptions = context.workstationTypes.map((x) => {
    return {
      id: x.id,
      name: x.name,
    };
  });

  return (
    <div className="subequipments-container">
      <FormGroup>
        <div className="rotation-control">
          <RotationControl
            handleChange={(rotationDeg) =>
              props.handleRotationChange(rotationDeg)
            }
            rotationDeg={props.rotationDeg}
          ></RotationControl>
        </div>
        <FormControl fullWidth>
          {/* <InputLabel id="demo-simple-select-label">
            Workstation Type
          </InputLabel> */}
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={props.workstationTypeId}
            label="Workstation Type"
            onChange={(e) =>
              handleStationTypeChange(
                props.workstationInstanceId,
                e.target.value
              )
            }
            sx={{ zIndex: 100000 }}
            style={{ zIndex: 100000 }}
            className="work-station"
          >
            {workstationTypeOptions.map((x) => {
              return (
                <MenuItem sx={{ zIndex: 100000 }} value={x.id}>
                  {x.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <div className="checbox-container">
          <div className="subparts">
            {props.assembly.map((part) => {
              return (
                // <FormControlLabel
                //   key={part.definitionId + props.workstationInstanceId}
                //   control={
                //     <Switch
                //       onChange={(e) =>
                //         handleAssemblyToggleChange(
                //           props.workstationInstanceId,
                //           part.definitionId,
                //           e.target.checked
                //         )
                //       }
                //       checked={part.isIncluded}
                //     />
                //   }
                //   label={`${part.name} - ${part.displayedPrice}`}
                // />
                <div className="checkbox">
                  <FormControlLabel
                    key={part.definitionId + props.workstationInstanceId}
                    control={
                      <Checkbox
                        onChange={(e) =>
                          handleAssemblyToggleChange(
                            props.workstationInstanceId,
                            part.definitionId,
                            e.target.checked
                          )
                        }
                        checked={part.isIncluded}
                        sx={{
                          color: "#D0D5DD",
                          "&.Mui-checked": {
                            color: "#0074A7",
                          },
                        }}
                      />
                    }
                    label=""
                  />
                  <div className="parts-descr">
                    <div>
                      <h6>{part.name}</h6>
                      <p>Description</p>
                    </div>
                    <h6>+ {part.displayedPrice}</h6>
                  </div>
                </div>
              );
            })}
          </div>
          {props.assembly.length > 0 && (
            <div className="total-price">
              {props.displayedTotal}
              <IconButton
                aria-label="delete"
                color="error"
                onClick={() =>
                  context.handleStationRemove(props.workstationInstanceId)
                }
              >
                <img src={DELETE_ICON} alt="Delete" />
              </IconButton>
            </div>
          )}
        </div>
      </FormGroup>
    </div>
  );
}

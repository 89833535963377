export default interface OrthonormalVector {
    x: -1 | 0 | 1;
    y: -1 | 0 | 1;
    z: -1 | 0 | 1;
}

export function isVectorEqual(v1: OrthonormalVector, v2: OrthonormalVector){

    return v1.x == v2.x && v1.y == v2.y && v1.z == v2.z;

}

import { ROUTES } from "../../../app-routes/routeConfig";
import PageTabs from "../../../app/components/PageTabs";
import { ReactNode } from "react";
import { useAppContext } from "../../../app/context";
import { FeatureContainer } from "../../../library/Layout/FeatureContainer";
import { CatalogueHeader } from "../../../shared/headers/CatalogueHeader";
import { useParentMatch } from "../../../shared/hooks/useParentRouteMatch";

export function CatalogueMaster({ children }: { children: ReactNode }) {
  const parentContext = useAppContext();

  const equipmentDefintions =
    parentContext.equipmentDefinition.state.workstationTypes;
  const predefinedLayouts =
    parentContext.predefinedLayout.state.configurationTemplates;
  const products = parentContext.product.state.items;

  const isEquipments = !!useParentMatch(ROUTES.ADMIN.EQUIPMENT_DEFINITION);
  const isTemplates = !!useParentMatch(ROUTES.CATALOGUE.CONFIGURATION_TEMPLATE);
  const isProducts = !!useParentMatch(ROUTES.ADMIN.PRICING_SHEET);

  return (
    <>
      <FeatureContainer header={<CatalogueHeader />}>
        <PageTabs
          tabs={[
            {
              name: "Products",
              active: isProducts,
              count: products.length || 0,
              url: ROUTES.ADMIN.PRICING_SHEET.path,
            },
            {
              name: "Equipments",
              active: isEquipments,
              count: equipmentDefintions.length || 0,
              url: ROUTES.ADMIN.EQUIPMENT_DEFINITION.LIST.path,
            },
            {
              name: "Templates",
              active: isTemplates,
              count: predefinedLayouts.length || 0,
              url: ROUTES.CATALOGUE.CONFIGURATION_TEMPLATE.LIST.path,
            },
          ]}
        />
        <div>{children}</div>
      </FeatureContainer>
    </>
  );
}

import { Box } from "@mui/material";

type Props = {
  children?: React.ReactNode;
};

export default function MainHolder({ children }: Props) {
  return (
    <Box component="div" sx={{ width: "100%", marginLeft: "72px", height: '100%', display: 'flex', flexDirection: 'column' }}>
      {children}
    </Box>
  );
}

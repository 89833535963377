import { injected } from "brandi";
import DI_TOKENS from "../../DI_TOKENS";
import { IStorageContext } from "../../infrastructure/storage/mockSeedStorageContext";
import { IWorkstationType } from "../models/IWorkstationType";
import { LocalStorageRepository } from "../repositories/LocalStorageRepository";
import { IUserService } from "./UserService";
import { IRepository } from "../repositories/IRepository";

export class WorkstationTypeService implements IWorkstationTypeService {
  _storageContext: IStorageContext;
  _repository: IRepository<IWorkstationType>;
  private _userService: IUserService;

  private get userId() {
    return this._userService.getCurrentUserId();
  }

  public constructor(
    storageContext: IStorageContext,
    userService: IUserService,
    repository: IRepository<IWorkstationType>
  ) {
    this._storageContext = storageContext;
    this._userService = userService;
    this._repository = repository;
  }
  async delete(id: string): Promise<void> {
    await this._repository.delete(id, this._userService.getCurrentUserId());
  }

  async GetAll(): Promise<IWorkstationType[]> {
    console.log("fetching all workstation types");
    let result = (await this._repository.getList(this.userId))?.items ?? [];
    if (this.userId != null && (result == null || result.length <= 0)) {
      const dummy = await this._storageContext.getWorkstationTypes();
      await this._repository.updateList(dummy, this.userId);

      result = dummy;
    }

    return result ?? [];
  }

  async update(definition: IWorkstationType) {
    await this._repository.update(definition, this.userId);
  }

  async create(definition: IWorkstationType) {
    return await this._repository.create(definition, this.userId);
  }
}

export interface IWorkstationTypeService {
  GetAll(): Promise<IWorkstationType[]>;
  update(definition: IWorkstationType): Promise<void>;
  create(definition: IWorkstationType): Promise<IWorkstationType | undefined>;
  delete(id: string): Promise<void>;
}

injected(
  WorkstationTypeService,
  DI_TOKENS.IStorageContext.Value,
  DI_TOKENS.IUserService.Value,
  DI_TOKENS.IRepository_IWorkstationType.Value
);

import { IConfigurationTemplate } from "application/models/IConfigurationTemplate";
import { useEffect, useState } from "react";
import { IWorkstationInstanceService } from "application/services/WorkstationInstanceService";
import { User } from "ui/auth/User";
import { ConfigurationTemplateDetailsWithId } from "../../../../application/models/ConfigurationTemplateDetailsWithId";
import { IConfigurationTemplateService } from "application/services/ConfigurationTemplateService";
import { FetchStatus } from "common/enums/FetchStatus";
import { IConfiguration } from "application/models/IProject";
import { ConfigurationTemplateAddUpdateModel } from "ui/models/configurationTemplateAddUpdateModel";

export function useConfigurationTemplateSlice(
  user: User | undefined,
  services: {
    workstationInstanceService: IWorkstationInstanceService;
    configurationTemplateService: IConfigurationTemplateService;
  }
): IPredefinedLayoutSlice {
  const { configurationTemplateService } = services;

  const [status, setStatus] = useState<FetchStatus>();

  const [currentTemplate, setCurrentTemplate] =
    useState<ConfigurationTemplateDetailsWithId>();
  const [templates, setTemplates] = useState<IConfigurationTemplate[]>([]);

  useEffect(() => {
    if (!user) {
      return;
    }
    if (status == null || status === "loading") {
      (async () => {
        const response = await configurationTemplateService.getList();
        setTemplates(response.items);
        setStatus("idle");
      })();
    }
  }, [user, status]);

  async function createTemplate(
    item: IConfigurationTemplate & {
      configuration: IConfiguration;
    }
  ) {
    const result = await configurationTemplateService.create(item);
    setStatus("loading");
    return result;
  }

  async function updateTemplate(
    item: IConfigurationTemplate & {
      configuration: IConfiguration;
    }
  ) {
    await configurationTemplateService.update(item);
    setStatus("loading");
  }

  return {
    dispatch: {
      setTemplates,
      setCurrentTemplate,
      createTemplate,
      updateTemplate,
    },
    state: {
      currentTemplate,
      configurationTemplates: templates,
    },
  };
}

export interface IPredefinedLayoutState {
  currentTemplate: ConfigurationTemplateDetailsWithId | undefined;
  configurationTemplates: IConfigurationTemplate[];
}

export interface IPredefinedLayoutDispatch {
  setTemplates: (
    set: (prev: IConfigurationTemplate[]) => IConfigurationTemplate[]
  ) => void;
  setCurrentTemplate(
    item: ConfigurationTemplateDetailsWithId | undefined
  ): void;
  createTemplate(
    item: ConfigurationTemplateAddUpdateModel
  ): Promise<IConfigurationTemplate | undefined>;
  updateTemplate(item: ConfigurationTemplateAddUpdateModel): Promise<void>;
}

export interface IPredefinedLayoutSlice {
  state: IPredefinedLayoutState;
  dispatch: IPredefinedLayoutDispatch;
}

import {
  IGeometryModelState,
  IGeometryModelDispatch,
  IGeometryModelSlice,
} from "./slice";

export function useGeometryModelReducer(
  geometryModelSlice: IGeometryModelSlice
): IGeometryModelReducer {
  const reducer = { ...geometryModelSlice };

  return reducer;
}

export interface IGeometryModelReducer {
  state: IGeometryModelState;
  dispatch: IGeometryModelDispatch;
}

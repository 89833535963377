import { Button, Typography } from "@mui/material";
import { ROUTES } from "../../../app-routes/routeConfig";
import { useWorkPanelContext } from "../context";
import { WorkstationListItem } from "./WorkstationListItem";
import { useNavigate } from "react-router-dom";
import { priceDisplay } from "../../../helpers/priceDisplay";
import Add from "@mui/icons-material/Add";
import RightArrow from "@mui/icons-material/ArrowForward";
import { useParentMatch } from "../../../shared/hooks/useParentRouteMatch";

export function WorkstationList() {
  const context = useWorkPanelContext();
  const navigate = useNavigate();
  const instanceModels = context.getSortedWorkstationInstances();
  const { setScreen } = context;

  const sortedWorkstationInstances: JSX.Element[] = instanceModels.map(
    (model, index) => {
      const pricedAssembly = model.assembly.map((x) => {
        const price = context.getAssemblyComponentPrice(x.definitionId);

        const displayedPrice = priceDisplay(price) ?? "";

        return {
          ...x,
          displayedPrice: displayedPrice,
          price: price?.amount || 0,
        };
      });

      return (
        <WorkstationListItem
          workstation={{ ...model, index: index }}
          assembly={pricedAssembly}
          isRemovable={instanceModels.length > 1}
        />
      );
    }
  );

  let title = "UNKKNOWN";

  //TODO: do variant selection in context
  const isTemplate = useParentMatch(ROUTES.CATALOGUE.CONFIGURATION_TEMPLATE);
  const isOrder = useParentMatch(ROUTES.SALES_PROCESS);

  if (isTemplate) {
    title = "Template Items";
  }

  if (isOrder) {
    title = "Cart Items";
  }

  function onReviewClick() {
    if (isTemplate) {
      setScreen("templateReview");
    }

    if (isOrder) {
      setScreen("orderReview");
    }
  }

  return (
    <div className="widget-inside">
      <div className="header">
        <h5>
          {title}
          {sortedWorkstationInstances.length > 0 && (
            <span>({`${sortedWorkstationInstances.length}`})</span>
          )}
        </h5>
        <div>
          <Button
            onClick={() =>
              navigate(ROUTES.CATALOGUE.CONFIGURATION_TEMPLATE.LIST.path)
            }
          >
            Templates
          </Button>
          {sortedWorkstationInstances.length > 0 && (
            <Button
              onClick={() => setScreen("equipmentDefinitionTiles")}
              startIcon={<Add />}
              className="add-equipment"
            >
              Add Equipment
            </Button>
          )}
        </div>
      </div>
      {sortedWorkstationInstances.length === 0 && (
        <div className="button-center">
          <Typography component={"p"} sx={{ textAlign: "center" }}>
            Nothing to show, search and add equipments
          </Typography>
          <Button
            onClick={() => {
              setScreen("equipmentDefinitionTiles");
            }}
            variant="contained"
            color="primary"
            startIcon={<Add />}
          >
            Add Equipment
          </Button>
        </div>
      )}
      <div className="equipment-list-container">
        {sortedWorkstationInstances}
      </div>
      {sortedWorkstationInstances.length > 0 && (
        <>
          <div className="total-price-footer">
            <h6>Total: {priceDisplay(context.productionLineTotalPrice)}</h6>
          </div>
          <div className="review-cart">
            <Button
              variant="contained"
              color="primary"
              endIcon={<RightArrow />}
              onClick={onReviewClick}
            >
              Review
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

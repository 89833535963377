import { useRef } from "react";
import { Model } from "@jmk/ar-toolbox-core/core";
import { Box, Typography } from "@mui/material";
import { ModelFileInput } from "@jmk/ar-toolbox-react";
import { getNameWithExtension } from "../../../../../common/fileHelpers/getNameWithExtension";
import CloudIcon from "assets/upload-cloud.svg";

export function UploadButton(props: {

  onModelUploaded: (
    model: Model,
    fileMeta: {
      fileName: string;
      extension: string;
    }
  ) => void;
}) {
  const modelFileInputRef = useRef<HTMLInputElement>(null);

  const fileMetaDataRef = useRef<{
    fileName: string;
    extension: string;
  }>();

  return (
    <>
      <ModelFileInput
        hidden
        inputElementRef={modelFileInputRef}
        onFilesSelected={(e) => {
          const f = e.at(0);

          if (f) {
            const [fname, extension] = getNameWithExtension(f.name);

            if (fname && extension) {
              fileMetaDataRef.current = { extension, fileName: fname };
            }

            return { isValid: true };
          }

          return { isValid: false };
        }}
        onFileConverted={(e) => {
          if (e instanceof Model && fileMetaDataRef.current) {
            props.onModelUploaded(e, fileMetaDataRef.current);
          }
        }}
      />
      <Box
        component="div"
        sx={{
          display: "flex",
          padding: "1rem 1.5rem",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          alignSelf: "stretch",
          borderRadius: "0.75rem",
          border: "2px solid #EAECF0",
          cursor: "pointer",
          width: "100%",
        }}
        onClick={() => {
          modelFileInputRef.current?.click();
        }}
      >
        <Box
          component="div"
          sx={{
            padding: 1,
            paddingBottom: 0,
            border: "1px solid #e9e9e9",
            borderRadius: 1,
            marginBottom: "0.75rem",
          }}
        >
          <img src={CloudIcon} alt="cloud icon" />
        </Box>
        <Typography component="p" textAlign="center">
          <Typography
            component="b"
            fontSize="0.875rem"
            fontWeight={600}
            color="#0074A7"
          >
            Click to upload
          </Typography>{" "}
          or drag and drop
          <br />
          CAD File, 3D file
        </Typography>
      </Box>
      {/* <Button
              variant="contained"
              component="label"
              onClick={() => {
                modelFileInputRef.current?.click();
              }}
            >
              Upload File
            </Button> */}
    </>
  );
}

import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#556cd6",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
  },
  typography: {
    body1: {
      width: "100%",
      textAlign: "left",
      fontSize: "0.875rem",
      fontWeightMedium: 500,
      color: "#344054",
      
    },
    body2: {
      fontSize: "12px",
      color: "#f00000",
      textAlign: "left",
    },
  },
});

export default theme;

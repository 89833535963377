import { useState } from "react";
import { ROUTES } from "../../../app-routes/routeConfig";
import { useAppContext } from "../../../app/context";
import { DataTable } from "../../../library/DataTable";
import { useNavigate } from "react-router-dom";
import { CatalogueMaster } from "../equipmentDefinition/CatalogueMaster";

const Templates = () => {
  const parentContext = useAppContext();
  const { configurationTemplates } = parentContext.predefinedLayout.state;
  const { loadConfigurationTemplate, startSaleFromTemplate } =
    parentContext.predefinedLayout.dispatch;
  const navigate = useNavigate();

  const handleSelection = (id: string) => {
    loadConfigurationTemplate(id);
    navigate(ROUTES.CATALOGUE.CONFIGURATION_TEMPLATE.DESIGN.EDIT.path);
  };

  const handleContinue = (id: string) => {
    startSaleFromTemplate(id);
    navigate(ROUTES.SALES_PROCESS.CUSTOMER_DETAILS.path);
  };

  return (
    <CatalogueMaster>
      <DataTable
        data={configurationTemplates}
        columns={["name", "description"]}
        handleEdit={(x) => {
          handleSelection(x.id);
        }}
        handleDelete={async ({ id }) => {}}
        handleContinue={(x) => {
          handleContinue(x.id);
        }}
        sx={{ padding: 0, marginTop: "2.25rem" }}
        checkboxSelection={true}
      />
    </CatalogueMaster>
  );
};

export default Templates;

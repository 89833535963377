import React from "react";
import { IFactoryViewportContext } from "./context";


export const FactoryViewportContext = React.createContext<IFactoryViewportContext>({
    onWorkstationInstanceSelected: undefined,
    selectedWorkstationInstanceId: null,
    isEquipmentDragAdditionInProgress: false,
    dragAddedEquipmentTypeId: null, 
    focusedWorkstationInstanceId: null,
    onWorkstationInstanceFocused: undefined,
    getModelBounds: undefined
});

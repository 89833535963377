import { Model } from "@jmk/ar-toolbox-core/core";
import { useEffect, useState } from "react";
import { IGeometryModelService } from "../../../../application/services/GeometryModelService";
import { IGeometryModel } from "../../../../application/models/IGeometryModel";
import { User } from "../../../auth/User";

export function useGeometryModelSlice(user: User | undefined, services: {
  geometryModelService: IGeometryModelService;
}): IGeometryModelSlice {
  const { geometryModelService } = services;

  //models
  const [pending, setGeometryModelsPending] = useState(true);
  const [geometryModels, setGeometryModels] = useState<IGeometryModel[]>([]);

  function fetchGeometryModels() {
    setGeometryModelsPending(true);
  }

  function getModelUrl(modelId: string) {
    return geometryModels?.find((x) => x.id == modelId)?.uri;
  }

  async function uploadToBucket(model: Model, fileName: string) {
    const result = await geometryModelService.uploadToBucket(model, fileName);
    return result;
  }

  
  useEffect(() => {
    if (user && pending) {
      geometryModelService.getList().then((fetched) => {
        setGeometryModels(fetched);
        setGeometryModelsPending(false);
      });
    }
  }, [pending, user?.id]);

  return {
    dispatch: {
      fetchGeometryModels,
      getModelUrl,
      uploadToBucket
    },
    state: {
      geometryModels,
      pending
    }
  }
}

export interface IGeometryModelDispatch {
  getModelUrl: (modelId: string) => string | undefined;
  fetchGeometryModels: () => void;
  uploadToBucket: (
    modelData: Model,
    fileName: string
  ) => Promise<{ resourceUri: string; modelId: string }>;
}



export interface IGeometryModelState {
  geometryModels: IGeometryModel[];
  pending: boolean;
}
export interface IGeometryModelSlice {
  state: IGeometryModelState;
  dispatch: IGeometryModelDispatch;
}

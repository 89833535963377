import { useEffect, useState } from "react";
import { ICheckoutService } from "../../../../application/services/CheckoutService";
import { IPriceCalculationService } from "../../../../application/services/PriceCalculationService";
import { Price } from "../../../../common/models/price";

export function useQuotingSlice(services: {
  priceCalculationService: IPriceCalculationService;
  checkoutService: ICheckoutService;
}): IQuotingSlice {
  const { priceCalculationService } = services;

  const [pricingCache, updatePricingCache] =
    useState<{ id: string; currencySymbol: string; amount: number }[]>();

  const [productionLineTotal, setProductionLineTotal] = useState<Price>({
    amount: 0,
    currencySymbol: "USD",
  });

  return {
    dispatch: {
      setProductionLineTotal,
      updatePricingCache,
    },
    state: {
      pricingCache,
      productionLineTotalPrice: productionLineTotal,
    },
  };
}

export interface IQuotingState {
  productionLineTotalPrice: Price | null;
  pricingCache:
    | { id: string; currencySymbol: string; amount: number }[]
    | undefined;
}

export interface IQuotingDispatch {
  setProductionLineTotal: (price: Price) => void;
  updatePricingCache: React.Dispatch<
    React.SetStateAction<
      { id: string; currencySymbol: string; amount: number }[] | undefined
    >
  >;
}

export interface IQuotingSlice {
  state: IQuotingState;
  dispatch: IQuotingDispatch;
}

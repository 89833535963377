import { IPartInstance } from "application/models/IPartInstance";
import { IConfiguration } from "application/models/IProject";
import { IWorkstationInstance } from "application/models/IWorkstationInstance";
import { WorkstationInstanceDto, PartInstanceDto, ConfigurationUpdateRequest } from "infrastructure/api-client";
import { useApiClients } from "infrastructure/hooks/useApiClients";
import { dateFieldsToDate, dateFieldsToString } from "./helpers/dateHelpers";
import { IRepository } from "application/repositories/IRepository";
import { IProjectRepository } from "./contracts/project";
import { configurationMapper } from "application/mappers/configurationMapper";

export function useProjectProvider() {
    const projectApi = useApiClients().project
  
    const update = async (model: IConfiguration, userId: string) => {
      const mapped = configurationMapper.mapModelToRequest(model);
  
      const { data } = await projectApi.configurationsPut(mapped);
    };
  
    const create = async (model: IConfiguration, userId: string) => {
      const mapped = configurationMapper.mapModelToRequest(model);
  
      const { data } = await projectApi.configurationsPost(mapped);
  
      const equipments: WorkstationInstanceDto[] = []
  
      return mapFromFullResponse({...data, equipments});
    };
  
    const mapFromResponse = (x: ConfigurationUpdateRequest) => {
      return {
        ...dateFieldsToDate(x),
        name: x.name!,
        equipments: [] as IWorkstationInstance[]
      };
    };
  
    const mapEquipment = (x: WorkstationInstanceDto): IWorkstationInstance => {
      const mapped = {
        ...dateFieldsToDate(x),
        assembly: [] as IPartInstance[],
        id: x.id!,
        previousInstanceId: x.previousInstanceId,
      };
      return mapped;
    };
  
    const mapPart = (x: PartInstanceDto): IPartInstance => {
      const mapped = { ...x, id: x.id!, name: x.name! };
      return mapped;
    };
  
    const mapFromFullResponse = (x: ConfigurationUpdateRequest): IConfiguration => {
      const mapped = mapFromResponse(x);
      mapped.equipments = x.equipments?.map(mapEquipment) ?? [];
  
      x.parts?.forEach((p) => {
        const parent = mapped.equipments.find(
          (e) => e.id == p.workstationInstanceId
        );
  
        if (parent) {
          const part = mapPart(p);
          parent.assembly = [...(parent.assembly ?? []), part];
        } else {
          throw new Error("orphaned part instance in server response!!!");
        }
      });
  
      return mapped;
    };
  
    const provider: IProjectRepository = {
      update: update,
      create: create,
      getList: async (userId: string) => {
        const { data } = await projectApi.configurationsListGet();
        const result = data.map(mapFromResponse);
        return {
          items: result,
          totalCount: result.length,
          filteredCount: result.length
        };
      },
      delete: async (id: string, userId: string) => {
        await projectApi.configurationsDelete(id);
      },
      get: async function (id: string, userId: string): Promise<IConfiguration> {
        const response = await projectApi.configurationsGet(id);
        const { data } = response;
        return mapFromFullResponse(data);
      },
      updateList: function (current: IConfiguration[], userId: string): Promise<void> {
        throw new Error("Function not implemented.");
      },
    };
  
    return provider;
  }
  
import { IRepository } from "../../application/repositories/IRepository";
import { IFilteredListResult } from "../../ui/app/store/pagination/pagination";
import { TableRequest } from "../paging/paging";

export function createApiProvider<T>(crudRequests: {
  getList?: (userId: string, request?: TableRequest<T>) => Promise<IFilteredListResult<T>>;
  create?: (item: T, userId: string) => Promise<T | undefined>;
  update?: (item: T, userId: string) => Promise<void>;
  updateList?: (items: T[], userId: string) => Promise<void>;
  get?: (id: string, userId: string) => Promise<T>;
  delete?: (id: string, userId: string) => Promise<void>
}) {
  const provider: IRepository<T> = {
    update: function (current: T, userId: string): Promise<void> {
      if (!crudRequests.update) {
        throw new Error("UPDATE not implemented");
      }
      return crudRequests.update(current, userId);
    },
    create: function (current: T, userId: string): Promise<T | undefined> {
      if (!crudRequests.create) {
        throw new Error("CREATE not implemented");
      }
      return crudRequests.create(current, userId);
    },
    getList: function (userId: string, request?: TableRequest<T>): Promise<IFilteredListResult<T>> {
      if (!crudRequests.getList) {
        throw new Error("GET list not implemented");
      }
      return crudRequests.getList(userId, request);
    },
    delete: async function (id: string, userId: string): Promise<void> {
      if (!crudRequests.delete) {
        throw new Error("DELETE not implemented");
      }
      return crudRequests.delete(id, userId);
    },
    get: async function (id: string, userId: string): Promise<T> {
      if (!crudRequests.get) {
        throw new Error("GET not implemented");
      }

      return await crudRequests.get(id, userId);
    },
    updateList: function (current: T[], userId: string): Promise<void> {
      if (!crudRequests.updateList) {
        throw new Error("PUT list not implemented");
      }
      return crudRequests.updateList(current, userId);
    },
  };

  return provider;
}

import React from "react";

import nameof from "ts-nameof.macro";

import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./ui/app/store";
import App from "./ui/App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";

import { token } from "brandi";

import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "./ui/theme";
import { BrowserRouter } from "react-router-dom";
import { AuthContextProvider } from "./ui/auth/context";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { DependencyInjectionContainerProvider } from "./infrastructure/ioc/DependencyInjectionContainerProvider";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />

      <ThemeProvider theme={theme}>
        <Provider store={store}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <BrowserRouter>
            <AuthContextProvider>
              <DependencyInjectionContainerProvider>
                <App />
              </DependencyInjectionContainerProvider>
            </AuthContextProvider>
          </BrowserRouter>
        </Provider>
      </ThemeProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from "react";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useAuthContext } from "../../auth/context";
import LOGO from "../../../assets/logo.png";
import DASHBOARD_ICON from "../../../assets/dashboard_icon.svg";
import PACKAGE_ICON from "../../../assets/package_icon.svg";
import CHART_ICON from "../../../assets/chart_icon.svg";
import SEARCH_ICON from "../../../assets/search_icon.svg";
import SETTINGS_ICON from "../../../assets/settings_icon.svg";
import AVATAR_ICON from "../../../assets/avatar.png";
import LOGOUT_ICON from "../../../assets/logout_icon.svg";
import MainHolder from "./MainHolder";
import { ROUTES } from "../../app-routes/routeConfig";
import { DivBox } from "ui/library/Layout/DivBox";

type Props = {
  children?: React.ReactNode;
};

export function NavBar({ children }: Props) {
  const authContext = useAuthContext();
  const location = useLocation();

  return (
    <DivBox className="nav-bar" sx={{ height: "100%" }}>
      <Box component="div" sx={{ display: "flex", height: '100%' }}>
        <Box
          component="nav"
          sx={{
            position: "fixed",
            zIndex: 9999,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            background: "#0074A7",
            height: "100vh",
            overflow: "hidden",
            padding: "2.5rem 1rem",
          }}
          className="left-nav-bar"
        >
          <Box
            component="div"
            sx={{
              textAlign: "left",
            }}
          >
            <img src={LOGO} alt="Logo" className="logo-img" />
          </Box>
          <List>
            {[
              {
                text: "Dashboard",
                icon: DASHBOARD_ICON,
                link: ROUTES.WELCOME_SCREEN.path,
                active: location.pathname === "/welcome-screen",
              },
              {
                text: "Catalogue",
                icon: PACKAGE_ICON,
                link: ROUTES.ADMIN.PRICING_SHEET.path,
                active: /equipment-definition|catalogue|pricing-sheet/.test(
                  location.pathname
                ),
              },
              {
                text: "Project",
                icon: CHART_ICON,
                link: ROUTES.SALES.LIST.path,
                active: /sales/.test(location.pathname),
              },
              { text: "Users", icon: SEARCH_ICON, link: "/", active: false },
            ].map((item, index) => (
              <ListItem
                button
                key={index}
                component={RouterLink}
                to={item.link}
                sx={{ paddingLeft: 0 }}
                className={
                  item.active
                    ? "list-item-single list-item-active"
                    : "list-item-single"
                }
              >
                <ListItemIcon sx={{ minWidth: "auto" }}>
                  <img src={item.icon} alt="" />
                </ListItemIcon>
                <ListItemText primary={item.text} className="list-item-text" />
              </ListItem>
            ))}
          </List>
          <List className="last-items">
            <ListItem
              button
              component={RouterLink}
              to="/"
              sx={{ paddingLeft: 0, minWidth: "auto" }}
            >
              <ListItemIcon sx={{ minWidth: "auto" }}>
                <img src={SETTINGS_ICON} alt="Settings" />
              </ListItemIcon>
              <ListItemText primary="Settings" className="list-item-text" />
            </ListItem>
            <ListItem
              button
              component={RouterLink}
              to="/"
              sx={{ minWidth: "auto", padding: 0, margin: 0 }}
            >
              <ListItemIcon sx={{ minWidth: "auto" }}>
                <img src={AVATAR_ICON} alt="User" />
              </ListItemIcon>
              <ListItemText
                primary={authContext.user?.loginName}
                className="list-item-text username-ellipses"
              />
              <IconButton
                edge="end"
                aria-label="logout"
                className="list-item-text logout-btn"
              >
                <img src={LOGOUT_ICON} alt="Logout" />
              </IconButton>
            </ListItem>
          </List>
        </Box>
        <MainHolder>{children}</MainHolder>
      </Box>
    </DivBox>
  );
}

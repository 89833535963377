import { useParentMatchFunc } from "./useParentRouteMatch";

/** matches the current route (location) with a parent route */
export function useVariantFromRoute<TVariant>(
  routeVariantMap: [{ path: string; }, TVariant][]
): TVariant | undefined {
  const parentMatch = useParentMatchFunc();

  const matchedPair = routeVariantMap.find(([route]) => parentMatch(route));

  return matchedPair ? matchedPair[1] : undefined;
}

import Header, {
  SalesProcessHeader,
} from "../../shared/headers/SalesProcessHeader";
import SalesProcessFooter from "../../shared/Footer";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../app-routes/routeConfig";
import { Box, Button, Container, Typography } from "@mui/material";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { CustomerDetails as CustomerDetailsModel } from "../../models/customerDetails";
import * as yup from "yup";
import { useAppContext } from "../../app/context";
import { FieldMeta } from "../../library/Form/fieldMeta/fieldMeta";
import { StandardForm } from "../../library/Form";
import { FeatureContainer } from "../../library/Layout/FeatureContainer";
import { useEffect } from "react";

const initialValues = {
  email: "",
  firstName: "",
  lastName: "",
  telephone: "",
  company: "",
};

const fields: FieldMeta<CustomerDetailsModel>[] = [
  {
    container: "column",
    containerGridSize: 6,
    fields: [
      {
        key: "firstName",
        field: "text-input",
      },
    ],
    label: "First name",
  },
  {
    container: "column",
    containerGridSize: 6,
    fields: [
      {
        key: "lastName",
        field: "text-input",
      },
    ],
    label: "Last name",
  },
  {
    key: "company",
    field: "text-input",
    label: "Company",
  },
  {
    key: "email",
    field: "text-input",
    label: "Email",
  },
  {
    key: "telephone",
    field: "text-input",
    label: "Phone",
  },
];

const validationSchema: yup.SchemaOf<CustomerDetailsModel> = yup
  .object()
  .shape({
    email: yup
      .string()
      .email("Enter a valid email")
      .required("Email is required"),
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    telephone: yup.string(),
    company: yup.string(),
  });

const CustomerDetails = () => {
  const navigate = useNavigate();
  const parentContext = useAppContext();
  const { customerDetails } = parentContext.order.state;
  const { updateCustomerDetails } = parentContext.order.dispatch;

  const onSubmit = async (values: CustomerDetailsModel) => {
    updateCustomerDetails(values);
    navigate(ROUTES.SALES_PROCESS.DESIGN.path);
  };

  return (
    <>
      <FeatureContainer
        header={<SalesProcessHeader />}
        footer={<SalesProcessFooter />}
      >
        <Box component={"div"} mb={25}>
          <Typography
            component={"h3"}
            sx={{
              fontSize: "1.875rem",
              fontWeight: 600,
              color: "#1e1e1e",
              textAlign: "center",
              marginTop: "2.5rem",
              marginBottom: "2.5rem",
            }}
          >
            Customer Details
          </Typography>
          <div className="new-sale-form">
            <Container maxWidth="sm">
              <StandardForm<CustomerDetailsModel>
                fields={fields}
                initialValues={customerDetails ?? initialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                submitButton={
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    endIcon={<ArrowForward />}
                    sx={{
                      width: "100%",
                      padding: "0.625rem 1.125rem",
                      borderRadius: "0.5rem",
                      border: "1px solid #0074a7",
                      background: "#0074a7",
                      boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                      fontSize: "1rem",
                      fontWeight: 600,
                      color: "#ffffff",
                      textTransform: "capitalize",
                      marginTop: 0,
                    }}
                  >
                    Continue
                  </Button>
                }
              />
            </Container>
          </div>
        </Box>
      </FeatureContainer>
    </>
  );
};

export default CustomerDetails;

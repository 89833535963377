import { useEffect, useState } from "react";
import { IProduct } from "../../../../application/models/IProduct";
import { DataTable } from "../../../library/DataTable";
import { CatalogueMaster } from "../equipmentDefinition/CatalogueMaster";
import { PricingSheetContextProvider, usePricingSheetContext } from "./context";
import { useLocation, useNavigate } from "react-router-dom";
import CreateEditModal from "./components/CreateEditModal";
import { generateUUID } from "three/src/math/MathUtils";
import { ROUTES } from "ui/app-routes/routeConfig";
import { AppModal } from "ui/library/AppModal";
import { Button } from "@mui/material";
import { submitButtonStyle } from "ui/library/AppModal/ModalOptionButtons";

function Layout() {
  const navigate = useNavigate();
  const { getPage, deleteProduct, products } = usePricingSheetContext();
  const [editModal, setEditModal] = useState<boolean>(false);
  const [editData, setEditData] = useState<IProduct>();
  const [key, setKey] = useState(generateUUID()); //dirty hack to repaginate
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const location = useLocation();
  const urlType = new URLSearchParams(location.search).get("type");

  useEffect(() => {
    if (urlType === "create") {
      setEditModal(true);
      setEditData(undefined);
    }
  }, [urlType, location]);

  return (
    <>
      <CatalogueMaster>
        <DataTable<IProduct>
          key={key}
          columns={["name", "description", "price", "currencyIso", "sku"]}
          actionPerRowEnabled={{
            delete: ({ isPublic }) => !isPublic,
            edit: ({ isPublic }) => !isPublic,
          }}
          handleEdit={(x) => {
            setEditData(x);
            setEditModal(!editModal);
          }}
          handleDelete={async ({ id }) => {
            await deleteProduct(id);
            setConfirmModal(true);
          }}
          pagination={{
            getPage: (request) => getPage(request),
            pageSize: 5,
          }}
          checkboxSelection={true}
          sx={{ padding: 0, marginTop: "2.25rem" }}
        />
      </CatalogueMaster>
      {editModal && (
        <CreateEditModal
          isOpen={editModal}
          handleClose={() => {
            setEditModal(!editModal);
            setKey(generateUUID());
          }}
          editData={editData}
        />
      )}
      {confirmModal && (
        <AppModal
          isOpen={confirmModal}
          handleClose={() => setConfirmModal(!confirmModal)}
          title="Products"
          subTitle="Successfully deleted product"
          type="confirm"
        >
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={submitButtonStyle}
            onClick={() => navigate(ROUTES.ADMIN.PRICING_SHEET.path)}
          >
            Continue
          </Button>
        </AppModal>
      )}
    </>
  );
}

export function PricingSheet() {
  return (
    <PricingSheetContextProvider>
      <Layout />
    </PricingSheetContextProvider>
  );
}

import { IWorkstationType } from "../../../../application/models/IWorkstationType";
import { DataTable } from "../../../library/DataTable";
import { useState } from "react";
import { EquipmentDefintionDetail } from "./components/detail";
import {
  EquipmentDefinitionTableContextProvider,
  useEquipmentDefinitionTableContext,
} from "./context";
import { CatalogueMaster } from "./CatalogueMaster";
import { ROUTES } from "../../../app-routes/routeConfig";
import { useNavigate } from "react-router-dom";
import { AppModal } from "ui/library/AppModal";
import { Button } from "@mui/material";
import { submitButtonStyle } from "ui/library/AppModal/ModalOptionButtons";

function Layout() {
  const navigate = useNavigate();

  const { equipmentDefintions, deleteDefinition } =
    useEquipmentDefinitionTableContext();

  const [confirmModal, setConfirmModal] = useState<boolean>(false);

  return (
    <>
      <CatalogueMaster>
        <>
          <DataTable<RowModel>
            data={equipmentDefintions}
            columns={["name", "description", "dateUpdated", "price"]}
            handleEdit={(x) => {
              if (x.isPublic) {
                alert("can't edit public/shared equipment definitions");
                return;
              }
              const pth =
                ROUTES.ADMIN.EQUIPMENT_DEFINITION.LIST.$.EDIT.buildRelativePath(
                  {
                    id: x.id,
                  }
                );
              navigate(pth);
            }}
            handleDelete={async ({ id }) => {
              await deleteDefinition(id);
              const checkEquipment = equipmentDefintions.filter(
                (item) => item.id === id
              );
              if (checkEquipment.length === 0) {
                setConfirmModal(true);
              }
            }}
            actionPerRowEnabled={{
              delete: ({ isPublic }) => !isPublic,
              edit: ({ isPublic }) => !isPublic,
            }}
            sx={{ padding: 0, marginTop: "2.25rem" }}
            checkboxSelection={true}
          />
          <EquipmentDefintionDetail />
        </>
      </CatalogueMaster>
      {confirmModal && (
        <AppModal
          isOpen={confirmModal}
          handleClose={() => setConfirmModal(!confirmModal)}
          title="Equipments"
          subTitle="Successfully deleted equipment"
          type="confirm"
        >
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={submitButtonStyle}
            onClick={() =>
              navigate(ROUTES.ADMIN.EQUIPMENT_DEFINITION.LIST.path)
            }
          >
            Continue
          </Button>
        </AppModal>
      )}
    </>
  );
}

export function EquipmentDefinitionList() {
  return (
    <EquipmentDefinitionTableContextProvider>
      <Layout />
    </EquipmentDefinitionTableContextProvider>
  );
}

type RowModel = IWorkstationType;

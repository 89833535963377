import { createContext, useContext, useEffect, useState } from "react";
import { IWorkstationInstance } from "../../../application/models/IWorkstationInstance";
import { IWorkstationType } from "../../../application/models/IWorkstationType";
import { Price } from "../../../common/models/price";
import { useAppContext } from "../../app/context";
import { CustomerDetails } from "../../models/customerDetails";
import { WorkpanelScreen } from "./models/WorkpanelScreen";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../app-routes/routeConfig";
import { IProductBomItem } from "application/models/IBomItem";
import { ConfigurationTemplateDetails } from "application/models/configurationTemplateDetails";
import { ConfigurationTemplateDetailsWithId } from "application/models/ConfigurationTemplateDetailsWithId";
import OrthonormalVector from "common/math/OrthonormalVector";

//TODO split the context
export interface IWorkPanelContext {
  getBoM: () => Promise<IProductBomItem[]>;
  customerDetails: CustomerDetails | undefined;
  acceptEquipmentDragAddition: () => void;
  beginEquipmentDragAddition: (workstationTypeId: string) => void;
  isDragAdditionInProgress: boolean;
  handleStationRotationChange: (
    workstationInstanceId: string,
    rad: number
  ) => void;
  workstationTypes: IWorkstationType[];

  getSortedWorkstationInstances: () => (IWorkstationInstance & {
    typeName: string;
  })[];
  updateWorkstationInstances:
    | React.Dispatch<React.SetStateAction<IWorkstationInstance[]>>
    | undefined;
  updateSelectedWorkstationInstanceId: ((x: string) => void) | undefined;
  updateFocusedWorkstationInstanceId: ((x: string) => void) | undefined;
  selectedWorkstationInstanceId: string | null;
  getAssemblyComponentPrice: (componentDefinitionId: string) => {
    amount: number;
    currencySymbol: string;
  } | null;

  getWorkstationInstancePrice: (id: string) => Promise<Price | null>;
  productionLineTotalPrice: Price | null;
  handleStationTypeChange: (
    workstationInstanceId: string,
    workstationTypeId: string
  ) => void;
  handleStationRemove: (workstationInstanceId: string) => void;

  setScreen: (screen: WorkpanelScreen) => void;
  selectedScreen: WorkpanelScreen | undefined;

  handleSendOrder: () => Promise<void>;

  createTemplate(details: ConfigurationTemplateDetails): void;
  updateTemplate(details: ConfigurationTemplateDetails): void;
  currentTemplate: ConfigurationTemplateDetailsWithId | undefined;

  isAdditionByConnectorMode: boolean;
  clearConnectorSelection: () => void;
  addEquipmentByConnector(equipmentDefinitionId: string): Promise<void>
}

export const WorkPanelContext = createContext<IWorkPanelContext | undefined>(
  undefined
);

type Props = {
  children?: React.ReactNode;
};

export const WorkPanelContextProvider: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const parentContext = useAppContext();

  const {
    getSortedWorkstationInstances,
    addWorkstationInstance,
    updateWorkstationInstances,
    updateSelectedWorkstationInstanceId,
    handleStationRemove,
    handleStationRotationChange,
    updateFocusedWorkstationInstanceId,
    handleStationTypeChange,
    setSelectedConnector,
  } = parentContext.workstation.dispatch;

  //STATE
  const { selectedWorkstationInstanceId, selectedConnector } =
    parentContext.workstation.state;
  const { customerDetails } = parentContext.order.state;
  const { workstationTypes, dragAddedEquipmentTypeId } =
    parentContext.equipmentDefinition.state;
  const { beginEquipmentDragAddition, acceptEquipmentDragAddition } =
    parentContext.equipmentDefinition.dispatch;
  const { productionLineTotalPrice } = parentContext.quoting.state;
  const { currentTemplate } = parentContext.predefinedLayout.state;

  const { handleSendOrder } = parentContext.order.dispatch;

  const { getItemPrice, getWorkstationInstancePrice, getBoM } =
    parentContext.quoting.dispatch;

  const { createTemplate, updateTemplate } =
    parentContext.predefinedLayout.dispatch;

  let [screen, setScreen] = useState<WorkpanelScreen>();

  const isAdditionByConnectorMode = selectedConnector != null;

  function clearConnectorSelection() {
    setSelectedConnector(undefined);
  }

  useEffect(() => {
    if (selectedConnector) {
      setScreen("equipmentDefinitionTiles");
    }
  }, [selectedConnector]);

  const getSortedworkstationInstances = () => {
    return getSortedWorkstationInstances().map((x) => {
      const typeName = workstationTypes.find(
        (t) => t.id === x.workstationTypeId
      )?.name;

      if (!typeName) {
        //TODO: creat mechanism to resolve deleted equipment
        throw new Error(
          `couldn't resolve equipment definition for a saved configuration, equipment instance: ${x.id}`
        );
      }

      return { ...x, typeName: typeName };
    });
  };

  async function addEquipmentByConnector(equipmentDefinitionId: string) {
    if (!addWorkstationInstance){
      throw new Error("add workstation instance function not defined")
    }
    await addWorkstationInstance(equipmentDefinitionId);
  }

  //TODO: hacky! use proper routing
  useEffect(() => {
    switch (screen) {
      case "orderReview":
        navigate(ROUTES.SALES_PROCESS.DESIGN.REVIEW.path);
        break;

      default:
        break;
    }
  }, [screen]);

  return (
    <WorkPanelContext.Provider
      value={{
        workstationTypes: workstationTypes,
        getSortedWorkstationInstances: getSortedworkstationInstances,
        updateWorkstationInstances: updateWorkstationInstances,
        selectedWorkstationInstanceId: selectedWorkstationInstanceId,
        updateSelectedWorkstationInstanceId,
        getAssemblyComponentPrice: getItemPrice!,
        getWorkstationInstancePrice: getWorkstationInstancePrice,
        productionLineTotalPrice: productionLineTotalPrice,
        handleStationTypeChange,
        handleStationRemove: handleStationRemove,
        handleStationRotationChange: handleStationRotationChange,
        beginEquipmentDragAddition,
        isDragAdditionInProgress: dragAddedEquipmentTypeId != null,
        acceptEquipmentDragAddition: acceptEquipmentDragAddition,
        updateFocusedWorkstationInstanceId,
        customerDetails,
        setScreen,
        selectedScreen: screen,
        getBoM,
        handleSendOrder,
        createTemplate,
        updateTemplate,
        currentTemplate,
        isAdditionByConnectorMode,
        clearConnectorSelection,
        addEquipmentByConnector
      }}
    >
      {children}
    </WorkPanelContext.Provider>
  );
};

export function useWorkPanelContext() {
  const context = useContext(WorkPanelContext);

  if (!context) {
    throw new Error("context must be obtained within WorkPanelContextProvider");
  }

  return context;
}

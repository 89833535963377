import { useMatch } from "react-router-dom";
import { ROUTES } from "../app-routes/routeConfig";
import { DivBox } from "ui/library/Layout/DivBox";
import { styled } from "@mui/material";

export default function SalesProcessFooter() {
  let variant:
    | "customer-onboarding"
    | "design"
    | "review"
    | "projects"
    | undefined;

  const isCustomerOnboardingStep = useMatch(
    ROUTES.SALES_PROCESS.CUSTOMER_DETAILS
  );
  const isDesignStep = useMatch(ROUTES.SALES_PROCESS.DESIGN);
  const isReviewStep = useMatch(ROUTES.SALES_PROCESS.DESIGN.REVIEW);

  if (isCustomerOnboardingStep) variant = "customer-onboarding";
  if (isDesignStep) variant = "design";
  if (isReviewStep) variant = "review";

  return (
    <FooterContainer >
      <DivBox className="sale-footer">
        <ul>
          <li className={variant == "customer-onboarding" ? "active" : ""}>
            <div>
              <h6>Customer Onboarding</h6>
              <p>Please provide company details</p>
            </div>
          </li>
          <li className={variant == "design" ? "active center" : "center"}>
            <div>
              <h6>Equipments</h6>
              <p>Add equipments as per the requirement</p>
            </div>
          </li>
          <li className={variant == "review" ? "active" : ""}>
            <div>
              <h6>Review</h6>
              <p>View and confirm the invoice</p>
            </div>
          </li>
        </ul>
      </DivBox>
    </FooterContainer>
  );
}

const FooterContainer = styled(DivBox)({

  //marginTop: 'auto'
});

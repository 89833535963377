import axios from "axios";
import { useAuthContext } from "../../ui/auth/context";

export const TOKEN_REFRESH_OFFSET_MINUTES = 2;
export const MS_PER_MINUTE = 60000;

export function useAuthenticatedClientInstance() {
  const { getAccessTokenExpirationTS, logout, refreshToken } = useAuthContext();

  const axiosApiResourceInstance = axios.create();

  axiosApiResourceInstance.interceptors.response.use(
    async (response) => {
      const expirationTs = getAccessTokenExpirationTS();

      if (expirationTs == null) {
        logout();
        return;
      }

      const now = new Date();

      const offsettedExpirationTS = new Date(
        expirationTs.getTime() - TOKEN_REFRESH_OFFSET_MINUTES * MS_PER_MINUTE
      );

      if (now >= offsettedExpirationTS) {
        const isRefreshed = await refreshToken();

        if (!isRefreshed) {
          logout();
          return;
        }
      }

      // Any status code that lie within the range of 2xx cause this function to trigger
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        // Handle the 401 error
        logout();
      }
      // If it's not a 401 error, just reject the promise as usual
      return Promise.reject(error);
    }
  );

  return axiosApiResourceInstance;
}

import {
  number,
  boolean,
  hashValues,
  route,
  string,
} from "react-router-typesafe-routes/dom";

export const DESIGN_FRAGMENTS = {
  
  ADD_WORKSTATION_INSTANCE: route(
    "add-workstation",
    {},
    {
      BY_CONNECTOR: route("byconnector/:previd", {
        params: {
          previd: string(),
        },
        state: {
          dirX: number(),
          dirY: number(),
          dirZ: number(),
        },
      }),
    }
  ),
};


export const DESIGN = route(
  "design",
  {},
  {
    EDIT: route("edit", {}, DESIGN_FRAGMENTS),
    CREATE: route("create", {}, DESIGN_FRAGMENTS),
    REVIEW: route("review"),
  }
);


export const ROUTES = {
  WELCOME_SCREEN: route("welcome-screen"),
  SALES_PROCESS: route(
    "sales-process",
    {},
    {
      CUSTOMER_DETAILS: route("customer-details"),
      DESIGN,
      REVIEW: route(
        "review",
        {},
        {
          ORDER_SUMMARY: route("summary"),
          ORDER_SUCCESS: route("success"),
        }
      ),
    }
  ),
  ADMIN: route(
    "admin",
    {},
    {
      EQUIPMENT_DEFINITION: route(
        "equipment-definition",
        {},
        {
          LIST: route(
            "list",
            {},
            {
              EDIT: route("edit/:id", {
                params: {
                  id: string(),
                },
              }),
              CREATE: route("create"),
            }
          ),
        }
      ),
      PRICING_SHEET: route("pricing-sheet"),
    }
  ),
  LOGIN: route("login"),
  LOGIN_PASSWORD: route("login-password"),
  LOGIN_SSO: route("login-sso"),
  LOGOUT: route("logout"),
  AUTH: route(
    "auth",
    {},
    {
      SSO_LOGIN: route("sso-login", { params: { token: string().defined() } }),
    }
  ),

  CATALOGUE: route(
    "catalogue",
    {},
    {
      CONFIGURATION_TEMPLATE: route(
        "configuration-template",
        {},
        {
          LIST: route("list"),
          DESIGN,
        }
      ),
    }
  ),
  SALES: route(
    "sales",
    {},
    {
      LIST: route("list"),
      DESIGN,
    }
  ),
};

import { IRepository } from "application/repositories/IRepository";
import { IUserService } from "./UserService";
import { TableRequest } from "infrastructure/paging/paging";
import { IFilteredListResult } from "ui/app/store/pagination/pagination";

export interface ICrudService<T> {
  update(current: T): Promise<void>;

  updateList(current: T[]): Promise<void>;

  create(item: T): Promise<T | undefined>;

  getList(request?: TableRequest<T>): Promise<IFilteredListResult<T>>;

  delete(id: string): Promise<void>;

  get(id: string, userId: string): Promise<T | undefined>;
}

export class CrudService<T> implements ICrudService<T> {
  private _repository: IOptionalRepository<T>;
  public readonly _userId: string;

  public constructor(
    repository: IOptionalRepository<T>,
    userService: IUserService
  ) {
    this._repository = repository;
    this._userId = userService.getCurrentUserId();
  }

  public update(current: T): Promise<void> {
    if (!this._repository.update)
      throw new Error("repository method not implemented");
    return this._repository.update(current, this._userId);
  }

  updateList(current: T[]): Promise<void> {
    if (!this._repository.updateList)
      throw new Error("repository method not implemented");
    return this._repository.updateList(current, this._userId);
  }

  create(item: T): Promise<T | undefined> {
    if (!this._repository.create)
      throw new Error("repository method not implemented");
    return this._repository.create(item, this._userId);
  }

  getList(request?: TableRequest<T>): Promise<IFilteredListResult<T>> {
    if (!this._repository.getList)
      throw new Error("repository method not implemented");
    return this._repository.getList(this._userId, request);
  }

  delete(id: string): Promise<void> {
    if (!this._repository.delete)
      throw new Error("repository method not implemented");
    return this._repository.delete(id, this._userId);
  }

  get(id: string): Promise<T | undefined> {
    if (!this._repository.get)
      throw new Error("repository method not implemented");
    return this._repository.get(id, this._userId);
  }
}

export interface IOptionalRepository<T> {
  update?: (current: T, userId: string) => Promise<void>;
  updateList?: (current: T[], userId: string) => Promise<void>;
  create?: (item: T, userId: string) => Promise<T | undefined>;
  getList?: (
    userId: string,
    request?: TableRequest<T>
  ) => Promise<IFilteredListResult<T>>;
  delete?: (id: string, userId: string) => Promise<void>;
  get?: (id: string, userId: string) => Promise<T | undefined>;
}

import { Box, Button, Grid } from "@mui/material";

import SearchIcon from "../../../../assets/search-lg.svg";
import Add from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { useWelcomeScreenContext } from "../context";
import { ROUTES } from "../../../app-routes/routeConfig";

const Header = () => {
  const navigate = useNavigate();
  const context = useWelcomeScreenContext();

  return (
    <div className="all-new-header">
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item className="left-side">
          <h1>Welcome back, {context.userName}</h1>
          <p>Track your sales, orders, & stats here</p>
        </Grid>
        <Grid item>
          <Box
            component={"div"}
            sx={{ display: "flex", alignItems: "center", gap: "0.75rem" }}
          >
            <div className="search">
              <input type="text" placeholder="Search" />
              <img src={SearchIcon} alt="search" className="img-fluid" />
            </div>
            <Button
              className="new-btn"
              onClick={() =>
                navigate(ROUTES.ADMIN.EQUIPMENT_DEFINITION.LIST.path)
              }
            >
              View Equipments
            </Button>
            <Button
              startIcon={<Add />}
              className="new-btn-active"
              onClick={() => {
                context.clearOrder();
                navigate(ROUTES.SALES_PROCESS.CUSTOMER_DETAILS.path);
              }}
            >
              New Project
            </Button>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Header;

import { Box, Button, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { Link } from "react-router-dom";
import React from "react";

interface Tab {
  name: string;
  active: boolean;
  count: number;
  url: string;
}

const PageTabs = ({ tabs }: { tabs: Tab[] }) => {
  return (
    <Box
      component="div"
      className="page-tabs"
      sx={{ marginTop: "1.62rem", display: "flex", gap: "0.75rem" }}
    >
      {tabs.map((item) => (
        <Button
          component={Link}
          to={item.url}
          startIcon={
            item.active && (
              <CircleIcon
                sx={{ color: "#17B26A" }}
                className="svg-icon-pagetabs"
              />
            )
          }
          sx={{
            display: "flex",
            padding: "0.5rem 0.75rem",
            alignItems: "center",
            borderRadius: "0.375rem",
            background: item.active ? "#F9FAFB" : "",
            boxShadow: item.active ? "0px 0px 0px 4px #F2F4F7" : "",
            color: item.active ? "#101828" : "#344054",
            textTransform: "capitalize",
            fontSize: "1rem",
            fontWeight: 600,
            gap: "0.5rem",
          }}
        >
          {item.name}
          <Typography
            component="span"
            sx={{
              padding: "0.125rem 0.5rem",
              borderRadius: "1rem",
              border: "1px solid #EAECF0",
              background: "#F9FAFB",
              fontSize: "0.75rem",
              fontWeight: 500,
              marginBottom: 0,
            }}
          >
            {item.count}
          </Typography>
        </Button>
      ))}
    </Box>
  );
};

export default PageTabs;

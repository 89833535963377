import { IOrder } from "application/models/IOrder";
import { IOrderService } from "application/services/OrderService";
import { User } from "ui/auth/User";
import { IWorkstationInstanceSlice } from "../workstationInstance/slice";
import { buildConfigurationState } from "../helpers/buildConfigurationState";
import { useState } from "react";
import { generateUUID } from "three/src/math/MathUtils";
import { IQuotingSlice } from "../quoting/slice";
import { CustomerDetails } from "ui/models/customerDetails";
import { IConfigurationService } from "application/services/ConfigurationService";
import { IOrderEmail } from "application/models/IOrderEmail";
import { ISendOrderService } from "application/services/SendOrderService";
import { ICheckoutService } from "application/services/CheckoutService";
import { IOrderDispatch, IOrderSlice, IOrderState } from "./orderSlice";

const DEFAULT_ORDER_NAME = "Order";

export function useOrderReducer(
  user: User | undefined,
  services: {
    orderService: IOrderService;
    configurationService: IConfigurationService;
    checkoutService: ICheckoutService;
    sendOrderService: ISendOrderService;
  },
  orderSlice: IOrderSlice,
  configurationSlice: IWorkstationInstanceSlice,
  quotingSlice: IQuotingSlice
) {
  const { customerDetails, currentOrder } = orderSlice.state;
  const { updateCustomerDetails, setCurrentOrder, clearOrder } = orderSlice.dispatch;

  const { orderService, checkoutService, sendOrderService } = services;

  const { workstationInstances } =
    configurationSlice.state;
  const { productionLineTotalPrice } = quotingSlice.state;

  const {
    setConfiguration,
    loadConfiguration,
    clearConfiguration,
  } = configurationSlice.dispatch;

  const saveCurrentOrder = async () => {
    const allNames = (await orderService.getList(user?.id!)).map((x) => x.name);

    let updatedOrder: IOrder | undefined;

    const isNewInstance = currentOrder == null;

    if (customerDetails == null) {
      throw new Error("Customer details need to be filled out!");
    }

    let orderModel = currentOrder
      ? { ...currentOrder, ...customerDetails }
      : undefined;
    let orderId = orderModel?.id ?? generateUUID();

    const name = `${DEFAULT_ORDER_NAME} ${allNames.length + 1}`;

    const configurationState = buildConfigurationState(
      configurationSlice.state,
      name,
      productionLineTotalPrice ?? undefined
    );

    let currencySymbol = productionLineTotalPrice?.currencySymbol ?? "USD";

    if (currencySymbol?.length <= 0) {
      currencySymbol = "USD";
    }
    if (orderModel == null) {
      orderModel = {
        name,
        currencySymbol: currencySymbol,
        id: orderId,
        totalAmount: productionLineTotalPrice?.amount ?? 0,
        ...customerDetails,
      };
    }

    if (isNewInstance) {
      updatedOrder = await orderService.create(
        orderModel,
        configurationState,
        user!.id
      );
    } else {
      updatedOrder = await orderService.update(
        orderModel,
        configurationState,
        user!.id
      );
    }

    if (updatedOrder != null) {
      setConfiguration(workstationInstances);
      setCurrentOrder(updatedOrder);
    }

    return updatedOrder;
  };

  const continueOrder = async (orderId: string) => {
    const order = await orderService.get(orderId, user?.id!);

    if (order) {
      const updatedCustomerDetails: CustomerDetails = { ...order };
      await loadConfiguration(order.configurationId);
      updateCustomerDetails(updatedCustomerDetails);
      setCurrentOrder({ ...order });
    } else {
      alert("Error loading order!");
    }
  };

  async function handleSendOrder() {
    if (customerDetails) {
      const bom = await checkoutService.getEquipmentBoM(workstationInstances);

      const order: IOrderEmail = {
        ...customerDetails,
        bom,
        totalPrice: productionLineTotalPrice ?? {
          amount: 0,
          currencySymbol: "USD",
        },
      };

      await sendOrderService.postOrder(order);
      clearOrder();
      clearConfiguration();
    } else {
      alert("please fill customer details");
    }
  }

  const reducer: {
    state: IOrderState;
    dispatch: IOrderDispatch & IExtraActions;
  } = {
    state: orderSlice.state,
    dispatch: {
      ...orderSlice.dispatch,
      clearOrder: () => {
        clearOrder();
        clearConfiguration();
      },
      getList: () => orderService.getList(user!.id),
      saveCurrentOrder,
      continueOrder,
      handleSendOrder,
    },
  };

  return reducer;
}

export interface IExtraActions {
  getList: () => Promise<IOrder[]>;
  saveCurrentOrder: () => Promise<IOrder | undefined>;
  continueOrder: (orderId: string) => Promise<void>;
  handleSendOrder: () => Promise<void>;
}

export interface IOrderReducer {
  state: IOrderState;
  dispatch: IOrderDispatch & IExtraActions;
}

import { IConfiguration } from "../../../../application/models/IProject";
import { IConfigurationService } from "../../../../application/services/ConfigurationService";
import { User } from "../../../auth/User";
import { buildConfigurationState } from "../helpers/buildConfigurationState";
import { IQuotingSlice } from "../quoting/slice";
import { IWorkstationInstanceSlice } from "../workstationInstance/slice";
import { IProjectDispatch, IProjectSlice, IProjectState } from "./slice";

export function useProjectReducer(
  projectSlice: IProjectSlice,
  workstationInstanceSlice: IWorkstationInstanceSlice,
  quotingSlice: IQuotingSlice,
  user: User | undefined,
  services: { configurationService: IConfigurationService },
  getScreenshotRef: React.MutableRefObject<
    | (() => {
        imgUrl: string;
      })
    | undefined
  >
): IProjectReducer {
  const { configurationService: projectService } = services;
  const slice = projectSlice;

  const saveProject = async (name: string, isNewInstance: boolean = false) => {
    let screenshotUrl: string | undefined = undefined;
    const { currentProjectId } = projectSlice.state;
    const { productionLineTotalPrice } = quotingSlice.state;

    if (getScreenshotRef.current) {
      const getScreenshot = getScreenshotRef.current;
      screenshotUrl = getScreenshot()?.imgUrl;
    }

    let updatedProject: IConfiguration;

    const configurationState = buildConfigurationState(
      workstationInstanceSlice.state,
      name,
      productionLineTotalPrice ?? undefined,
      currentProjectId
    );

    if (isNewInstance) {
      updatedProject = await projectService.requestConfigurationCreate(
        configurationState,
        user!.id
      );
    } else {
      updatedProject = await projectService.requestConfigurationUpdate(
        configurationState,
        user!.id
      );
    }

    return updatedProject;
  };

  const handleSaveProjectAs = async (name: string) => {
    const { projects } = projectSlice.state;
    const currentNames = projects.map((x) => x.name);

    if (currentNames.includes(name)) {
      alert("Error: set a new name for the project copy");
    } else {
      saveProject(name, true).then(async (updated) => {
        projectSlice.dispatch.setCurrentProjectName(name);
        await projectSlice.dispatch.refreshProjectList();
      });
    }
  };

  const saveCurrentProject = async () => {
    const updated = await saveProject(
      projectSlice.state.currentProjectName,
      projectSlice.state.isNewProject
    );

    projectSlice.dispatch.setIsNewProject(false);
    await projectSlice.dispatch.refreshProjectList();
  };

  const handleLoadProject = async (id: string) => {
    const project = await projectService.get(id, user!.id);

    if (project) {
      const updatedEquipments = JSON.parse(JSON.stringify(project.equipments));
      workstationInstanceSlice.dispatch.updateWorkstationInstances(
        updatedEquipments
      );
      projectSlice.dispatch.setCurrentProjectName(project.name);
      projectSlice.dispatch.setIsNewProject(false);
    }
  };

  const reducer = {
    dispatch: {
      ...slice.dispatch,
      handleSaveProjectAs,
      saveCurrentProject,
      handleLoadProject,
    },
    state: slice.state,
  };

  return reducer;
}

export interface IProjectReducer {
  state: IProjectState;
  dispatch: IProjectDispatch & {
    handleSaveProjectAs: (name: string) => Promise<void>;
    saveCurrentProject: () => Promise<void>;
    handleLoadProject: (id: string) => void;
  };
}

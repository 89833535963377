import { useNavigate } from "react-router-dom";
import CLOSE from "assets/close.svg";
import { ROUTES } from "../../../app-routes/routeConfig";
import { BaseHeader } from "../../../shared/headers/BaseHeader";
import { Box, Button } from "@mui/material";
import { useVariantFromRoute } from "../../../shared/hooks/useVariantFromRoute";
import { useHistoryGoBack } from "../../../shared/hooks/historyGoBack";
import { useState } from "react";
import { AppModal } from "ui/library/AppModal";
import { submitButtonStyle } from "ui/library/AppModal/ModalOptionButtons";

export function DesignHeader({
  handleSaveCurrentOrder,
}: {
  handleSaveCurrentOrder: () => Promise<void>;
}) {
  const [confirmModal, setConfirmModal] = useState<boolean>(false);

  const goBack = useHistoryGoBack();
  const navigate = useNavigate();

  const variant = useVariantFromRoute<"sales" | "template" | "projects">([
    [ROUTES.SALES_PROCESS, "sales"],
    [ROUTES.CATALOGUE.CONFIGURATION_TEMPLATE, "template"],
    [ROUTES.SALES.DESIGN, "projects"], //confusion between sales and projects!!!!
  ]);

  //todo create a way to match the route tralinng elements, instead of enumerating all combinations
  const editMode = useVariantFromRoute<"Edit" | "New">([
    [ROUTES.CATALOGUE.CONFIGURATION_TEMPLATE.DESIGN.EDIT, "Edit"],
    [ROUTES.CATALOGUE.CONFIGURATION_TEMPLATE.DESIGN.CREATE, "New"],

    [ROUTES.SALES.DESIGN.EDIT, "Edit"],
    [ROUTES.SALES.DESIGN.CREATE, "New"],
  ]);

  let title = "";
  let subtitle: string | undefined;
  let showSaveButton = false;
  let cancelRoute: string | undefined;

  switch (variant) {
    case "sales":
      title = "New Projects";
      subtitle = "Configure your production line";
      showSaveButton = true;
      cancelRoute = ROUTES.WELCOME_SCREEN.path;
      break;

    case "template":
      title = `${editMode} Template`;
      subtitle = `${editMode} a template configuration`;
      cancelRoute = ROUTES.CATALOGUE.CONFIGURATION_TEMPLATE.LIST.path;
      break;

    case "projects":
      title = `${editMode} Project`;
      subtitle = `${editMode} your configuration`;
      showSaveButton = true;
      break;

    default:
      break;
  }

  const saveProject: React.MouseEventHandler = async (e) => {
    e.preventDefault();

    await handleSaveCurrentOrder();

    // alert("Current configuration saved");
    setConfirmModal(true);
  };

  function onCancel() {
    if (cancelRoute != null) {
      navigate(cancelRoute);
    } else {
      goBack();
    }
  }

  return (
    <>
      <BaseHeader title={title} subtitle={subtitle}>
        <Box
          component={"div"}
          sx={{ textAlign: "end", display: "flex", alignItems: "center" }}
        >
          {showSaveButton && (
            <Button
              className="save header-button"
              onClick={saveProject}
              sx={{ marginRight: "10px" }}
              id="save-btn"
            >
              Save
            </Button>
          )}
          <Button className="header-button" onClick={() => onCancel()}>
            <img src={CLOSE} alt="close" className="img-fluid" /> Cancel
          </Button>
        </Box>
      </BaseHeader>
      {confirmModal && (
        <AppModal
          isOpen={confirmModal}
          handleClose={() => setConfirmModal(!confirmModal)}
          title="New Project"
          subTitle="Current configuration saved"
          type="confirm"
        >
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={submitButtonStyle}
            onClick={() => setConfirmModal(!confirmModal)}
          >
            Continue
          </Button>
        </AppModal>
      )}
    </>
  );
}

export const addEquipmentButtonStyle = {
  border: "1px solid #0074A7",
  borderRadius: "0.5rem",
  padding: "0.625rem 1rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textTransform: "capitalize",
  color: "#ffffff !important",
  fontSize: "0.875rem",
  fontWeight: 600,
  background: "#0074A7",
  "&:hover": {
    background: "#0074A7",
    color: "#ffffff",
  },
};
export const newTemplateButtonStyle = {
  border: "1px solid #D0D5DD",
  borderRadius: "0.5rem",
  padding: "0.625rem 1rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textTransform: "capitalize",
  color: "#1E1E1E",
  fontSize: "0.875rem",
  fontWeight: 600,
};

import { Box } from "@mui/material";
import React from "react";

export function Overlay(props: { children: JSX.Element | JSX.Element[] }) {
  console.log("OVERLAY");
  return (
    <Box
      component={"div"}
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        pointerEvents: "none",
      }}
    >
      <Box
        component={"div"}
        sx={{
          background: "transparent",
          minWidth: "35em",
          width: "100%",
          height: "100%",
          padding: "4vh",
          float: "right",
          position: "relative",
          zIndex: 10,
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
}

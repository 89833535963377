import { IConfigurationState } from "application/models/IConfigurationState";
import { IConfiguration } from "application/models/IProject";
import { IWorkstationInstance } from "application/models/IWorkstationInstance";
import { dateFieldsToString } from "infrastructure/resource-providers/helpers/dateHelpers";
import { generateUUID } from "three/src/math/MathUtils";
import { StrictOmit } from "common/types/strictOmit";

function mapStateToModel(state: IConfigurationState, isClone = false) {
  let model: IConfiguration = {
    ...state,
    updatedTS: new Date(),
    createdTS: new Date(),
    totalAmount: state.totalPrice?.amount ?? 0,
    currencySymbol: state.totalPrice?.currencySymbol ?? "USD",
  };

  if (isClone) {
    model = cloneConfiguration(model);
  }

  return model;
}

function cloneEquipments(input: IWorkstationInstance[]) {
  const originalIds = input.map((x) => x.id);
  const idMap = originalIds.map((old) => {
    return {
      old,
      new: generateUUID(),
    };
  });

  const output = [...input];
  output.forEach((x) => {
    x.previousInstanceId = idMap.find(
      (item) => x.previousInstanceId == item.old
    )?.new;
    x.id = idMap.find((item) => x.id == item.old)?.new!;
    x.assembly?.forEach((p) => {
      p.id = generateUUID();
    });
  });

  return output;
}

function cloneConfiguration(
  input: IConfiguration
): IConfiguration {
  return { ...input, equipments: cloneEquipments(input.equipments) };
}

function mapModelToRequest(model: IConfiguration) {
  const mappedParts = model.equipments.flatMap((e) =>
    e.assembly.map((x) => {
      return { ...x, workstationInstanceId: e.id };
    })
  );

  const mappedEquipments = model.equipments.map((x) => {
    return { ...x };
  });

  const mapped = {
    ...dateFieldsToString(model),
    equipments: mappedEquipments,
    parts: mappedParts,
  };
  return mapped;
}

export const configurationMapper = {
  mapStateToModel,
  mapModelToRequest,
  cloneConfiguration //todo to service
};



export function getNameWithExtension(filename: string) {
  filename = filename?.split('/')?.pop() ?? '';
  const extension = filename?.split('.')?.pop()?.toLowerCase();

  const name = filename.replace(`.${extension}`, '');

  return [
    name,
    extension
  ] as const;
}

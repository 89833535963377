import {
  EquipmentDefinitionApi,
  GeometryModelApi,
  ProductApi,
  ConfigurationsApi,
  OrdersApi,
  ConfigurationTemplatesApi,
} from "../api-client";
import { useAuthenticatedClientInstance } from "./useAuthenticatedResourceInstance";
import { commonApiConfiguration } from "./commonApiConfiguration";

export function useApiClients() {
  const axiosInstance = useAuthenticatedClientInstance();

  return {
    project: new ConfigurationsApi(
      commonApiConfiguration,
      undefined,
      axiosInstance
    ),
    product: new ProductApi(commonApiConfiguration, undefined, axiosInstance),
    equipmentDefinition: new EquipmentDefinitionApi(
      commonApiConfiguration,
      undefined,
      axiosInstance
    ),
    geometryModel: new GeometryModelApi(
      commonApiConfiguration,
      undefined,
      axiosInstance
    ),
    order: new OrdersApi(commonApiConfiguration, undefined, axiosInstance),
    configurationTemplate: new ConfigurationTemplatesApi(
      commonApiConfiguration,
      undefined,
      axiosInstance
    ),
  };
}

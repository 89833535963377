import { SaleList } from "./components/SaleList";
import { ProjectsContextProvider } from "./context";

//TODO: is it sales or projects????
export function Sales() {
  return (
    <ProjectsContextProvider>
      <SaleList />
    </ProjectsContextProvider>
  );
}

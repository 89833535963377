import { useNavigate } from "react-router-dom";
import { AppModal } from "../AppModal";
import { ReactNode } from "react";

export interface RoutedModalProps {
  /**Optional path override for when modal is closed */
  title: string;
  subtitle: string;
  onClosePath?: string;
  children: (api: RoutedModalApi) => ReactNode;
}

export interface RoutedModalApi {
  close: () => void;
}

/** this modal is always open. it is expected to be triggered by route change. on close, it will go either to 'onClosePath' route, or go back in history */
export function RoutedModal({
  onClosePath,
  children,
  title,
  subtitle,
}: RoutedModalProps) {
  const navigate = useNavigate();

  const handleClose = () => {
    if (onClosePath) {
      navigate(onClosePath);
    } else {
      navigate(-1);
    }
  };

  const api: RoutedModalApi = { close: handleClose };

  return (
    <AppModal
      handleClose={handleClose}
      title={title}
      subTitle={subtitle}
      isOpen={true}
    >
      <div>{children(api)}</div>
    </AppModal>
  );
}

export default RoutedModal;

import { createContext, useContext } from "react";
import { IConfigurationTemplate } from "application/models/IConfigurationTemplate";
import { useAppContext } from "../../app/context";
import nameof from "ts-nameof.macro";
import { IOrder } from "application/models/IOrder";
import { useFetchData } from "ui/app/hooks/useFetchData";

export interface IWelcomeScreenContext {
  clearOrder: () => void;
  handleLayoutSelected: (id: string) => void;
  productionLineLayouts: IConfigurationTemplate[];
  userName?: string;
  continueOrder: (orderId: string) => Promise<void>;
  recentSales: IOrder[];
}

export const WelcomeScreenContext = createContext<
  IWelcomeScreenContext | undefined
>(undefined);

type Props = {
  children?: React.ReactNode;
};

export const WelcomeScreenContextProvider: React.FC<Props> = ({ children }) => {
  const parentContext = useAppContext();

  const loginName = parentContext.user?.loginName;
  const { configurationTemplates: predefinedLayouts } =
    parentContext.predefinedLayout.state;
  const { loadConfigurationTemplate: handleProductionLineLayoutSelected } =
    parentContext.predefinedLayout.dispatch;
  const { clearOrder, continueOrder, getList } = parentContext.order.dispatch;

  const [orders] = useFetchData(getList);

  const recentSales = orders?.slice(0, 5) ?? [];

  return (
    <WelcomeScreenContext.Provider
      value={{
        handleLayoutSelected: handleProductionLineLayoutSelected,
        productionLineLayouts: predefinedLayouts,
        userName: loginName,
        clearOrder,
        continueOrder,
        recentSales,
      }}
    >
      {children}
    </WelcomeScreenContext.Provider>
  );
};

export function useWelcomeScreenContext() {
  const context = useContext(WelcomeScreenContext);

  if (!context) {
    throw new Error(
      `App Context must be obtained within ${nameof(
        WelcomeScreenContext
      )}Provider`
    );
  }

  return context;
}

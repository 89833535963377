import { useEffect, useState } from "react";
import { FetchStatus } from "../../../common/enums/FetchStatus";


export function useFetchData<T>(getData: () => Promise<T[]>) {
  const [data, setData] = useState<T[]>();
  const [status, setStatus] = useState<FetchStatus>("idle");

  const fetchData = async () => {
    setStatus("loading");
    try {
      const result = await getData();
      setData(result);
      setStatus("success");
    } catch (error) {
      console.error(error);
      setStatus("error");
    }
  };

  useEffect(() => {
    fetchData();
  }, [getData]);

  const refetch = fetchData;

  return [data, status, refetch] as const;
}

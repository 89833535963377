import MockService from "../../application/services/MockService";
import { createContainer } from "brandi";
import { ContainerProvider } from "brandi-react";
import DI_TOKENS from "../../DI_TOKENS";
import { MockDependency } from "../../application/MockDependency";
import { WorkstationTypeService } from "../../application/services/WorkstationTypeService";
import { BrowserStaticStorageContext } from "../storage/mockSeedStorageContext";
import { WorkstationInstanceService } from "../../application/services/WorkstationInstanceService";
import { ProductionLineService } from "../../application/services/ProductionLineService";
import { PriceCalculationService } from "../../application/services/PriceCalculationService";
import { ProductService } from "../../application/services/ProductService";
import { CheckoutService } from "../../application/services/CheckoutService";
import { EmailOrderService } from "../../application/services/SendOrderService";
import { ConfigurationService } from "../../application/services/ConfigurationService";
import { useAuthContext } from "../../ui/auth/context";
import { UserService } from "../../application/services/UserService";
import { buildProductProvider } from "../resource-providers/productProvider";
import { useApiClients } from "../hooks/useApiClients";
import { buildEquipmentDefinitionProvider } from "../resource-providers/equipmentDefinitionProvider";
import { GeometryModelService } from "../../application/services/GeometryModelService";
import { buildGeometryModelProvider } from "../resource-providers/geometryModelProvider";
import { useProjectProvider } from "infrastructure/resource-providers/projectProvider";
import { OrderService } from "application/services/OrderService";
import { buildOrderProvider } from "infrastructure/resource-providers/orderProvider";
import { ConfigurationTemplateService } from "application/services/ConfigurationTemplateService";
import { buildConfigurationTemplateProvider } from "infrastructure/resource-providers/configurationTemplateProvider";

export function DependencyInjectionContainerProvider(props: {
  children: JSX.Element | JSX.Element[];
}) {
  const { children } = props;

  const clients = useApiClients();

  const geometryModelProvider = buildGeometryModelProvider(
    clients.geometryModel
  );
  const productProvider = buildProductProvider(clients.product);
  const equipmentDefinitionProvider = buildEquipmentDefinitionProvider(
    clients.equipmentDefinition
  );
  const projectProvider = useProjectProvider();
  const orderProvider = buildOrderProvider(clients.order);
  const configurationTemplateProvider = buildConfigurationTemplateProvider(
    clients.configurationTemplate
  );

  const { user } = useAuthContext();

  const userService = new UserService(user?.id!);

  const buildDiContainer = () => {
    const di = createContainer();

    di.bind(DI_TOKENS.IWorkstationTypeService.Value)
      .toInstance(WorkstationTypeService)
      .inSingletonScope();
    di.bind(DI_TOKENS.IWorkstationInstanceService.Value)
      .toInstance(WorkstationInstanceService)
      .inSingletonScope();
    di.bind(DI_TOKENS.IMockService.Value)
      .toInstance(MockService)
      .inTransientScope();
    di.bind(DI_TOKENS.IMockDependency.Value)
      .toInstance(MockDependency)
      .inTransientScope();
    di.bind(DI_TOKENS.IStorageContext.Value)
      .toInstance(BrowserStaticStorageContext)
      .inSingletonScope();
    di.bind(DI_TOKENS.IProductionLineService.Value)
      .toInstance(ProductionLineService)
      .inSingletonScope();
    di.bind(DI_TOKENS.IPriceCalculationService.Value)
      .toInstance(PriceCalculationService)
      .inSingletonScope();

    //PRODUCT
    di.bind(DI_TOKENS.IProductService.Value)
      .toInstance(ProductService)
      .inSingletonScope();
    di.bind(DI_TOKENS.IProductRepository.Value).toConstant(productProvider);

    //ORDER
    di.bind(DI_TOKENS.IOrderService.Value)
      .toInstance(OrderService)
      .inSingletonScope();

    //MODEL
    di.bind(DI_TOKENS.IGeometryModelService.Value)
      .toInstance(GeometryModelService)
      .inSingletonScope();
    di.bind(DI_TOKENS.IGeometryModelRepository.Value).toConstant(
      geometryModelProvider
    );

    di.bind(DI_TOKENS.ICheckoutService.Value)
      .toInstance(CheckoutService)
      .inSingletonScope();
    di.bind(DI_TOKENS.ISendOrderService.Value)
      .toInstance(EmailOrderService)
      .inSingletonScope();
    di.bind(DI_TOKENS.IConfigurationService.Value)
      .toInstance(ConfigurationService)
      .inSingletonScope();
    di.bind(DI_TOKENS.IProjectRepository.Value).toConstant(projectProvider);
    di.bind(DI_TOKENS.IUserService.Value).toConstant(userService);
    di.bind(DI_TOKENS.IRepository_IWorkstationType.Value).toConstant(
      equipmentDefinitionProvider
    );
    di.bind(DI_TOKENS.IOrderRepository.Value).toConstant(orderProvider);

    //CONFIGURATION TEMPLATE
    di.bind(DI_TOKENS.IConfigurationTemplateService.Value)
      .toInstance(ConfigurationTemplateService)
      .inSingletonScope();

    di.bind(DI_TOKENS.IConfigurationTemplateRepository.Value).toConstant(
      configurationTemplateProvider
    );

    return di;
  };

  const diContainer = buildDiContainer();

  return (
    <ContainerProvider container={diContainer}>{children}</ContainerProvider>
  );
}

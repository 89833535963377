import "./ReviewOrder.scss";
import { useWorkPanelContext } from "../context";
import { OrderSummary } from "./OrderSummary";

export function ReviewOrder() {
  const context = useWorkPanelContext();
  const {customerDetails} = context;

  if (!customerDetails){
    return <>PLEASE FILL CUSTOMER DETAILS</>
  }

  const { firstName, lastName, email, company } = customerDetails;

  return (
    <div className="widget-inside">
      <div className="new-review-cart">
        <h5 className="title">Your Order</h5>
        <div className="cart-contents">
          <div className="contact-details">
            <ul>
              <li>
                <p>Customer Name</p>
                <p>{`${firstName} ${lastName}`}</p>
              </li>
              <li>
                <p>Company</p>
                <p>{company || "Not available"}</p>
              </li>
              <li>
                <p>Email</p>
                <p>{email}</p>
              </li>
            </ul>
          </div>
          <OrderSummary />
        </div>
      </div>
    </div>
  );
}

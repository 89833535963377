import { IFieldState } from "./IFieldState";
import { RenderableField } from "./RenderableField";

export class FieldBuilder<TRecord> {
  createField<K extends keyof TRecord>(
    key: K,
    renderCell: (params: IFieldState<TRecord[K]>) => React.ReactNode
  ) {
    return new RenderableField(key, renderCell);
  }
}

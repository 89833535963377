
export interface IUserService {
    getCurrentUserId(): string;
}

export class UserService implements IUserService {
    userId: string;

    constructor(userId: string){
        this.userId = userId;
    }

    getCurrentUserId(): string {
        return this.userId;
    }

}

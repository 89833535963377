import { Box } from "@mui/material";
import { OrbitControls, Stage } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { Object3D } from "three";
import { GltfModel } from "./GltfModel";

export function ModelViewer(props: {
  gltfUrl?: string;
  object3d?: Object3D | undefined;
}) {
  const { gltfUrl, object3d } = props;

  return (
    <Box sx={{ height: "max(20vw, 200px)" }} component="div">
      <Canvas>
        <Stage environment={null}>
          {object3d ? (
            <primitive object={object3d} />
          ) : gltfUrl ? (
            <GltfModel gltfUrl={gltfUrl} />
          ) : null}

          <OrbitControls
            makeDefault
            minPolarAngle={0}
            maxPolarAngle={Math.PI / 2} />
        </Stage>
      </Canvas>
    </Box>
  );
}

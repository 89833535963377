import { injected } from "brandi";
import { IConfiguration } from "../models/IProject";
import DI_TOKENS from "../../DI_TOKENS";
import { configurationMapper } from "application/mappers/configurationMapper";
import { IConfigurationState } from "../models/IConfigurationState";
import { generateUUID } from "three/src/math/MathUtils";
import { IProjectRepository } from "infrastructure/resource-providers/contracts/project";

export interface IConfigurationService {
  getConfigurations(userId: string): Promise<IConfiguration[]>;

  get(id: string, userId: string): Promise<IConfiguration | undefined>;

  requestConfigurationUpdate(
    state: IConfigurationState,
    userId: string
  ): Promise<IConfiguration>;

  requestConfigurationCreate(
    state: IConfigurationState,
    userId: string
  ): Promise<IConfiguration>;

  requestConfigurationDelete(id: string, userId: string): Promise<void>;
}

export class ConfigurationService implements IConfigurationService {
  public constructor(projectRepository: IProjectRepository) {
    this._projectRepository = projectRepository;
  }
  async get(id: string, userId: string): Promise<IConfiguration | undefined> {
    const result = await this._projectRepository.get(id, userId);
    return result;
  }
  _projectRepository: IProjectRepository;

  async requestConfigurationDelete(id: string, userId: string): Promise<void> {
    await this._projectRepository.delete(id, userId);
  }

  async requestConfigurationUpdate(
    state: IConfigurationState & { id: string },
    userId: string
  ): Promise<IConfiguration> {
    const result = await this.createUpdateProject(state, userId, false);
    return result;
  }

  async requestConfigurationCreate(
    state: IConfigurationState,
    userId: string
  ): Promise<IConfiguration> {
    const result = await this.createUpdateProject(state, userId, true);
    return result;
  }

  async getConfigurations(userId: string): Promise<IConfiguration[]> {
    const fetched =
      (await this._projectRepository.getList(userId))?.items ?? [];
    return fetched.map((x) => {
      if (x.createdTS) {
        x.createdTS = new Date(x.createdTS);
      }

      if (x.updatedTS) {
        x.updatedTS = new Date(x.updatedTS);
      }

      return x;
    });
  }

  private async createUpdateProject(
    state: IConfigurationState & {
      id?: string;
    },
    userId: string,
    isCopy: boolean = false
  ) {
    const mapped = configurationMapper.mapStateToModel(state, isCopy);
    let { id } = state;

    if (isCopy) {
      id = id ?? generateUUID();
      await this._projectRepository.create({ ...mapped }, userId);
    } else {
      if (id == null) {
        throw new Error("cannot UPDATE a configuration without ID!");
      }
      await this._projectRepository.update({ ...mapped }, userId);
    }

    return { ...mapped, id };
  }
}

injected(ConfigurationService, DI_TOKENS.IProjectRepository.Value);

import { RenderableColumnBase } from "./RenderableColumnBase";
import { RenderCellParams } from "./RenderCellParams";

export class RenderableColumn<
  TRecord,
  K extends keyof TRecord
> extends RenderableColumnBase<TRecord> {
  key: K;
  renderCell: (
    params: RenderCellParams<TRecord[keyof TRecord]>
  ) => React.ReactNode;

  constructor(
    key: K,
    renderCell: (params: RenderCellParams<TRecord[K]>) => React.ReactNode
  ) {
    super();
    this.renderCell = (x: any) => renderCell(x);
    this.key = key;
  }
}

import { IProduct } from "../../application/models/IProduct";
import { IRepository } from "../../application/repositories/IRepository";
import { ProductApi } from "../api-client";
import { createApiProvider } from "./createApiProvider";
import { fromResponse } from "./helpers/fromResponse";

export function buildProductProvider(api: ProductApi): IRepository<IProduct> {

  const apiProvider = createApiProvider({
    getList: async (userId, request) => await fromResponse(api.productGetListPost(request)),
    get: async (id,userId) => await fromResponse(api.productGet(id)),
    //create: async () => await fromResponse(api.productListPut()),
    updateList: async (items: IProduct[]) => await fromResponse(api.productListPut({
        items
    })),
    delete: async (id, userId) => await fromResponse(api.productDelete(id)),
  });

  return apiProvider;
}

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  WelcomeScreenContextProvider,
  useWelcomeScreenContext,
} from "./context";
import Header from "./components/Header";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { ROUTES } from "ui/app-routes/routeConfig";
import { useNavigate } from "react-router-dom";
import { DivBox } from "ui/library/Layout/DivBox";

function Layout() {
  const navigate = useNavigate();
  const context = useWelcomeScreenContext();

  const { recentSales } = context;

  const handleContinue = (id: string) => {
    context.continueOrder(id).then(() => {
      navigate(ROUTES.SALES_PROCESS.CUSTOMER_DETAILS.path);
    });
  };

  const isNoSales = recentSales.length <= 0;

  return (
    <div className="container-new">
      <Header />
      <div className="table-container">
        <div className="header">
          <h5>Recent Projects</h5>
        </div>
        <div className="body">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Customer Name</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Current Status</TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isNoSales &&
                recentSales.map((x) => (
                  <RecentSalesRow
                    customerName={`${x.firstName} ${x.lastName}`}
                    date={new Date()}
                    handleContinue={() => {
                      handleContinue(x.id);
                    }}
                  />
                ))}
            </TableBody>
          </Table>
          {isNoSales && <NoSales />}
        </div>
      </div>
    </div>
  );
}

export default function WelcomeScreen() {
  return (
    <WelcomeScreenContextProvider>
      <Layout />
    </WelcomeScreenContextProvider>
  );
}

function NoSales() {
  return (
    <DivBox
      display={"flex"}
      alignItems={"center"}
      justifyItems={"center"}
      justifyContent={"center"}
      minHeight={"40vh"}
    >
      <Typography m={"30px"} width={"auto"}>
        No recent sales to display. Please start a new sales process
      </Typography>
    </DivBox>
  );
}

function RecentSalesRow({
  customerName,
  date,
  handleContinue,
}: {
  customerName: string;
  date: Date;
  handleContinue: () => void;
}) {
  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell component="th" scope="row">
        {customerName}
      </TableCell>
      <TableCell>{date.toLocaleDateString()}</TableCell>
      <TableCell>
        <p className="status success">In Progress</p>
      </TableCell>
      <TableCell>
        <Button
          endIcon={<ArrowForward fontSize="small" />}
          className="continue-btn"
          onClick={handleContinue}
        >
          Continue
        </Button>
      </TableCell>
    </TableRow>
  );
}

import { useFormik } from "formik";
import { Button, Grid, SxProps, Theme } from "@mui/material";
import { OptionalRecord } from "../../../common/types/OptionalRecord";
import { GridBox } from "../Layout/LayoutBox";
import { ReactNode, useEffect } from "react";
import { ExtractKeys } from "../../../common/types/ExtractKeys";
import { Fields } from "./Fields";
import { StandardFormValidationSchema } from "./StandardFormValidationSchema";
import { FieldMeta } from "./fieldMeta/fieldMeta";

export interface StandardFormProps<
  TValues extends OptionalRecord<ExtractKeys<TValues>, any> & {
    id?: string;
  }
> {
  /**fields that will be displayed and validated by the form. might be simple keys or containers that nest other fields. all nested levels will be validated */
  fields: FieldMeta<TValues>[];
  /**these values will populate the form fields initially, if defined */
  initialValues: Partial<TValues>;
  /**yup schema used to define the validation rules */
  validationSchema: StandardFormValidationSchema<TValues>;
  onSubmit: (values: TValues) => Promise<void>;
  /**styling for the entire form */
  sx?: SxProps<Theme> | undefined;
  /** styling for each field */
  fieldContainerSx?: SxProps<Theme> | undefined;
  /** styling for the submit container below the form */
  submitContainerSx?: SxProps<Theme> | undefined;
  /** button override - no click handler needed. Use ref if you want to use an external button */
  submitButton?: ReactNode | boolean;
  /** render function, enables custom handling and nesting of the formBody. uses 'children as functions' approach. Note: the formik context is available here also */
  children?: (formBody: ReactNode, api: FormApi) => JSX.Element;
}

export interface FormApi {
  submit: () => void;
}

/**
 * `StandardForm` provides a structured way to build forms with various fields and layouts.
 * It leverages Formik for form state management and uses Yup for validation.
 *
 * @template TValues The shape of your form state. Ensure this extends both `OptionalRecord<ExtractKeys<TValues>, any>` and `{ id?: string }`.
 *
 * @param {StandardFormProps<TValues>} props
 * @param {FieldMeta<TValues>[]} props.fields - Definitions of fields that will be displayed and validated. Relate to keys of the fields in the TValues form state/data model.
 * May be a predefined field (text field, number field) or a custom renderer
 * @param {Partial<TValues>} props.initialValues - Initial values to populate the form fields.
 * @param {StandardFormValidationSchema<TValues>} props.validationSchema - Yup schema used for validation rules - see [Yup GitHub](https://github.com/jquense/yup).
 * @param {(values: TValues) => Promise<void>} props.onSubmit - Callback executed upon form submission.
 * @param {SxProps<Theme> | undefined} [props.sx] - Styling for the entire form.
 * @param {SxProps<Theme> | undefined} [props.fieldContainerSx] - Styling for each individual field.
 * @param {SxProps<Theme> | undefined} [props.submitContainerSx] - Styling for the submit button's container.
 *
 * @returns {JSX.Element} Form element containing the defined fields and a submit button.
 *
 * @example
 * ```tsx
 * type SimpleFormModel = {
 *   firstName: string;
 *   lastName: string;
 *   email: string;
 * };
 *
 * const simpleYupSchema: yup.SchemaOf<SimpleFormModel> = yup.object().shape({
 *   email: yup.string().email("Enter a valid email").required("Email is required"),
 *   firstName: yup.string().required("First Name is required"),
 *   lastName: yup.string().required("Last Name is required")
 * });
 *
 * <StandardForm
 *   fields={[
 *     { key: 'firstName', field: 'text-input' },
 *     { key: 'lastName', field: 'text-input' },
 *     { key: 'email', field: 'text-input' }
 *   ]}
 *   initialValues={{ firstName: '', lastName: '', email: '' }}
 *   validationSchema={simpleYupSchema}
 *   onSubmit={(values) => { console.log(values); }}
 * />
 * ```
 *
 * @remarks
 * For understanding and creating validation schemas, refer to the Yup documentation: [Yup GitHub](https://github.com/jquense/yup)
 */
export function StandardForm<
  TValues extends OptionalRecord<ExtractKeys<TValues>, any> & {
    id?: string;
  }
>({
  initialValues,
  onSubmit,
  fields,
  validationSchema,
  sx,
  fieldContainerSx,
  submitContainerSx,
  submitButton,
  children,
}: StandardFormProps<TValues>) {
  const formik = useFormik<Partial<TValues>>({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (v) => onSubmit(v as TValues),
  });

  useEffect(() => {
    console.log(formik.errors);
  }, [formik.errors]);

  //default submit button
  if (submitButton == null) {
    submitButton = (
      <Button
        className="standardForm-SubmitButton"
        color="primary"
        variant="contained"
        fullWidth
        type="submit"
      >
        Submit
      </Button>
    );
  }

  if (typeof submitButton === "boolean") {
    submitButton = undefined;
  }

  const formBody = (
    <form onSubmit={formik.handleSubmit}>
      <GridBox sx={sx} className="standardForm-Container">
        <Fields
          fieldMetas={fields}
          fieldContainerSx={fieldContainerSx}
          formik={formik}
        />
        <Grid
          sx={submitContainerSx}
          className="standardForm-SubmitContainer"
          item
        >
          {submitButton != null ? submitButton : <></>}
        </Grid>
      </GridBox>
    </form>
  );

  const rendered = children
    ? children(formBody, {
        submit: () => {
          formik.handleSubmit();
          console.log(formik.errors);
          console.log(formik.touched);
        },
      })
    : formBody;

  return rendered;
}



export function dateFieldsToString<T>(
    item: T & {
      updatedTS?: Date | string | undefined;
      createdTS?: Date | string | undefined;
    }
  ) {
    let updatedString: string | undefined;
    let createdString: string | undefined;
  
    if (item.updatedTS) {
      updatedString = new Date(item.updatedTS).toISOString();
    }
  
    if (item.createdTS) {
      createdString = new Date(item.createdTS).toISOString();
    }
  
    return { ...item, updatedTS: updatedString, createdTS: createdString };
  }
  
  export function dateFieldsToDate<T>(
    item: T & {
      updatedTS?: Date | string | undefined;
      createdTS?: Date | string | undefined;
    }
  ) {
    return {
      ...item,
      createdTS: new Date(item.createdTS ?? new Date()),
      updatedTS: new Date(item.updatedTS ?? new Date()),
    };
  }
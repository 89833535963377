import { injected } from "brandi";
import { generateUUID } from "three/src/math/MathUtils";
import DI_TOKENS from "../../DI_TOKENS";
import { IPartInstance } from "../models/IPartInstance";
import { IWorkstationInstance } from "../models/IWorkstationInstance";
import { IWorkstationType } from "../models/IWorkstationType";
import { IWorkstationTypeService } from "./WorkstationTypeService";

export interface IWorkstationInstanceService {

    build(x: number, y: number, z: number, previousInstanceId: string | undefined, workstationTypeId: string, rotationRad?: number): Promise<IWorkstationInstance>

    changeStationType(workstationInstance: IWorkstationInstance, workstationTypeId: string): Promise<IWorkstationInstance>

    reposition(workstationInstance: IWorkstationInstance, x: number, z:number): IWorkstationInstance

}

export class WorkstationInstanceService implements IWorkstationInstanceService {
    private _workstationTypeService: IWorkstationTypeService;

    constructor(workstationTypeService: IWorkstationTypeService) {

        this._workstationTypeService = workstationTypeService;

    }
    
    reposition(workstationInstance: IWorkstationInstance, x: number, z: number): IWorkstationInstance {
        workstationInstance.insertPointX = x;
        workstationInstance.insertPointZ = z;

        return workstationInstance;
    }

    async changeStationType(workstationInstance: IWorkstationInstance, workstationTypeId: string): Promise<IWorkstationInstance> {

        const prevTypeId = workstationInstance.workstationTypeId;

        if (prevTypeId != workstationTypeId) {

            const workstationTypes = await this._workstationTypeService.GetAll();

            const workstationType = workstationTypes.find(x => x.id === workstationTypeId);

            if (workstationType){
                workstationInstance.workstationTypeId = workstationTypeId;
                workstationInstance.assembly = this.buildDefaultAssemblyFromType(workstationType);
            }
               
        }

        return workstationInstance;

    }

    async build(x: number, y: number, z: number, previousInstanceId: string | undefined, workstationTypeId: string, rotationRad?: number): Promise<IWorkstationInstance> {

        const workstationTypes = await this._workstationTypeService.GetAll();

        const wsType = workstationTypes.find(t => t.id === workstationTypeId);

        if (wsType == null){
            throw new Error(`requested equipment definition ${workstationTypeId} does not exist`)
        }

        const instance: IWorkstationInstance = {

            assembly: [],
            id: generateUUID(),
            insertPointX: x,
            insertPointY: y,
            insertPointZ: z,
            previousInstanceId: previousInstanceId,
            workstationTypeId: wsType.id,
            rotationRad: rotationRad ?? 0,

        }

        const assembly = this.buildDefaultAssemblyFromType(wsType);

        instance.assembly = assembly;

        return instance;
    }

    private buildDefaultAssemblyFromType(wsType: IWorkstationType): IPartInstance[] {
        return wsType.components.filter(x => x.isConfigurable && (x.nodeId != null)).map(c => {

            const item: IPartInstance = {
                id: generateUUID(),
                definitionId: c.id,
                isIncluded: true,
                name: c.name,
                nodeName: c.nodeId!
            };

            return item;
        });
    }
}

injected(WorkstationInstanceService, DI_TOKENS.IWorkstationTypeService.Value)
import { isEqual } from "lodash";
import { IFieldState } from "./IFieldState";

//TODO: get rid of 'any'

export function buildFieldState<TValues extends Record<string, any>>(
  fieldName: string,
  formState: {
    values: Record<string, any>;
    errors: Record<string, any>;
    touched: Record<string, any>;
    handleChange: (x: TValues) => any;
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean
    ) => void;
  }
): IFieldState<TValues[keyof TValues]> {
  return {
    value: formState?.values[fieldName],
    error: formState?.errors[fieldName] as string | undefined,
    touched: formState?.touched[fieldName] as boolean | undefined,
    handleChange: (val: TValues[keyof TValues]) => {
      if (!isEqual(formState.values[fieldName], val)) {
        formState.setFieldValue(fieldName, val);
      }
    },
  };
}

import { injected } from "brandi";
import DI_TOKENS from "../../DI_TOKENS";
import { IStorageContext } from "../../infrastructure/storage/mockSeedStorageContext";
import { IProduct } from "../models/IProduct";
import { IProductRepository } from "../repositories/generic";
import { IUserService } from "./UserService";
import { IRepository } from "../repositories/IRepository";
import { TableRequest } from "../../infrastructure/paging/paging";
import { IFilteredListResult } from "../../ui/app/store/pagination/pagination";

export interface IProductService {
  getAll(): Promise<IProduct[]>;

  getList(
    request: TableRequest<IProduct>
  ): Promise<IFilteredListResult<IProduct>>;

  get(id: string): Promise<IProduct | null>;

  updateList(products: IProduct[]): Promise<void>;

  delete(id: string): Promise<void>;
}

export class ProductService implements IProductService {
  private _storageContext: IStorageContext;
  private _repository: IRepository<IProduct>;
  private _userId: string;

  constructor(
    storageContext: IStorageContext,
    repository: IProductRepository,
    user: IUserService
  ) {
    this._storageContext = storageContext;
    this._repository = repository;
    this._userId = user.getCurrentUserId();
  }

  async getList(
    request: TableRequest<IProduct>
  ): Promise<IFilteredListResult<IProduct>> {
    const result = await this._repository.getList(this._userId, request);
    return result;
  }

  async delete(id: string): Promise<void> {
    this._repository.delete(id, this._userId);
  }
  async updateList(products: IProduct[]): Promise<void> {
    await this._repository.updateList(products, this._userId);
  }

  async getAll() {
    let result = (await this._repository.getList(this._userId))?.items ?? [];
    if (this._userId != null && (!result || result.length <= 0)) {
      let mocks = await this._storageContext.getProducts();
      await this._repository.updateList(mocks, this._userId);
      result = mocks;
    }

    return result;
  }

  async get(id: string): Promise<IProduct | null> {
    const result = await this._repository.get(id, this._userId);

    return result ?? null;
  }
}

injected(
  ProductService,
  DI_TOKENS.IStorageContext.Value,
  DI_TOKENS.IProductRepository.Value,
  DI_TOKENS.IUserService.Value
);

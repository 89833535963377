import {
  Box,
  Button,
  SxProps
} from "@mui/material";

export function ModalOptionButtons({
  handleSubmitClick, handleCancelClick, submitButtonText = "Ok", cancelButtonText = "Cancel",
}: {
  handleSubmitClick: () => void;
  handleCancelClick: () => void;
  submitButtonText?: string;
  cancelButtonText?: string;
}) {
  return (
    <Box component="div" display="flex" justifyContent="space-between">
      <Button
        type="reset"
        fullWidth
        variant="contained"
        color="primary"
        sx={cancelButtonStyle}
      >
        {cancelButtonText}
      </Button>
      <Button
        
        fullWidth
        variant="contained"
        color="primary"
        sx={submitButtonStyle}
        onClick={handleSubmitClick}
      >
        {submitButtonText}
      </Button>
    </Box>
  );
}

export const cancelButtonStyle: SxProps = {
  width: "100%",
  padding: "0.625rem 1.125rem",
  borderRadius: "0.5rem",
  border: "1px solid #EAECF0",
  background: "#FFF",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  fontSize: "1rem",
  fontWeight: 600,
  color: "#4B5966",
  textTransform: "capitalize",
  marginTop: 0,
  flex: 1,
  marginRight: "0.37rem",
  ":hover": {
    color: "#ffffff",
  },
};

export const submitButtonStyle: SxProps = {
  width: "100%",
  padding: "0.625rem 1.125rem",
  borderRadius: "0.5rem",
  border: "1px solid #0074a7",
  background: "#0074a7",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  fontSize: "1rem",
  fontWeight: 600,
  color: "#ffffff",
  textTransform: "capitalize",
  marginTop: 0,
  flex: 1,
  marginLeft: "0.37rem",
};
import { createContext, useContext } from "react";
import nameof from "ts-nameof.macro";
import { IWorkstationType } from "../../../../application/models/IWorkstationType";
import { IProduct } from "../../../../application/models/IProduct";
import { AppContext, useAppContext } from "../../../app/context";
import { Model } from "@jmk/ar-toolbox-core/core";
import { EditorMode } from "../../../../common/enums/EditorMode";
import { FormModel } from "./models/FormModel";

export interface IEquipmentDefintionTableContext {
  getDefinition: (id: string) => Promise<IWorkstationType | undefined>;
  updateDefiniton: (definition: IWorkstationType) => Promise<boolean>;
  createDefiniton: (
    definition: IWorkstationType
  ) => Promise<IWorkstationType | undefined>;
  deleteDefinition: (id: string) => Promise<void>,
  equipmentDefintions: IWorkstationType[];
  getModelUri: (modelId: string) => string | undefined;
  products: IProduct[];
  uploadToBucket: (
    modelData: Model,
    fileName: string
  ) => Promise<{ resourceUri: string; modelId: string }>;
  handleEquipmentDefinitionSubmit: (
    id: string,
    definition: FormModel,
    editorMode: EditorMode
  ) => Promise<void>;
}

export const EquipmentDefintionTableContext = createContext<
  IEquipmentDefintionTableContext | undefined
>(undefined);

export function useEquipmentDefinitionTableContext() {
  const name = nameof(EquipmentDefintionTableContext);
  const ctx = useContext(EquipmentDefintionTableContext);

  if (!ctx) {
    throw new Error(`${name} must be requested from its ${name}Provider!!!`);
  }

  return ctx;
}

function map(id: string, input: FormModel): IWorkstationType {
  const output: IWorkstationType = {
    ...input,
    id,
    components: input.components,
  };

  return output;
}

export function EquipmentDefinitionTableContextProvider(props: {
  children: JSX.Element | JSX.Element[];
}) {
  const { children } = props;

  const parentContext = useAppContext();
  const { product: products, equipmentDefinition } = parentContext;

  const { getModelUrl, uploadToBucket, fetchGeometryModels } = parentContext.geometryModel.dispatch;

  const { createDefiniton, updateDefiniton } =
    parentContext.equipmentDefinition.dispatch;
  const { workstationTypes } = parentContext.equipmentDefinition.state;
  const { deleteDefiniton: deleteDefinition } = equipmentDefinition.dispatch;

  const ctxImplementation: IEquipmentDefintionTableContext = {
    updateDefiniton,
    createDefiniton,
    deleteDefinition,
    getModelUri: getModelUrl,
    getDefinition: (id) =>
      Promise.resolve(workstationTypes.find((x) => x.id == id)),
    products: products.state.items,
    uploadToBucket,
    handleEquipmentDefinitionSubmit: async (id, data, mode) => {
      if (mode == EditorMode.Update) await updateDefiniton(map(id, data));
      else await createDefiniton(map(id, data));
      fetchGeometryModels();
    },
    equipmentDefintions: workstationTypes,
  };

  return (
    <EquipmentDefintionTableContext.Provider value={ctxImplementation}>
      {children}
    </EquipmentDefintionTableContext.Provider>
  );
}

import { Link, useNavigate } from "react-router-dom";
import BG from "../../../assets/login_bg.png";
import LOGO from "../../../assets/logo.png";
import "./Login.scss";
import { Grid, Input } from "@mui/material";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { ROUTES } from "../../app-routes/routeConfig";

const Login = () => {
  const navigate = useNavigate();

  return (
    <div className="authentication">
      <Grid container spacing={2} alignItems="center">
        <Grid item md={6} sx={{ display: { xs: "none", md: "block" } }}>
          <img src={BG} alt="" className="bgImg" />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <div className="login_selector_main_wrapper">
            <img src={LOGO} alt="" className="img-fluid" />
            <h3>Log in to your account</h3>
            <p className="para_color">
              Welcome back! Please enter your details.
            </p>
            <div className="form">
              <Input
                type="text"
                placeholder="Enter your email"
                className="input"
              />
              <button
                type="button"
                onClick={() => navigate(ROUTES.LOGIN_PASSWORD.path)}
              >
                Continue with email
              </button>
            </div>
            <p className="para_color">
              Don't have an account? <Link to="/">Contact Admin</Link>
            </p>
            <Link to="/" className="ssoLogin">
              {`Login with SSO`}
              <ArrowForward fontSize="small" />
            </Link>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Login;

import { IGeometryModel } from "../../application/models/IGeometryModel";
import { IGeometryModelRepository } from "../../application/repositories/IGeometryModelRepository";
import { GeometryModelApi } from "../api-client";
import { createApiProvider } from "./createApiProvider";
import { fromResponse, getPageFromResponse } from "./helpers/fromResponse";

export function buildGeometryModelProvider(
  api: GeometryModelApi
): IGeometryModelRepository {
  const apiProvider = createApiProvider<IGeometryModel>({
    getList: async (userId, request) => await getPageFromResponse(api.geometryModelListGet()),
  });

  return {
    ...apiProvider,
    getPresignedUploadUrl: async (x) => {
      const result = await fromResponse(api.geometryModelGetUploadUrlPost(x));
      return { ...result, resourceUri: result.resourceUri! };
    },
    updateList: async (items: (IGeometryModel & { resourceKey: string })[]) =>
      await fromResponse(
        api.geometryModelListPut({
          items: items.map((x) => {
            return {
              ...x,
            };
          }),
        })
      ),
  };
}

import { Button as MuiButton, SxProps } from "@mui/material";
import { MouseEventHandler } from "react";

export function Button(props: {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  children: React.ReactNode;
  sx?: SxProps;
  className?: string;
}) {
  const { children } = props;
  return (
    <MuiButton
      variant="contained"
      onClick={props.onClick}
      sx={props.sx}
      className={props.className}
    >
      {children}
    </MuiButton>
  );
}

import { RenderableFieldMeta } from "./fieldMeta/RenderableFieldMeta";
import { SingleFieldMeta } from "./SingleFieldMeta";
import { FieldMeta } from "./fieldMeta/fieldMeta";

//TODO this is overcomplicated

export function metaHasKey<TValues>(
  field: FieldMeta<TValues>
): field is SingleFieldMeta<TValues> | RenderableFieldMeta<TValues> {
  return (field as SingleFieldMeta<TValues>).key != null;
}

import { Autocomplete, TextField } from "@mui/material";

export function AutocompleteCell(props: {
  value: string;
  options: {
    key: string;
    label: string;
  }[];
  onChange?: (value: string) => void;
}) {
  const { onChange, options } = props;

  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={options}
      sx={{ width: 300 }}
      renderInput={(params) => (
        <TextField {...params} label="Search products" />
      )}
      onChange={(e, values) => {
        onChange && onChange(values?.key || "");
      }}
    />
  );
}

import { StandardForm } from "../../../library/Form";
import { FieldMeta } from "../../../library/Form/fieldMeta/fieldMeta";
import { ConfigurationTemplateDetails } from "../../../../application/models/configurationTemplateDetails";
import "./ReviewTemplate.scss";
import * as yup from "yup";
import { Box, Button, FormLabel, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import { useEffect, useState } from "react";
import TemplateSuccessModal from "./TemplateSuccessModal";
import UploadIcon from "../../../../assets/upload-cloud.svg";
import { FieldBuilder } from "ui/library/Form/fieldMeta/FieldBuilder";
import { useWorkPanelContext } from "../context";
import { useVariantFromRoute } from "ui/shared/hooks/useVariantFromRoute";
import { ROUTES } from "ui/app-routes/routeConfig";

const validationSchema: yup.SchemaOf<ConfigurationTemplateDetails> = yup
  .object()
  .shape({
    name: yup.string().required("Template name is required"),
    description: yup.string().required("Description is required"),
    type: yup.string().required("Type is required"),
    imgUrl: yup.string(),
  });

const ReviewTemplate = () => {
  const variant = useVariantFromRoute<"edit" | "create">([
    [ROUTES.CATALOGUE.CONFIGURATION_TEMPLATE.DESIGN.CREATE, "create"],
    [ROUTES.CATALOGUE.CONFIGURATION_TEMPLATE.DESIGN.EDIT, "edit"], //confusion between sales and projects!!!!
  ]);

  const context = useWorkPanelContext();
  const { setScreen, createTemplate, updateTemplate, currentTemplate } =
    context;
  const [modal, setModal] = useState<boolean>(false);
  const [templateName, setTemplateName] = useState<string>();
  const [thumbnail, setThumbnail] = useState<File | undefined>(undefined);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    !context.currentTemplate && setModal(true);
  }, [context.currentTemplate]);

  const chooseThumbnail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.[0];
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

    if (file && allowedExtensions.test(file.name)) {
      setError("");
      setThumbnail(file);
    } else {
      setThumbnail(undefined);
      setError("Invalid file extension. Please select a valid image file.");
    }
  };

  const onSubmit = async (values: ConfigurationTemplateDetails) => {
    if (!error) {
      if (thumbnail) {
        values.imgUrl = URL.createObjectURL(thumbnail);
      }

      setTemplateName(values?.name);

      switch (variant) {
        case "create":
          createTemplate(values);
          break;
        case "edit":
          updateTemplate(values);
          break;
      }
    }
  };

  const fieldBuilder = new FieldBuilder<ConfigurationTemplateDetails>();

  const fields: FieldMeta<ConfigurationTemplateDetails>[] = [
    {
      key: "name",
      field: "text-input",
      label: "Template Name",
    },
    {
      key: "description",
      field: "text-input",
      label: "Description",
    },
    {
      key: "type",
      field: "text-input",
      label: "Type",
    },
    fieldBuilder.createField("imgUrl", () => {
      return (
        <Box component="div" sx={{ marginBottom: 2 }}>
          <FormLabel>
            <input
              type="file"
              style={{ display: "none" }}
              onChange={chooseThumbnail}
            />
            <Box
              component="div"
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                component="div"
                sx={{
                  borderRadius: "0.5rem",
                  border: "1px solid #EAECF0",
                  padding: "0.625rem 0.625rem 0.3rem",
                  marginRight: 2,
                }}
              >
                <img src={UploadIcon} alt="" />
              </Box>
              <Typography component="p">
                Upload{" "}
                <span
                  style={{
                    color: "#0074A7",
                    fontSize: "0.875rem",
                    fontWeight: "600",
                  }}
                >
                  Thumbnail Image
                </span>
              </Typography>
            </Box>
            {error && (
              <Typography component="p" color="error">
                {error}
              </Typography>
            )}
            {thumbnail && (
              <Box component="div" sx={{ marginTop: 1 }}>
                <img
                  src={URL.createObjectURL(thumbnail)}
                  alt="Thumbnail Preview"
                  style={{
                    width: "100%",
                    height: "auto",
                    borderRadius: "0.5rem",
                  }}
                />
              </Box>
            )}
          </FormLabel>
        </Box>
      );
    }),
  ];

  const initialValues: ConfigurationTemplateDetails = currentTemplate ?? {
    name: "",
    description: "",
    type: "",
    imgUrl: "",
  };

  return (
    <>
      <div className="review-template">
        <h5 className="title">Template Details</h5>
        <div className="cart-contents">
          <StandardForm<ConfigurationTemplateDetails>
            fields={fields}
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            submitButton={
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{
                  padding: "0.625rem 1.125rem",
                  borderRadius: "0.5rem",
                  border: "1px solid #0074a7",
                  background: "#0074a7",
                  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                  fontSize: "1rem",
                  fontWeight: 600,
                  color: "#ffffff",
                  textTransform: "capitalize",
                  marginTop: 0,
                  flex: 1,
                }}
              >
                Save Template
              </Button>
            }
          />
          <div className="review-footer">
            <div className="go-back">
              <Link to="#" onClick={() => setScreen("equipmentInstanceList")}>
                <ChevronLeft color="info" /> Go Back
              </Link>
            </div>
          </div>
        </div>
      </div>
      <TemplateSuccessModal
        templateName={templateName ?? ""}
        open={modal}
        setOpen={setModal}
      />
    </>
  );
};

export default ReviewTemplate;

import { IConfigurationTemplate } from "application/models/IConfigurationTemplate";
import { CrudService, ICrudService } from "./CrudService";
import { IUserService } from "./UserService";
import { injected } from "brandi";
import DI_TOKENS from "DI_TOKENS";
import { IConfigurationTemplateRepository } from "infrastructure/resource-providers/contracts/configurationTemplate";
import { omit } from "../../common/typeHelpers/omit";
import { Modify } from "common/types/Modify";
import { IConfiguration } from "application/models/IProject";
import { TableRequest } from "infrastructure/paging/paging";
import { IFilteredListResult } from "ui/app/store/pagination/pagination";

export interface IConfigurationTemplateService
  extends Modify<
    ICrudService<IConfigurationTemplate>,
    {
      create: (
        current: IConfigurationTemplate & { configuration: IConfiguration }
      ) => Promise<IConfigurationTemplate | undefined>;

      update: (
        current: IConfigurationTemplate & { configuration: IConfiguration }
      ) => Promise<IConfigurationTemplate | undefined>;
    }
  > {}

export class ConfigurationTemplateService
  implements IConfigurationTemplateService
{
  repository: IConfigurationTemplateRepository;
  base: CrudService<IConfigurationTemplate>;
  public constructor(
    repository: IConfigurationTemplateRepository,
    userService: IUserService
  ) {
    const baseRepo = omit(repository, "update", "create");
    this.base = new CrudService(baseRepo, userService);
    this.repository = repository;
  }
  updateList(current: IConfigurationTemplate[]): Promise<void> {
    return this.base.updateList(current);
  }
  getList(
    request?: TableRequest<IConfigurationTemplate> | undefined
  ): Promise<IFilteredListResult<IConfigurationTemplate>> {
    return this.base.getList(request);
  }
  delete(id: string): Promise<void> {
    return this.base.delete(id);
  }
  get(id: string): Promise<IConfigurationTemplate | undefined> {
    return this.base.get(id);
  }

  create(item: IConfigurationTemplate & { configuration: IConfiguration }) {
    return this.repository.create(item, this.base._userId);
  }

  update(
    item: IConfigurationTemplate & { configuration: IConfiguration },
  ) {
    return this.repository.update(item, this.base._userId);
  }
}

injected(
  ConfigurationTemplateService,
  DI_TOKENS.IConfigurationTemplateRepository.Value,
  DI_TOKENS.IUserService.Value
);

import { RenderCellParams } from "./RenderCellParams";
import { RenderableColumn } from "./RenderableColumn";


export class ColumnBuilder<TRecord> {
  createColumn<K extends keyof TRecord>(
    key: K,
    renderCell: (params: RenderCellParams<TRecord[K]>) => React.ReactNode
  ) {
    return new RenderableColumn(key, renderCell);
  }
}

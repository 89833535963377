import { Navigate, Route, Routes } from "react-router-dom";
import { ROUTES } from "../app-routes/routeConfig";
import { Logout } from "../auth/components/Logout";
import { Private } from "../auth/components/Private";
import SsoLogin from "../auth/components/SsoLogin";
import { EquipmentDefinitionList } from "../features/admin/equipmentDefinition";
import { PricingSheet } from "../features/admin/pricingSheet";
import WelcomeScreen from "../features/welcomeScreen";
import Login from "../auth/components/Login";
import LoginPassword from "../auth/components/LoginPassword";
import LoginSSO from "../auth/components/LoginSSO";
import { RoleEnum } from "../../infrastructure/api-client";
import Templates from "../features/admin/configurationTemplate";
import { Sales } from "../features/sales";
import CustomerDetails from "../features/customerDetails";
import { DesignWorkspace } from "../features/designWorkspace";
import OrderSuccess from "ui/features/orderSuccess";

export function AppRouter() {
  return (
    <Routes>
      <Route path={ROUTES.LOGIN.path} element={<Login />} />
      <Route path={ROUTES.LOGIN_PASSWORD.path} element={<LoginPassword />} />
      <Route path={ROUTES.LOGIN_SSO.path} element={<LoginSSO />} />
      <Route path={ROUTES.LOGOUT.path} element={<Logout />} />
      <Route path={ROUTES.AUTH.SSO_LOGIN.path} element={<SsoLogin />} />

      <Route element={<Private />}>
        <Route
          path="*"
          element={<Navigate to={ROUTES.WELCOME_SCREEN.path} replace />}
        />
        <Route
          path={ROUTES.SALES_PROCESS.REVIEW.ORDER_SUCCESS.path}
          element={<OrderSuccess />}
        />
        <Route path={ROUTES.WELCOME_SCREEN.path} element={<WelcomeScreen />} />
        <Route path={ROUTES.SALES.path}>
          <Route path={ROUTES.SALES.LIST.path} element={<Sales />} />
          <Route
            path={ROUTES.SALES.DESIGN.EDIT.path}
            element={<DesignWorkspace />}
          />
        </Route>

        <Route
          path={ROUTES.SALES_PROCESS.CUSTOMER_DETAILS.path}
          element={<CustomerDetails />}
        />

        <Route
          path={ROUTES.SALES_PROCESS.DESIGN.path + "/*"}
          element={<DesignWorkspace />}
        />
      </Route>

      <Route
        element={
          <Private
            roles={[RoleEnum.OrganizationAdmin, RoleEnum.ProductAdmin]}
          />
        }
      >
        <Route
          path={ROUTES.CATALOGUE.CONFIGURATION_TEMPLATE.LIST.path}
          element={<Templates />}
        />

        <Route
          path={ROUTES.ADMIN.PRICING_SHEET.path}
          element={<PricingSheet />}
        />

        <Route
          path={ROUTES.CATALOGUE.CONFIGURATION_TEMPLATE.DESIGN.path + "/*"}
          element={<DesignWorkspace />}
        />

        <Route
          path={ROUTES.ADMIN.EQUIPMENT_DEFINITION.LIST.path + "/*"}
          element={<EquipmentDefinitionList />}
        >
          <Route
            path={ROUTES.ADMIN.EQUIPMENT_DEFINITION.LIST.$.EDIT.relativePath}
          />
        </Route>
      </Route>
    </Routes>
  );
}

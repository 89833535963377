import { IFieldState } from "./IFieldState";




export abstract class RenderableFieldMeta<TRecord> {
  abstract renderCell: (
    params: IFieldState<TRecord[keyof TRecord]>
  ) => React.ReactNode;
  abstract key: keyof TRecord;
}

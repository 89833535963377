import { ICheckoutService } from "../../../application/services/CheckoutService";
import { IGeometryModelService } from "../../../application/services/GeometryModelService";
import { ISendOrderService } from "../../../application/services/SendOrderService";
import { IPriceCalculationService } from "../../../application/services/PriceCalculationService";
import { IProductService } from "../../../application/services/ProductService";
import { IProductionLineService } from "../../../application/services/ProductionLineService";
import { IConfigurationService } from "../../../application/services/ConfigurationService";
import { IWorkstationInstanceService } from "../../../application/services/WorkstationInstanceService";
import { IWorkstationTypeService } from "../../../application/services/WorkstationTypeService";
import { User } from "../../auth/User";
import { useEquipmentDefinitionReducer } from "./equipmentDefinition/reducer";
import { useEquipmentDefinitionSlice } from "./equipmentDefinition/slice";
import { useGeometryModelReducer } from "./geometryModel/reducer";
import { useGeometryModelSlice } from "./geometryModel/slice";
import { useOrderReducer } from "./orders/reducer";
import { useConfigurationTemplateReducer } from "./configurationTemplate/reducer";
import { useConfigurationTemplateSlice } from "./configurationTemplate/slice";
import { useProductReducer } from "./product/reducer";
import { useProductSlice } from "./product/slice";
import { useProjectReducer } from "./project/reducer";
import { useProjectSlice } from "./project/slice";
import { useQuotingReducer } from "./quoting/reducer";
import { useQuotingSlice } from "./quoting/slice";
import { useWorkstationInstanceReducer } from "./workstationInstance/reducer";
import { useWorkstationInstanceSlice } from "./workstationInstance/slice";
import { IOrderService } from "application/services/OrderService";
import { useOrderSlice } from "./orders/orderSlice";
import { IConfigurationTemplateService } from "application/services/ConfigurationTemplateService";

export function useStore(
  user: User | undefined,
  services: {
    workstationInstanceService: IWorkstationInstanceService;
    productionLineLayoutService: IProductionLineService;
    workstationTypeService: IWorkstationTypeService;
    geometryModelService: IGeometryModelService;
    productService: IProductService;
    priceCalculationService: IPriceCalculationService;
    checkoutService: ICheckoutService;
    configurationService: IConfigurationService;
    sendOrderService: ISendOrderService;
    orderService: IOrderService;
    configurationTemplateService: IConfigurationTemplateService
  },
  getScreenshotRef: React.MutableRefObject<
    | (() => {
        imgUrl: string;
      })
    | undefined
  >
) {
  const productSlice = useProductSlice(user, services);
  const projectSlice = useProjectSlice(user, getScreenshotRef);
  const workstationSlice = useWorkstationInstanceSlice(user, services);
  const equipmentDefinitionSlice = useEquipmentDefinitionSlice(user, services);
  const geometryModelSlice = useGeometryModelSlice(user, services);
  const quotingSlice = useQuotingSlice(services);
  const predefinedLayoutSlice = useConfigurationTemplateSlice(user, services);
  const orderSlice = useOrderSlice();

  return {
    product: useProductReducer(productSlice),
    project: useProjectReducer(
      projectSlice,
      workstationSlice,
      quotingSlice,
      user,
      services,
      getScreenshotRef
    ),
    workstation: useWorkstationInstanceReducer(
      workstationSlice,
      equipmentDefinitionSlice,
      geometryModelSlice,
      services
    ),
    equipmentDefinition: useEquipmentDefinitionReducer(
      equipmentDefinitionSlice
    ),
    geometryModel: useGeometryModelReducer(geometryModelSlice),
    quoting: useQuotingReducer(
      services,
      quotingSlice,
      workstationSlice,
      equipmentDefinitionSlice,
      productSlice
    ),
    predefinedLayout: useConfigurationTemplateReducer(
      user,
      services,
      predefinedLayoutSlice,
      workstationSlice,
      orderSlice,
      quotingSlice
    ),
    order: useOrderReducer(user, services, orderSlice, workstationSlice, quotingSlice),
  };
}

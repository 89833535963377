import { createApiProvider } from "./createApiProvider";
import { fromResponse } from "./helpers/fromResponse";
import { ConfigurationTemplatesApi } from "infrastructure/api-client";
import { IConfigurationTemplateRepository } from "./contracts/configurationTemplate";
import { configurationMapper } from "application/mappers/configurationMapper";

export function buildConfigurationTemplateProvider(api: ConfigurationTemplatesApi) {

  const apiProvider = createApiProvider({
    get: async (id, userId) => await fromResponse(api.configurationTemplatesGet(id)),
    // getList: async (userId, request) => await fromResponse(api.productGetListPost(request)),
    getList: async (userId, request) => {
      const items = await fromResponse(api.configurationTemplatesListGet());
      return {
        items,
        filteredCount: items.length,
        totalCount: items.length,
      };
    },
  });

  const repository: IConfigurationTemplateRepository = {
    ...apiProvider,
    create: async (x) => {
      const configurationRequest = configurationMapper.mapModelToRequest(x.configuration);
      const response = await fromResponse(
        api.configurationTemplatesPost({
          ...x,
          configuration: configurationRequest,
        })
      );
      return response;
    },
    update: async (x) => {
      const configurationRequest = configurationMapper.mapModelToRequest(x.configuration);
      const response = await fromResponse(
        api.configurationTemplatesPut({
          ...x,
          configuration: configurationRequest,
        })
      );
      return response;
    },
  };

  return repository;
}

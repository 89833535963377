import { OptionalRecord } from "../../../common/types/OptionalRecord";
import {
  DataGrid,
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
  GridSortDirection,
  useGridApiRef,
} from "@mui/x-data-grid";
import { splitAndCapitalizeCamelCase } from "../helpers/splitAndCapitalizeCamelCase";
import { Box, SxProps, Theme, Typography } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { isEqual } from "lodash";
import { RenderableColumnBase } from "./RenderableColumnBase";
import { Button } from "../Button";
import LayoutBox from "../Layout/LayoutBox";
import { generateUUID } from "three/src/math/MathUtils";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { IFilteredListResult } from "../../app/store/pagination/pagination";
import { TableRequest } from "../../../infrastructure/paging/paging";
import { SortOrder } from "../../../infrastructure/api-client";
import { ExtractKeys } from "../../../common/types/ExtractKeys";
import ArrowForward from "@mui/icons-material/ArrowForward";

const pageSizeOptions = [15, 50, 100];

export function DataTable<
  TRecord extends OptionalRecord<ExtractKeys<TRecord>, any> & {
    id: string;
  }
>(props: {
  data?: TRecord[];
  handleRowClick?: (row: TRecord) => void;
  editable?: boolean;
  footer?: boolean;
  columns?: (ExtractKeys<TRecord> | RenderableColumnBase<TRecord>)[];
  handleChange?: (rows: TRecord[]) => void;
  controls?: publicGridControlTypes[];
  handleSave?: (data: TRecord[]) => void;
  handleEdit?: (row: TRecord) => void;
  handleDelete?: (record: TRecord) => Promise<void>;
  handleContinue?: (row: TRecord) => void;
  addRow?: boolean | { label?: string; handleClick: () => void };
  errors?: string | OptionalRecord<ExtractKeys<TRecord>, string>[];
  pagination?: {
    pageNumber?: number;
    pageSize?: number;
    getPage: (
      request: TableRequest<TRecord>
    ) => Promise<IFilteredListResult<TRecord>>;
    disableRepaginateOnSort?: boolean;
  };
  actionPerRowEnabled?: {
    delete?: (row: TRecord) => boolean;
    edit?: (row: TRecord) => boolean;
  };
  sx?: SxProps<Theme> | undefined;
  checkboxSelection?: boolean;
}) {
  const {
    handleRowClick,
    editable,
    columns,
    handleChange,
    errors,
    addRow,
    handleEdit,
    handleDelete,
    handleContinue,
    pagination,
    actionPerRowEnabled,
    sx,
  } = props;
  let hasFooter = props.footer;
  if (hasFooter == null) {
    hasFooter = true;
  }

  const [data, setData] = useState(props.data ?? []);
  const [rowCount, setRowCount] = useState<number>();
  const [sortModel, setSortModel] =
    useState<{ field: string; sort: GridSortDirection }[]>();
  const [newRowIds, setNewRowIds] = useState<string[]>([]);
  const [fetchPagePending, setFetchPagePending] = useState(false);

  useEffect(() => {
    setData(props.data ?? []);
  }, [props.data]);

  let controls: (
    | gridControlTypes
    | { label: string; handleClick: () => void }
  )[] = [...(props.controls ?? [])];

  const [paginationModel, setPaginationModel] = useState({
    pageNumber: pagination?.pageNumber ?? 0,
    pageSize: pagination?.pageSize ?? pageSizeOptions[0],
  });

  const apiRef = useGridApiRef();

  const previousRows = useRef<TRecord[]>([]);

  const dynamicColDefs = useMemo(() => {
    const firstRow = data?.at(0);

    let keys = columns ?? (firstRow ? Object.keys(firstRow) : []);

    let columnDefinitions = [
      ...keys
        .map((column) => {
          let key: keyof TRecord | string | undefined = undefined;

          let renderCell:
            | ((params: {
                value: any;
                onChange?: (value: any) => void;
              }) => React.ReactNode)
            | undefined = undefined;
          if (typeof column === "object") {
            key = column.key;
            renderCell = column.renderCell;
          } else {
            key = column;
          }

          if (key == "id") {
            return undefined;
          }

          const def: GridColDef = {
            field: key?.toString(),
            headerName: splitAndCapitalizeCamelCase(key?.toString()),
            editable: !renderCell && editable,
            flex: 1,
            renderCell:
              renderCell != undefined
                ? (params) =>
                    renderCell!({
                      value: params.value,
                      onChange: (v) => {
                        const updatedRow = { ...params.row };
                        updatedRow[params.colDef.field] = v;

                        if (apiRef?.current?.updateRows) {
                          apiRef.current.updateRows([
                            {
                              ...updatedRow,
                            },
                          ]);
                        }
                      },
                    })
                : undefined,
          };
          return def;
        })
        .filter((x) => x != null)
        .map((x) => x!),
    ];

    if (keys?.length > 0 && handleEdit != null) {
      const editAction: GridActionsColDef = {
        field: "actions-edit",
        headerName: "",
        editable: false,
        type: "actions",
        getActions: (params) => {
          if (actionPerRowEnabled?.edit) {
            if (!actionPerRowEnabled.edit(params.row)) {
              return [];
            }
          }

          return [
            <GridActionsCellItem
              onClick={() => {
                const row = params.row;
                handleEdit(row);
              }}
              icon={<EditIcon sx={{ color: "#475467" }} />}
              label="Edit"
            />,
          ];
        },
      };

      columnDefinitions = [...columnDefinitions, editAction];
    }

    if (keys?.length > 0 && handleDelete != null) {
      const deleteAction: GridActionsColDef = {
        field: "actions",
        headerName: "",
        editable: false,
        type: "actions",
        getActions: (params) => {
          if (actionPerRowEnabled?.delete) {
            if (!actionPerRowEnabled.delete(params.row)) {
              return [];
            }
          }

          return [
            <GridActionsCellItem
              onClick={() => {
                const row = params.row;

                handleDelete(row).then(() => {
                  if (isPaginationEnabled) {
                    repaginate();
                    setFetchPagePending(true);
                  } else {
                    let rows = apiRef.current.getSortedRows();
                    const updated = rows.filter((x) => x !== row);
                    apiRef.current.updateRows([
                      { id: row.id, _action: "delete" },
                    ]);
                  }
                });
              }}
              icon={<DeleteIcon sx={{ color: "#475467" }} />}
              label="Delete"
            />,
          ];
        },
      };

      columnDefinitions = [...columnDefinitions, deleteAction];
    }

    if (handleContinue != null) {
      const continueAction: GridActionsColDef = {
        field: "actions-continue",
        headerName: "",
        editable: false,
        type: "actions",
        getActions: (params) => {
          if (actionPerRowEnabled?.edit) {
            if (!actionPerRowEnabled.edit(params.row)) {
              return [];
            }
          }

          return [
            <GridActionsCellItem
              onClick={() => {
                const row = params.row;
                handleContinue(row);
              }}
              icon={<ArrowForward sx={{ color: "#475467" }} />}
              label="Continue"
            />,
          ];
        },
      };

      columnDefinitions = [...columnDefinitions, continueAction];
    }

    return [...columnDefinitions];
  }, [data, handleDelete, handleContinue]);

  const isPaginationEnabled = pagination != null;

  const fetchPage = async () => {
    pagination
      ?.getPage({
        pageNumber: paginationModel.pageNumber + 1,
        pageSize: paginationModel.pageSize,
        sort: sortModel?.map((x) => {
          return {
            column: x.field,
            order:
              x.sort === "asc" ? SortOrder.Ascending : SortOrder.Descending,
          };
        }),
      })
      .then((page) => {
        setData(page.items);
        setRowCount(page.totalCount);
        setFetchPagePending(false);
      });
  };

  const repaginate = () => {
    setPaginationModel((prev) => {
      return { ...prev, pageNumber: 0 };
    });
  };

  useEffect(() => {
    newRowIds.forEach((id) => {
      const rowEl = apiRef.current.getRowElement(id);
      if (rowEl) {
        rowEl.classList.add("new-row");
      }
    });
  }, [newRowIds, apiRef]);

  useEffect(() => {
    if (
      isPaginationEnabled &&
      paginationModel?.pageNumber != null &&
      paginationModel?.pageSize != null
    ) {
      if (fetchPagePending) {
        fetchPage();
      }
    }
  }, [fetchPagePending]);

  useEffect(() => {
    if (!pagination?.disableRepaginateOnSort) {
      repaginate();
    } else {
      setFetchPagePending(true);
    }
  }, [sortModel]);

  useEffect(() => {
    setFetchPagePending(true);
  }, [paginationModel]);

  const handleAddRow = () => {
    const id = generateUUID();
    const newRow = { id }; // generateUniqueId is a placeholder function, replace it with your own id generation logic
    apiRef.current.updateRows([{ ...newRow }]);
    setNewRowIds((prev) => [...prev, id]);
    //apiRef.current.getRowElement
    // handleChange && handleChange([...data, newRow]);
  };

  if (addRow === true) {
    if (!controls) {
      controls = [];
    }

    controls = [...controls, "add-row"];
  } else if (addRow) {
    if (!controls) {
      controls = [];
    }

    controls = [...controls, { ...addRow, label: addRow.label ?? "Add Row" }];
  }

  if (!(dynamicColDefs?.length > 0)) {
    return <></>;
  }

  return (
    <LayoutBox className="dataTable-root" sx={{ ...sx }}>
      <LayoutBox
        className="dataTable-dataGridContainer"
        sx={{ minHeight: "200px", padding: 0 }}
      >
        <DataGrid
          className="dataTable-dataGrid"
          rowCount={rowCount}
          pageSizeOptions={pageSizeOptions}
          paginationMode={isPaginationEnabled ? "server" : "client"}
          sortingMode={isPaginationEnabled ? "server" : "client"}
          onSortModelChange={(sm) => {
            setSortModel(sm);
          }}
          paginationModel={{
            page: paginationModel.pageNumber,
            pageSize: paginationModel.pageSize,
          }}
          onPaginationModelChange={(m) => {
            setPaginationModel({
              pageNumber: m.page,
              pageSize: m.pageSize,
            });
          }}
          sx={{
            borderRadius: "0.5rem",
            border: "1px solid #EAECF0",
            "& .MuiDataGrid-columnHeaders": {
              minHeight: "2.75rem !important",
              maxHeight: "2.75rem !important",
              borderColor: "#EAECF0",
            },
            "& .MuiDataGrid-withBorderColor": {
              borderColor: "#EAECF0",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontSize: "0.75rem",
              fontWeight: 500,
              color: "#475467",
            },
            "& .MuiDataGrid-cellContent": {
              fontSize: "0.875rem",
              fontWeight: 500,
              color: "#101828",
            },
            "& .MuiDataGrid-cell, & .MuiDataGrid-columnHeader": {
              "&:focus": {
                outline: "none",
              },
            },
            "& .MuiInputBase-root": {
              width: "auto",
              marginBottom: 0,
            },
            "& .MuiDataGrid-footerContainer": {
              background: "#F7F7F1",
            },
            "& .new-row": {
              backgroundColor: "#f0f8ff",
            },
            "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows, .MuiDataGrid-selectedRowCount":
              {
                fontSize: "0.875rem",
                fontWeight: 500,
                color: "#344054",
              },
            "& .MuiDataGrid-virtualScroller": {
              minHeight: "200px",
            },
            "& .MuiDataGrid-overlay": {
              color: "#555555",
              fontSize: 14,
            },
            ...(!hasFooter
              ? {
                  "& .MuiDataGrid-footerContainer": {
                    display: "none",
                  },
                }
              : undefined),
          }}
          apiRef={apiRef}
          rows={data}
          columns={dynamicColDefs}
          onRowClick={(e) => {
            handleRowClick && handleRowClick(e.row);
          }}
          onStateChange={(state, event, details) => {
            if (handleChange) {
              const updatedRows = apiRef.current
                .getSortedRows()
                .map((x) => ({ ...x })) as TRecord[];

              if (!isEqual(previousRows.current, updatedRows)) {
                previousRows.current = updatedRows;
                handleChange(updatedRows);
              }
            }
          }}
          checkboxSelection={props.checkboxSelection}
        />
        {errors != null && Array.isArray(errors) && (
          <ErrorDisplay errors={errors} />
        )}
      </LayoutBox>

      {controls && controls.length > 0 && (
        <Box className="dataTable-controlsContainer" component={"div"}>
          <Controls
            items={controls}
            handleSave={() => {
              props.handleSave &&
                props.handleSave(apiRef.current.getSortedRows() as TRecord[]);
            }}
            handleAddRow={handleAddRow}
          />
        </Box>
      )}
    </LayoutBox>
  );
}

function Controls({
  items,
  handleSave,
  handleAddRow,
  className,
}: {
  items: (gridControlTypes | { label: string; handleClick: () => void })[];
  handleSave?: () => void;
  handleAddRow?: () => void;
  className?: string;
}) {
  return (
    <LayoutBox
      direction="horizontal"
      className={`dataTable-controls ${className}`}
    >
      {items.map((item) => {
        switch (item) {
          case "save":
            return (
              <Button onClick={handleSave} className="continue-btn-new">
                Save
              </Button>
            );
            break;

          case "add-row":
            return (
              <Button
                onClick={handleAddRow}
                className="continue-btn-new"
                sx={{
                  padding: "5px 10px !important",
                  fontSize: "14px !important",
                  marginTop: "0 !important",
                }}
              >
                Add Row
              </Button>
            );
            break;

          default:
            if (item instanceof Object) {
              return (
                <Button
                  onClick={() => {
                    item.handleClick();
                  }}
                >
                  {item.label}
                </Button>
              );
            } else {
              throw new Error(`encountered invalid grid control`);
            }
            break;
        }

        return <></>;
      })}
    </LayoutBox>
  );
}

function ErrorDisplay(props: { errors: OptionalRecord<string, string>[] }) {
  console.log(props.errors);

  const keyMapping: { [key: string]: string } = {
    isConfigurable: "Is Configurable",
    name: "Name",
    productId: "Product Id",
  };

  return (
    <>
      {props.errors
        ?.filter((x) => x != null)
        .map(
          (e, i) =>
            !e.id && (
              <Typography component={"p"} sx={{ color: "#f00000" }}>
                Please fix row {i + 1} errors:{" "}
                {Object.keys(e)
                  .map((key) => keyMapping[key] || key)
                  .join(", ")}{" "}
                is required
                {/* ({Object.keys(e).reduce((a, b) => {
              return `${a} is required, ${b} is required`;
            }, "")}) */}
              </Typography>
            )
        )}
    </>
  );
}

type publicGridControlTypes = "save";

type gridControlTypes = "add-row" | publicGridControlTypes;

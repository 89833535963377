import ArrowForward from "@mui/icons-material/ArrowForward";
import { Button, SxProps } from "@mui/material";
import { IProduct } from "application/models/IProduct";
import { AppModal } from "ui/library/AppModal";
import { StandardForm } from "ui/library/Form";
import { FieldMeta } from "ui/library/Form/fieldMeta/fieldMeta";
import * as yup from "yup";
import { usePricingSheetContext } from "../context";
import { ProductFormModel } from "../models/ProductFormModel";
import { generateUUID } from "three/src/math/MathUtils";
import { useState } from "react";
import { ROUTES } from "ui/app-routes/routeConfig";
import { submitButtonStyle } from "ui/library/AppModal/ModalOptionButtons";
import { useNavigate } from "react-router-dom";

interface CreateEditModalProps {
  isOpen: boolean;
  handleClose: () => void;
  editData: IProduct | undefined;
}

const fields: FieldMeta<ProductFormModel>[] = [
  {
    container: "column",
    containerGridSize: 6,
    fields: [
      {
        key: "name",
        field: "text-input",
      },
    ],
    label: "Product name",
  },
  {
    container: "column",
    containerGridSize: 6,
    fields: [
      {
        key: "description",
        field: "text-input",
      },
    ],
    label: "Product description",
  },
  {
    key: "price",
    field: "text-input",
    label: "Price",
  },
  {
    key: "currencyIso",
    field: "text-input",
    label: "Currency ISO",
    readOnly: true,
  },
  {
    key: "sku",
    field: "text-input",
    label: "SKU",
  },
];

const validationSchema: yup.SchemaOf<ProductFormModel> = yup.object().shape({
  name: yup.string().required("Name is required"),
  description: yup.string().required("Description is required"),
  price: yup.number().required("Price is required"),
  currencyIso: yup.string().required("Currency is required"),
  sku: yup.string().required("SKU is required"),
});

const CreateEditModal = ({
  isOpen,
  handleClose,
  editData,
}: CreateEditModalProps) => {
  const navigate = useNavigate();
  const { updateProducts } = usePricingSheetContext();
  const [confirmModal, setConfirmModal] = useState<boolean>(false);

  const initialValues = editData ?? { currencyIso: "USD" };

  const onSubmit = async (values: ProductFormModel) => {
    const id = editData?.id ?? generateUUID(); //todo: move such logic out of components
    const model = { ...values, id };
    await updateProducts([model])
      .then(() => {
        handleClose();
        setConfirmModal(true);
      })
      .catch(() => {
        alert("Error saving product!");
      });
  };

  const isEdit = editData != null;

  return (
    <>
      <AppModal
        title={!isEdit ? "Create Product" : "Edit Product"}
        subTitle={`${!isEdit ? "Create" : "Edit"} your product catalogue`}
        handleClose={handleClose}
        isOpen={isOpen}
      >
        <div className="new-sale-form">
          <StandardForm<ProductFormModel>
            fields={fields}
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            submitButton={
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                endIcon={<ArrowForward />}
                sx={buttonStyle}
              >
                {!isEdit ? "Create" : "Update"}
              </Button>
            }
          />
        </div>
      </AppModal>
      {confirmModal && (
        <AppModal
          isOpen={confirmModal}
          handleClose={() => setConfirmModal(!confirmModal)}
          title="Products"
          subTitle="Successfully created product"
          type="confirm"
        >
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={submitButtonStyle}
            onClick={() => navigate(ROUTES.ADMIN.PRICING_SHEET.path)}
          >
            Continue
          </Button>
        </AppModal>
      )}
    </>
  );
};

const buttonStyle: SxProps = {
  width: "100%",
  padding: "0.625rem 1.125rem",
  borderRadius: "0.5rem",
  border: "1px solid #0074a7",
  background: "#0074a7",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  fontSize: "1rem",
  fontWeight: 600,
  color: "#ffffff",
  textTransform: "capitalize",
  marginTop: 0,
};

export default CreateEditModal;

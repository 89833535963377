import { Navigate, Outlet, Route, useLocation } from "react-router-dom";
import { useAuthContext } from "../context";
import { ROUTES } from "../../app-routes/routeConfig";
import React, { useEffect, useRef, useState } from "react";
import { RoleEnum } from "../../../infrastructure/api-client";

type ChildrenProp = {
  children: React.ReactNode;
  component?: never;
};

type ComponentProp = {
  children?: never;
  component: React.ReactNode;
};

type OutletProp = {
  children?: never;
  component?: never;
};

type PrivateProps = (ChildrenProp | ComponentProp | OutletProp) & {
  roles?: RoleEnum[]
};

export function Private({children, component, roles}: PrivateProps) {
  const content = children || component;

  const location = useLocation();
  const [isAuthentticationInProgres, setIsAuthenticationInProgress] =
    useState(true);

  const isLoading = useRef(false);

  const { user: authUser, authenticateStoredSession } = useAuthContext();


  useEffect(() => {
    if (!authUser && !isLoading.current) {
      isLoading.current = true;
      authenticateStoredSession().then(() => {
        setIsAuthenticationInProgress(false);
        isLoading.current = false;
      });
    }
  }, [authUser]);

  
  if (roles != null && (authUser?.role == null || !roles.includes(authUser?.role))) {
    return <>UNAUTHORIZED</>
  }

  return authUser ? (
    <> {content ?? <Outlet />}</>
  ) : isAuthentticationInProgres ? (
    <div>Loading...</div>
  ) : (
    <Navigate to={ROUTES.LOGOUT.path} state={{ from: location }} />
  );
}

import React from "react";
import { FactoryViewport } from "../factoryViewport";
import WorkPanel from "../workPanel";
import { ViewportContainer } from "../../app/components/ViewportContainer";
import { FeatureContainer } from "../../library/Layout/FeatureContainer";
import { DesignHeader } from "./components/DesignHeader";
import { DesignFooter } from "./components/DesignFooter";
import { useAppContext } from "../../app/context";

export function DesignWorkspace() {
  const parentContext = useAppContext();
  const { workPanelOverlayRef, set2DViewRef, getScreenshotRef } =
    parentContext.uiRefs;

  const { saveCurrentOrder } = parentContext.order.dispatch;

  return (
    <FeatureContainer
      header={
        <DesignHeader
          handleSaveCurrentOrder={async () => {
            await saveCurrentOrder();
          }}
        />
      }
      footer={<DesignFooter />}
      wideContent
    >
      <ViewportContainer
        viewport={
          <FactoryViewport
            set2DViewRef={set2DViewRef}
            getScreenshotRef={getScreenshotRef}
            key={"main-viewport-holder"}
          />
        }
        workPanel={<WorkPanel />}
        workpanelOverlayRef={workPanelOverlayRef}
      />
    </FeatureContainer>
  );
}

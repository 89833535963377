import { IWorkstationType } from "../../application/models/IWorkstationType";
import { EquipmentDefinitionApi } from "../api-client";
import { createApiProvider } from "./createApiProvider";
import { fromResponse, getPageFromResponse } from "./helpers/fromResponse";

export function buildEquipmentDefinitionProvider(api: EquipmentDefinitionApi) {
  const apiProvider = createApiProvider<IWorkstationType>({
    update: async (item, userId) => {
      await api.equipmentDefinitionPut(mapToApi(item));
    },
    create: async (item, userId) => {
      return await fromResponse(api.equipmentDefinitionPost(mapToApi(item)));
    },
    delete: async (id) => {
      await api.equipmentDefinitionDelete(id);
    },
    getList: async (userId) =>
      await getPageFromResponse(api.equipmentDefinitionListGet()),

    updateList: async (items) =>
      await fromResponse(
        api.equipmentDefinitionListPut({
          items: items.map(mapToApi),
        })
      ),
  });

  return apiProvider;
}

function mapToApi(i: IWorkstationType) {
  const mapped = {
    ...i,
    components: i.components.map((c) => {
      return { ...c, equipmentDefinitionId: i.id };
    }),
  };

  return mapped;
}

import { injected } from "brandi";
import DI_TOKENS from "../../DI_TOKENS";
import IMockDependency from "../IMockDependency";
import IMockService from "./IMockService";

export default class MockService implements IMockService {
    dependency: IMockDependency;

    constructor(dependency: IMockDependency){

        this.dependency = dependency;

    }

    logTestData(): void {
        alert("di test 111, from dep: " + this.dependency.Data);
    }

}

injected(MockService,DI_TOKENS.IMockDependency.Value)


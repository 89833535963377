import { Slider, Stack, Typography } from "@mui/material";

export function RotationControl(props: {
    handleChange: (rotationDeg: number) => void,
    rotationDeg: number
}) {

    const {handleChange, rotationDeg} = props;

    const getValueLabel = (val: number) => `${val.toFixed()}°`;

    return (
        <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
            <Typography>
                Rotation
            </Typography>
            <Slider
                aria-label="Rotation"
                defaultValue={0}
                value={rotationDeg}
                getAriaValueText={getValueLabel}
                valueLabelDisplay="auto"
                valueLabelFormat={getValueLabel}
                step={15}
                marks
                min={-180}
                max={180}
                onChange={(e,v) => handleChange(v as number)}
            />

        </Stack>
    )


}
import { ReactNode } from "react";

export function FeatureContainer({
  header,
  children,
  footer,
  wideContent,
}: {
  header?: ReactNode;
  children: ReactNode;
  footer?: ReactNode;
  wideContent?: boolean;
}) {
  //TODO: improve the conditional below, HACKY
  return (
    <>
      {wideContent ? (
        <>
          <div className="container-new">{header}</div>
          {children}
        </>
      ) : (
        <div className="container-new">
          {header}
          {children}
        </div>
      )}
      {footer != null && footer}
    </>
  );
}

import { Box, Html, useGLTF, useHelper } from "@react-three/drei";
import { MeshProps, useFrame } from "@react-three/fiber";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import * as THREE from "three";
import { BoxHelper, Euler, Object3D, Vector3 } from "three";
import { FactoryViewportContext } from "../FactoryViewportContext";
import { TooltipControl } from "..";
import OrthonormalVector, {
  isVectorEqual,
} from "../../../../common/math/OrthonormalVector";
import { useHover } from "@use-gesture/react";
import { Model } from "@jmk/ar-toolbox-core/core";
import PLUS_ICON from "../../../../assets/plus.svg";

const WorkstationInstance: React.FC<
  {
    uuid: string;
    modelUrl: string;
    onConnectorClick: (
      workstationInstanceId: string,
      connectorDirection: OrthonormalVector
    ) => void;
    visibilityMap: {
      isIncluded: boolean;
      nodeId: string;
    }[];
    directionToPreviousWorkstation: OrthonormalVector | null;
    directionToNextWorkstation: OrthonormalVector | null;
  } & MeshProps
> = ({ ...props }) => {
  let rotationRad = 0;

  let quaternion = new THREE.Quaternion(); // create one and reuse it

  const refV = new Vector3(0, 0, 1);

  quaternion.setFromUnitVectors(refV, refV);

  const [is3dBoxHover, setIs3dBoxHover] = useState(false);
  const [is2dBoxHover, setIs2dBoxHover] = useState(false);
  const isHover = is3dBoxHover || is2dBoxHover;
  // const hover = (e: ThreeEvent<PointerEvent>) => {
  //   setIs3dBoxHover(true);
  // };
  // const unhover = (e: ThreeEvent<PointerEvent>) => {
  //   setIs3dBoxHover(false);
  // };

  if (props.directionToPreviousWorkstation) {
    const dir = props.directionToPreviousWorkstation;

    const facingVector = new Vector3(dir.x, dir.y, -dir.z);

    quaternion = new THREE.Quaternion();

    quaternion.setFromUnitVectors(facingVector, refV);
  }

  const context = useContext(FactoryViewportContext);

  const isSelected = context.selectedWorkstationInstanceId == props.uuid;

  const gltfUrl = props.modelUrl;

  const { scene } = useGLTF(process.env.PUBLIC_URL + gltfUrl, true, true);

  const cachedGeometry = useMemo(() => {
    const aabb = new THREE.Box3();
    aabb.setFromObject(scene);
    const box = aabb.clone();

    const center = new Vector3();
    box.getCenter(center);

    scene.setRotationFromQuaternion(quaternion);

    scene.translateX(-center.x);
    scene.translateY(-box.min.y);
    scene.translateZ(-center.z);

    aabb.setFromObject(scene);

    //skinned meshes dont clone well with native three
    const cloned = Model.fromObject3D(scene).clone()?.toObject3d();

    if (!cloned) {
      throw new Error("can't clone the model mesh");
    }

    return { cloned, box: aabb.clone() };
  }, [gltfUrl]);

  const copiedScene = cachedGeometry.cloned;

  useEffect(() => {
    const nodes: string[] = [];

    let children = copiedScene.children;
    //hack we have to know how nested is the scene - TBD
    const firstLevelChildren = copiedScene.children[0].children;

    children = children.concat(firstLevelChildren);

    children.forEach((c) => {
      nodes.push(c.name);

      const match = props.visibilityMap.find((x) => x.nodeId === c.name);

      if (match) {
        c.visible = match.isIncluded;
      }
    });
  }, [props.visibilityMap]);

  const positionedSceneRef = useRef<THREE.Object3D>();

  let highlightColor = "red";

  if (props.uuid == context.selectedWorkstationInstanceId) {
    highlightColor = "green";
  }

  const bbox = cachedGeometry.box;

  const minCorner = bbox.min.clone();
  const maxCorner = bbox.max.clone();

  let connectors = buildConnectors(maxCorner, minCorner);

  //remove already used connectors
  //TODO: current algorithm is purely geometrical and will fail for oblique directions
  //some id based connector referencing might be more sensible?
  connectors = connectors.filter((c) => {
    const isPrevUsed =
      props.directionToPreviousWorkstation != null &&
      isVectorEqual(c.localDirection, props.directionToPreviousWorkstation);
    const isNextUsed =
      props.directionToNextWorkstation != null &&
      isVectorEqual(c.localDirection, props.directionToNextWorkstation);

    const isUsed = isPrevUsed || isNextUsed;

    return !isUsed;
  });

  const bindHover = useHover(
    (state) => state && setIs2dBoxHover(state.hovering || false)
  );

  const isMiddleStation =
    props.directionToPreviousWorkstation != null &&
    props.directionToNextWorkstation != null;

  return (
    <mesh {...props}>
      <primitive ref={positionedSceneRef} object={copiedScene} scale={1} />

      <HighlightBox
        highlightColor={highlightColor}
        on3dHoverChange={setIs3dBoxHover}
        positionedSceneRef={positionedSceneRef}
        isSelected={isSelected}
      />

      <meshStandardMaterial roughness={0.75} emissive="#404057" />

      {!isMiddleStation &&
        connectors.map((connector) => {
          return (
            <Html
              zIndexRange={[8, 9]}
              sprite={true}
              position={[connector.x, 0, connector.z]}
            >
              <TooltipControl
                onClick={(e) =>
                  props.onConnectorClick(props.uuid, connector.localDirection)
                }
                className="content"
                //style={{ backgroundColor: isHover ? 'red' : "blue" }}
                style={{ opacity: !isHover ? "0.0" : "1.0" }}
                onPointerEnter={() => setIs2dBoxHover(true)}
                onPointerLeave={() => setIs2dBoxHover(false)}
              >
                <img src={PLUS_ICON} alt="plus" />
              </TooltipControl>
            </Html>
          );
        })}
    </mesh>
  );
};

export default WorkstationInstance;

function buildConnectors(maxCorner: THREE.Vector3, minCorner: THREE.Vector3) {
  const minX = minCorner.x;
  const maxX = maxCorner.x;

  const minZ = minCorner.z;
  const maxZ = maxCorner.z;

  const centerX = (minX + maxX) / 2.0;
  const centerZ = (minZ + maxZ) / 2.0;

  const frontConnectorPosition = {
    x: centerX,
    z: minZ,
    localDirection: {
      x: 0,
      y: 0,
      z: -1,
    } as OrthonormalVector,
  };

  const backConnectorPosition = {
    x: centerX,
    z: maxZ,
    localDirection: {
      x: 0,
      y: 0,
      z: 1,
    } as OrthonormalVector,
  };

  const leftConnectorPosition = {
    x: minX,
    z: centerZ,
    localDirection: {
      x: -1,
      y: 0,
      z: 0,
    } as OrthonormalVector,
  };

  const rightConnectorPosition = {
    x: maxX,
    z: centerZ,
    localDirection: {
      x: 1,
      y: 0,
      z: 0,
    } as OrthonormalVector,
  };

  let connectors = [
    frontConnectorPosition,
    backConnectorPosition,
    leftConnectorPosition,
    rightConnectorPosition,
  ];
  return connectors;
}

const HighlightBox = (props: {
  positionedSceneRef: React.MutableRefObject<
    THREE.Object3D<THREE.Event> | undefined
  >;
  highlightColor: string;
  on3dHoverChange: (isHover: boolean) => void;
  isSelected: boolean;
}) => {
  const {
    positionedSceneRef,
    highlightColor,
    on3dHoverChange: onHoverChange,
  } = props;

  const offsettedBoxRef = useRef<Object3D>(null);
  const boxRef = useRef<Object3D>(null);
  const [bboxSize, setBboxSize] = useState<Vector3>();
  const isHover = useRef(false);

  useHelper(boxRef, BoxHelper, highlightColor);

  if (boxRef.current) {
    boxRef.current.visible = false;
  }

  useFrame(({ raycaster }) => {
    if (positionedSceneRef.current && offsettedBoxRef.current) {
      positionedSceneRef.current.updateWorldMatrix(true, true);

      const rotationCompensation = new Euler();

      rotationCompensation.setFromRotationMatrix(
        positionedSceneRef.current.matrixWorld.clone()
      );

      rotationCompensation.x = 0;
      rotationCompensation.y = -rotationCompensation.y;
      rotationCompensation.z = 0;

      const worldPos = new Vector3();

      positionedSceneRef.current.getWorldPosition(worldPos);

      //boxRef.current.position.setY(worldPos.y);
      offsettedBoxRef.current.setRotationFromEuler(rotationCompensation);

      const prevHover = isHover.current;
      isHover.current =
        raycaster.intersectObject(offsettedBoxRef.current).length > 0;

      if (isHover.current !== prevHover) {
        onHoverChange(isHover.current);
      }

      return;
    }

    if (!bboxSize && positionedSceneRef.current) {
      const bbox = new THREE.Box3().setFromObject(positionedSceneRef.current);

      const tmpSize = new THREE.Vector3();

      bbox.getSize(tmpSize);

      setBboxSize(tmpSize);
    }
  });

  return bboxSize ? (
    <>
      <Box
        visible={false}
        ref={offsettedBoxRef}
        args={[bboxSize.x * 1.1, bboxSize.y * 1.1, bboxSize.z * 1.1]}
        position={new Vector3(0, bboxSize.y / 2, 0)}
      />
      {props.isSelected && (
        <Box
          visible={false}
          ref={boxRef}
          args={[bboxSize.x, bboxSize.y, bboxSize.z]}
          position={new Vector3(0, bboxSize.y / 2, 0)}
        />
      )}
    </>
  ) : (
    <></>
  );
};

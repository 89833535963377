import { injected } from "brandi";
import OrthonormalVector from "../../common/math/OrthonormalVector";
import { Point } from "../../common/math/Point";
import DI_TOKENS from "../../DI_TOKENS";
import { IWorkstationInstance } from "../models/IWorkstationInstance"
import { IWorkstationInstanceService } from "./WorkstationInstanceService";

export interface IProductionLineService {

    calculateNextInstancePosition(referenceInstanceId: string, direction: OrthonormalVector, allInstances: IWorkstationInstance[]): { x: number, y: number, z: number } | null

    addEquipmentInstance(referenceInstanceId: string, workstationTypeId: string, direction: OrthonormalVector | undefined, allInstances: IWorkstationInstance[], position?: Point): Promise<IWorkstationInstance | null>

    addFirstEquipmentInstance(equipmentDefinitionId: string, position?: Point): Promise<IWorkstationInstance>

    removeEquipmentInstance(id: string, allInstances: IWorkstationInstance[]): IWorkstationInstance[]

}

export class ProductionLineService implements IProductionLineService {

    private _workstationInstanceService: IWorkstationInstanceService;

    public constructor(workstationInstanceService: IWorkstationInstanceService) {
        this._workstationInstanceService = workstationInstanceService;
    }

    removeEquipmentInstance(id: string, allInstances: IWorkstationInstance[]): IWorkstationInstance[] {

        let result = allInstances;

        const instanceToRemove = allInstances.find(x => x.id === id);

        if (instanceToRemove) {

            const prevId = instanceToRemove.previousInstanceId;

            const nextId = allInstances.find(x => x.previousInstanceId === id)?.id ?? null;

            result = result.filter(x => x.id !== id);

            const nextInstnace = result.find(x => x.id === nextId);

            if (nextInstnace) {
                nextInstnace.previousInstanceId = prevId;
            }

        }

        return result;

    }

    addFirstEquipmentInstance(equipmentDefinitionId: string, position?: Point): Promise<IWorkstationInstance> {

        let x = position?.x ?? 0;
        let y = position?.y ?? 0;
        let z = position?.z ?? 0;

        return this._workstationInstanceService.build(x, y, z, undefined, equipmentDefinitionId);
    }


    async addEquipmentInstance(referenceInstanceId: string, workstationTypeId: string, direction: OrthonormalVector | undefined, instanceSequence: IWorkstationInstance[], position?: Point): Promise<IWorkstationInstance | null> {

        let newPosition = position ?? null;

        if (newPosition == null && direction){

            newPosition = this.calculateNextInstancePosition(referenceInstanceId, direction, instanceSequence);
        }

        if (newPosition != null) {

            const previousInstanceId = instanceSequence.at(-1)?.id;

            //new instance is appeneded
            const newInstance = await this._workstationInstanceService.build(newPosition.x,
                newPosition.y,
                newPosition.z,
                previousInstanceId,
                workstationTypeId);

            return newInstance;

        }
        //throw?
        return null;
    }

    private DELTA = 2.0;

    calculateNextInstancePosition(referenceInstanceId: string, direction: OrthonormalVector, allInstances: IWorkstationInstance[]): { x: number; y: number; z: number } | null {

        const refrerenceInstance = allInstances.find(x => x.id === referenceInstanceId);

        if (refrerenceInstance) {

            const referencePosition = { x: refrerenceInstance.insertPointX, y: refrerenceInstance.insertPointY, z: refrerenceInstance.insertPointZ };

            const newPosition = {

                x: referencePosition.x + (direction.x * this.DELTA),
                y: 0,
                z: referencePosition.z + (direction.z * this.DELTA)

            }

            return newPosition;
        }

        return null;

    }



}

injected(ProductionLineService, DI_TOKENS.IWorkstationInstanceService.Value);
import { SalesProcessHeader } from "../../shared/headers/SalesProcessHeader";
import TICK from "assets/tick.png"
import { Button, Container } from "@mui/material";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../app-routes/routeConfig";

const OrderSuccess = () => {
  const navigate = useNavigate();

  return (
    <div className="container-new">
      <SalesProcessHeader />
      <Container maxWidth="sm">
        <div className="success-confirmation">
          <img src={TICK} alt="tick" />
          <h5>Proposal Sent</h5>
          <p>Confirmation Email Successfully Delivered to Customer's Inbox</p>
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            endIcon={<ArrowForward />}
            className="continue-btn-new"
            onClick={() => navigate(ROUTES.WELCOME_SCREEN.path)}
          >
            Continue to dashboard
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default OrderSuccess;

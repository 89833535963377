import { styled } from "@mui/material";
import {
  OrbitControls,
  OrthographicCamera,
  Plane,
  useBounds,
  useContextBridge,
} from "@react-three/drei";
import { Canvas, useFrame, useLoader, useThree } from "@react-three/fiber";
import { Suspense, useContext, useEffect, useRef, useState } from "react";
import * as THREE from "three";

import WorkstationInstance from "./components/WorkstationInstance";
import { AppContext, useAppContext } from "../../app/context";
import { FactoryViewportContext } from "./FactoryViewportContext";
import { IFactoryViewportContext } from "./context";
import OrthonormalVector from "../../../common/math/OrthonormalVector";
import { getOrthoVectorBetween } from "./helpers/getOrthoVectorBetween";
import { getWorkstationPosition } from "./helpers/getWorkstationPosition";
import { useNavigate } from "react-router-dom";
import { ROUTES, DESIGN_FRAGMENTS } from "../../app-routes/routeConfig";
import { Vector3 } from "three";

import { DraggableObject } from "./components/DraggableObject";
import { generateUUID } from "three/src/math/MathUtils";
import { CameraControlsManager } from "./components/CameraControlsManager";
import type { OrbitControls as OrbitControlsImpl } from "three-stdlib";
import { snapToGrid } from "./helpers/snapToGrid";
import Header from "../../shared/headers/SalesProcessHeader";
import SalesProcessFooter from "../../shared/Footer";
import { IPartInstance } from "../../../application/models/IPartInstance";
import { DivBox } from "ui/library/Layout/DivBox";

export const TooltipControl = styled("div")(() => ({
  backgroundColor: "#0074A7",
  userSelect: "none",
  color: "white",
  borderRadius: "0.5rem",
  padding: "0 0.3rem 0.2rem",
}));

function InstancedThing() {
  const ref = useRef<THREE.InstancedMesh>(null!);
  useEffect(() => {
    ref.current.setMatrixAt(0, new THREE.Matrix4());
  }, []);
  return (
    <instancedMesh ref={ref} args={[undefined, undefined, 1]}>
      <boxBufferGeometry args={[1, 1, 1]} />
      <meshNormalMaterial />
    </instancedMesh>
  );
}

const Content = (props: {
  children: JSX.Element | JSX.Element[];
  modelGroupRef: React.RefObject<THREE.Group>;
}) => {
  const rotStep = 0; // 0.001;

  const ref = props.modelGroupRef;

  useFrame(
    () =>
      (ref.current!.rotation.x =
        ref.current!.rotation.y =
        ref.current!.rotation.z +=
          rotStep)
  );
  return <group ref={ref}>{props.children}</group>;
};

export function FactoryViewport(props: {
  set2DViewRef: React.MutableRefObject<(() => void) | undefined>;
  getScreenshotRef: React.MutableRefObject<
    (() => { imgUrl: string }) | undefined
  >;
}) {
  const navigate = useNavigate();

  const onConnectorClick = (
    workstationInstanceId: string,
    connectorDirection: OrthonormalVector
  ) => {
    navigate(
      DESIGN_FRAGMENTS.ADD_WORKSTATION_INSTANCE.BY_CONNECTOR.buildUrl({
        previd: workstationInstanceId,
      }),
      {
        state:
          DESIGN_FRAGMENTS.ADD_WORKSTATION_INSTANCE.BY_CONNECTOR.buildState({
            dirX: connectorDirection.x,
            dirY: connectorDirection.y,
            dirZ: connectorDirection.z,
          }),
      }
    );
  };

  return (
    <DivBox sx={{ display: "flex", width: "100%", height: "100%" }}>
      <SceneWrapper>
        <Viewport3D
          key={"viewport-3d"}
          set2DViewRef={props.set2DViewRef}
          getScreenshotRef={props.getScreenshotRef}
        />
      </SceneWrapper>
    </DivBox>
  );
}

function Viewport3D(props: {
  set2DViewRef: React.MutableRefObject<(() => void) | undefined>;
  getScreenshotRef: React.MutableRefObject<
    (() => { imgUrl: string }) | undefined
  >;
}) {
  const parentContext = useAppContext();

  console.log("viewport 3D rerender");

  const {
    getSortedWorkstationInstances,
    updateSelectedWorkstationInstanceId,
    updateFocusedWorkstationInstanceId,
    addWorkstationInstance,
    repositionWorkstationInstance,
    setSelectedConnector,
  } = parentContext.workstation.dispatch;
  const { selectedWorkstationInstanceId, focusedWorkstationId } =
    parentContext.workstation.state;
  const { dragAddedEquipmentTypeId } = parentContext.equipmentDefinition.state; //dragAddedEquipmentTypeId

  const [cameraFocusPoint, setCameraFocusPoint] = useState<{
    x: number;
    y: number;
    z: number;
  }>();

  const [cameraPosition, setCameraPosition] = useState<{
    x: number;
    y: number;
    z: number;
  }>();

  const [draggedInEquipmentId, setDraggedInEquipmentId] = useState<
    string | null
  >(null);

  const { gl, scene, camera } = useThree();

  const getScreenshot = () => {
    gl.render(scene, camera);
    const screenshotUrl = gl.domElement.toDataURL("image/jpeg", 0.7);
    return { imgUrl: screenshotUrl };
  };

  props.getScreenshotRef.current = getScreenshot;

  function getModelBounds() {
    const box = new THREE.Box3();
    groupRef?.current?.children.forEach((c) => box.expandByObject(c));
    return box;
  }

  const ctx: IFactoryViewportContext = {
    onWorkstationInstanceSelected: (id: string) => {
      updateSelectedWorkstationInstanceId!(id);
    },
    selectedWorkstationInstanceId: selectedWorkstationInstanceId,
    isEquipmentDragAdditionInProgress: dragAddedEquipmentTypeId != null,
    dragAddedEquipmentTypeId: dragAddedEquipmentTypeId,
    onWorkstationInstanceFocused: updateFocusedWorkstationInstanceId,
    focusedWorkstationInstanceId: focusedWorkstationId ?? null,
    getModelBounds: getModelBounds,
  };

  useEffect(() => {
    const selectedInstance = getSortedWorkstationInstances().find(
      (x) => x.id === focusedWorkstationId
    );

    if (selectedInstance) {
      setCameraFocusPoint({
        x: selectedInstance.insertPointX,
        y: selectedInstance.insertPointY,
        z: selectedInstance.insertPointZ,
      });

      setCameraPosition(undefined);
    }
  }, [focusedWorkstationId]);

  const bounds = useBounds();

  const groupRef = useRef<THREE.Group>(null);

  useEffect(() => {
    const handle2DView = () => {
      if (controls.current && camera && scene && groupRef.current) {
        const box = getModelBounds();

        let center = new Vector3();
        box.getCenter(center);

        setCameraPosition({ x: center.x, y: camera.position.y, z: center.z });
        setCameraFocusPoint({ x: center.x, y: 0, z: center.z });
      }
    };
    props.set2DViewRef.current = handle2DView;
  }, [
    setCameraFocusPoint,
    setCameraPosition,
    props.set2DViewRef,
    bounds,
    camera,
    groupRef,
  ]);

  const sortedWorkstations: {
    modelUrl: string;
    id: string;
    workstationTypeId: string;
    assembly: IPartInstance[];
    insertPointX: number;
    insertPointY: number;
    insertPointZ: number;
    rotationRad: number;
    previousInstanceId: string | undefined;
  }[] = getSortedWorkstationInstances();

  const camVector = cameraFocusPoint
    ? new Vector3(cameraFocusPoint.x, cameraFocusPoint.y, cameraFocusPoint.z)
    : undefined;

  const sequence = sortedWorkstations;

  const [tempPt, setTempPt] = useState<{ x: number; z: number } | null>(null);

  //hack to avoid double adddition of instance
  const isAsyncEquipmentAdditionInProgress = useRef(false);

  const handleDragIntoCanvas = (x: number, z: number) => {
    const commit = () => {
      setTempPt({ x, z });

      if (!ctx.isEquipmentDragAdditionInProgress) {
        return;
      }

      if (
        draggedInEquipmentId == null &&
        !isAsyncEquipmentAdditionInProgress.current
      ) {
        if (dragAddedEquipmentTypeId == null) {
          throw new Error("equipment definition not selected when dragging!");
        }

        isAsyncEquipmentAdditionInProgress.current = true;
        addWorkstationInstance!(dragAddedEquipmentTypeId, {
          x: x,
          y: 0,
          z: z,
        }).then((result) => {
          if (result.workstationId) {
            console.log(
              `dragging in ${result.workstationId} at ${tempPt?.x ?? x} ${
                tempPt?.z ?? z
              }`
            );
            setDraggedInEquipmentId(result.workstationId);
          }
        });
      } else {
        setTempPt({ x, z });
      }
    };

    commit();
  };

  useEffect(() => {
    if (draggedInEquipmentId && tempPt != null) {
      repositionWorkstationInstance(tempPt.x, tempPt.z, draggedInEquipmentId);
    }

    if (!ctx.isEquipmentDragAdditionInProgress) {
      isAsyncEquipmentAdditionInProgress.current = false;
      setDraggedInEquipmentId(null);
      setTempPt(null);
    }
  }, [ctx.isEquipmentDragAdditionInProgress]);

  const [isDragging, setIsDragging] = useState(false);
  const floorPlane = new THREE.Plane(new THREE.Vector3(0, 1, 0), 0);

  const controls = useRef<OrbitControlsImpl>(null);

  const onConnectorClick = (
    workstationId: string,
    direction: OrthonormalVector
  ) => {
    setSelectedConnector({ workstationId, direction });
  };

  return (
    <FactoryViewportContext.Provider value={ctx}>
      {/* <CameraControlsManager focus={cameraFocusPoint} /> */}
      <axesHelper args={[2]} />
      <pointLight color="grey" />
      <pointLight position={[30, 50, -10]} color="0x404040" />
      <pointLight position={[-30, -100, 10]} color="#b01116" />
      <ambientLight intensity={0.3} color="0x404040" />
      <DragTarget
        isDraggingActive={ctx.isEquipmentDragAdditionInProgress}
        handleDragOnPlane={(x, y, z) => {
          handleDragIntoCanvas(x, z);
        }}
        targetPlane={floorPlane}
        obstructingElementRef={parentContext.uiRefs.workPanelOverlayRef}
      />

      <Content
        key="b3b8ca77-de21-4c88-a4e5-7ed9715ac64c"
        modelGroupRef={groupRef}
      >
        {sortedWorkstations.map((current, index) => {
          const prevIndex = index - 1;
          const nextIndex = index + 1;

          const prevInstance =
            prevIndex >= 0 ? sortedWorkstations.at(prevIndex) : undefined;
          const nextInstance =
            nextIndex >= 0 ? sortedWorkstations.at(nextIndex) : undefined;

          const modelUrl = current.modelUrl;

          let instancePt = {
            x: current.insertPointX,
            z: current.insertPointZ,
          };

          const isPosCacheOverride =
            !isDragging && current.id === draggedInEquipmentId;

          if (isPosCacheOverride && tempPt != null) {
            instancePt = { x: tempPt.x, z: tempPt.z };
          }

          return (
            <DraggableObject
              key={current.id}
              floorPlane={floorPlane}
              setIsDragging={(val) => setIsDragging(val)}
              position={[instancePt.x, current.insertPointY, instancePt.z]}
              onDragPositionChange={(pos) => {
                if (!isObstructed(parentContext.uiRefs.workPanelOverlayRef))
                  repositionWorkstationInstance(pos[0], pos[2], current.id);
              }}
              isPositionCacheOverride={!isDragging}
              rotation={[0, current.rotationRad, 0]}
              handleClick={() => ctx.onWorkstationInstanceSelected!(current.id)}
              handleDoubleClick={() => {
                ctx.onWorkstationInstanceSelected!(current.id);
                ctx.onWorkstationInstanceFocused!(current.id);
              }}
            >
              <WorkstationInstance
                key={current.id}
                visibilityMap={current.assembly.map((a) => {
                  return {
                    isIncluded: a.isIncluded,
                    nodeId: a.nodeName,
                  };
                })}
                modelUrl={modelUrl}
                directionToNextWorkstation={null}
                directionToPreviousWorkstation={null}
                uuid={current.id}
                onConnectorClick={onConnectorClick}
              />
            </DraggableObject>
          );
        })}
      </Content>
      <gridHelper
        args={[64, 64, "#8e8e8e", "rgba(142, 142, 142, 0.092)"]}
        position={[-0, 0, 0]}
        rotation={[0, Math.PI / 2, 0]}
      />

      <Suspense fallback={null}>
        <Floor />
      </Suspense>

      <OrbitControls
        enableDamping={false}
        ref={controls}
        minZoom={10}
        maxZoom={200}
        enabled={!(isDragging || dragAddedEquipmentTypeId)}
      />

      {/* {target && <TransformControls object={target}   />} */}
      <CameraControlsManager
        controls={controls}
        focusPoint={cameraFocusPoint}
        position={cameraPosition}
      />
      {/* <OrbitControls makeDefault target={camVector} /> */}

      <OrthographicCamera makeDefault zoom={50} position={[0, 40, 200]} />
    </FactoryViewportContext.Provider>
  );
}

function SceneWrapper({ ...props }) {
  // bridge any number of contexts
  const ContextBridge = useContextBridge(AppContext);

  return (
    <Canvas gl={{ antialias: true }} key={"main-3d-canvas"} dpr={0.75}>
      <ContextBridge>{props.children}</ContextBridge>
    </Canvas>
  );
}

const DragTarget = (props: {
  isDraggingActive: boolean;
  handleDragOnPlane: (x: number, y: number, z: number) => void;
  targetPlane: THREE.Plane;
  obstructingElementRef?: React.MutableRefObject<HTMLDivElement | null>; //for example a menu floating over the canvas
}) => {
  useFrame(({ camera, raycaster, size }) => {
    if (props.isDraggingActive) {
      //we are doing this calculation based on stored cursor value, because the mouse position updates via r3f are unreliable when dragging
      const handleDraggingAddition = () => {
        const absolutePointerX = (window as any).actualPointerX - size.left;
        const absolutePointerY = (window as any).actualPointerY - size.top;

        const nx = (absolutePointerX / size.width) * 2 - 1;
        const ny = (-absolutePointerY / size.height) * 2 + 1;

        const isXOutside = nx < -1 || nx > 1;
        const isYOutside = ny < -1 || nx > 1;

        const isCursorOutside = isXOutside || isYOutside;

        if (isCursorOutside || isObstructed(props.obstructingElementRef))
          return;

        raycaster.setFromCamera({ x: nx, y: ny }, camera);

        let planeIntersectPoint = new THREE.Vector3();
        const hasResult = raycaster.ray.intersectPlane(
          props.targetPlane,
          planeIntersectPoint
        );

        planeIntersectPoint = snapToGrid(planeIntersectPoint);

        if (hasResult) {
          props.handleDragOnPlane(
            planeIntersectPoint.x,
            planeIntersectPoint.y,
            planeIntersectPoint.z
          );
        }
      };

      handleDraggingAddition();
    }
  });

  return <></>;
};

const Floor = () => {
  const height = useLoader(
    THREE.TextureLoader,
    "/textures/DiamondPlate001_1K_Displacement.png"
  );

  const normals = useLoader(
    THREE.TextureLoader,
    "/textures/DiamondPlate001_1K_NormalGL.png"
  );

  const colors = useLoader(
    THREE.TextureLoader,
    "/textures/DiamondPlate001_1K_Color.png"
  );

  const metalness = useLoader(
    THREE.TextureLoader,
    "/textures/DiamondPlate001_1K_Metalness.png"
  );

  const textures = [height, normals, colors];

  textures.forEach((t) => {
    t.repeat.set(32, 32);
    t.wrapS = THREE.RepeatWrapping;
    t.wrapT = THREE.RepeatWrapping;
  });

  return (
    <group>
      <Plane
        rotation={[-Math.PI / 2, 0, 0]}
        position={[0, -0.01, 0]}
        args={[64, 64, 2, 2]}
      >
        <Suspense
          fallback={<meshStandardMaterial attach="material" color="#99c7db" />}
        >
          <meshStandardMaterial
            attach="material"
            color="#99c7db"
            normalMap={normals}
            displacementMap={height}
            displacementScale={0.01}
          />
        </Suspense>
      </Plane>
    </group>
  );
};

const isObstructed = (
  obstructingElementRef?: React.MutableRefObject<HTMLDivElement | null>
) => {
  const obstructingElement = obstructingElementRef?.current;
  if (obstructingElement) {
    const x = (window as any).actualPointerX;
    const y = (window as any).actualPointerY;
    const isHInside =
      obstructingElement.clientLeft <= x &&
      obstructingElement.clientLeft + obstructingElement.clientWidth >= x;
    const isVInside =
      obstructingElement.clientTop <= y &&
      obstructingElement.clientTop + obstructingElement.clientHeight >= y;

    return isHInside && isVInside;
  }
  return false;
};


export function splitAndCapitalizeCamelCase(input: string): string {
  const camelCase = input.replace(/([a-z])([A-Z])/g, "$1 $2").split(" ");

  let result = "";

  camelCase.forEach((word) => {
    result = result + word.charAt(0).toUpperCase() + word.slice(1) + " ";
  });

  return result;
}

import { IConfigurationState } from "application/models/IConfigurationState";
import { Price } from "../../../../common/models/price";
import { IWorkstationInstanceState } from "../workstationInstance/slice";

export function buildConfigurationState(
  equipmentsState: IWorkstationInstanceState,
  name: string,
  totalPrice: Price | undefined,
  screenshotUrl?: string
) {
  const { workstationInstances: equipments } = equipmentsState;
  totalPrice = totalPrice ?? {
    amount: 0,
    currencySymbol: "USD",
  };

  let configurationState: IConfigurationState = {
    name,
    equipments,
    totalPrice,
    screenshotUrl,
  };

  return configurationState;
}

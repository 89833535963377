import { IWorkstationInstanceSlice } from "../workstationInstance/slice";
import {
  IPredefinedLayoutDispatch,
  IPredefinedLayoutSlice,
  IPredefinedLayoutState,
} from "./slice";
import { IOrderSlice } from "../orders/orderSlice";
import { ConfigurationTemplateDetails } from "application/models/configurationTemplateDetails";
import { generateUUID } from "three/src/math/MathUtils";
import { IQuotingSlice } from "../quoting/slice";
import { StrictOmit } from "common/types/strictOmit";
import { IConfigurationService } from "application/services/ConfigurationService";
import { User } from "ui/auth/User";
import { ConfigurationTemplateDetailsWithId } from "application/models/ConfigurationTemplateDetailsWithId";
import { ConfigurationTemplateAddUpdateModel } from "../../../models/configurationTemplateAddUpdateModel";

export function useConfigurationTemplateReducer(
  user: User | undefined,
  {
    configurationService,
  }: {
    configurationService: IConfigurationService;
  },
  configurationTemplateSlice: IPredefinedLayoutSlice,
  configurationSlice: IWorkstationInstanceSlice,
  orderSlice: IOrderSlice,
  quotingSlice: IQuotingSlice
): IPredefinedLayoutReducer {
  const { configurationTemplates, currentTemplate } =
    configurationTemplateSlice.state;
  const { workstationInstances } =
    configurationSlice.state;

  const { productionLineTotalPrice } = quotingSlice.state;

  const { loadConfiguration, clearConfiguration } = configurationSlice.dispatch;
  const { setCurrentTemplate } = configurationTemplateSlice.dispatch;

  function loadConfigurationTemplate(id: string) {
    (async () => {
      const configurationTemplate = configurationTemplates.find((x) => x.id === id);

      if (configurationTemplate && user) {
        loadConfiguration(configurationTemplate.configurationId, true);
        setCurrentTemplate({
          ...configurationTemplate,
          id,
        });
      }
    })();
  }

  function startSaleFromTemplate(id: string) {
    loadConfigurationTemplate(id);
  }

  function buildConfigurationTemplate(
    id: string,
    details: ConfigurationTemplateDetails
  ) {

    if (!details.name){
      throw new Error("template name is required");
    }

    if (!details.type){
      throw new Error("template type is required");
    }

    const item: ConfigurationTemplateAddUpdateModel = {
      ...details,
      name: details.name,
      type: details.type,
      id,
      configuration: {
        name: details.name,
        currencySymbol: productionLineTotalPrice?.currencySymbol ?? "USD",
        totalAmount: productionLineTotalPrice?.amount ?? 0,
        equipments: workstationInstances,
        updatedTS: new Date(),
        createdTS: new Date(),
      },
      imgUrl: "",
    };

    return item;
  }

  function createTemplate(details: ConfigurationTemplateDetails) {
    const template = buildConfigurationTemplate(generateUUID(), details);
    configurationTemplateSlice.dispatch.createTemplate(template);
    setCurrentTemplate(undefined);
  }

  function updateTemplate(details: ConfigurationTemplateDetails) {
    const id = currentTemplate?.id;

    if (id == null) {
      throw new Error("Error while editing configuration template");
    }

    const template = buildConfigurationTemplate(id, details);
    configurationTemplateSlice.dispatch.updateTemplate(template);
    setCurrentTemplate(undefined);
  }

  return {
    state: configurationTemplateSlice.state,
    dispatch: {
      ...configurationTemplateSlice.dispatch,
      loadConfigurationTemplate,
      startSaleFromTemplate,
      createTemplate,
      updateTemplate,
      clearTemplate: () => {
        const item: ConfigurationTemplateDetailsWithId = {
          id: generateUUID()
        }
        setCurrentTemplate(item);
        clearConfiguration()
        
      }
    },
  };
}

interface IExtraActions {
  loadConfigurationTemplate(id: string): void;
  startSaleFromTemplate(id: string): void;
  createTemplate(details: ConfigurationTemplateDetails): void;
  updateTemplate(details: ConfigurationTemplateDetails): void;
  clearTemplate(): void
}

export interface IPredefinedLayoutReducer {
  dispatch: StrictOmit<
    IPredefinedLayoutDispatch,
    "createTemplate" | "updateTemplate"
  > &
    IExtraActions;
  state: IPredefinedLayoutState;
}

import { useLocation } from "react-router-dom";
import { matchPath } from "react-router";

/** note: this contains an override of a react router bug - see the private function */
export function useParentMatch(route: { path: string }) {
  const { pathname } = useLocation();

  return match(pathname, route);
}

/** note: this contains an override of a react router bug - see the private function */
export function useParentMatchFunc() {
  const { pathname } = useLocation();

  return (route: { path: string }) => match(pathname, route);
}

function match(pathname: string, route: { path: string }) {
  const isParent = matchWithOptionalParams(pathname, `${route.path}/*`);
  const isDirect = matchWithOptionalParams(pathname, `${route.path}`);

  return isParent || isDirect;
}

/**
 * react router bug: it doesnt match when optional params i.e. stuff/param? (the question mark) are present
 * this has been resolved in future version of the package 6.18.0-pre.0
 * the following workaround that uses manual replacement
 * comes from here: https://github.com/remix-run/react-router/discussions/9862#discussioncomment-6412034
 */
const matchWithOptionalParams = (path: string, routeToMatch: string) => {
  const pathPattern = routeToMatch.replaceAll(/:(.*?)\?/gi, "*");
  return matchPath(pathPattern, path);
};

import { useCursor } from "@react-three/drei";
import { ThreeEvent, useFrame } from "@react-three/fiber";
import { Suspense, useEffect, useRef, useState } from "react";
import * as THREE from 'three';
import { useDrag } from "@use-gesture/react";
import { Vector3 } from "three";
import { LoadingCube } from "./LoadingCube";
import { snapToGrid } from "../helpers/snapToGrid";

export function DraggableObject(props: {
    setIsDragging: (val: boolean) => void;
    floorPlane: THREE.Plane;
    position: [number, number, number];
    rotation: [number, number, number]
    onDragPositionChange: (pos: [number, number, number]) => void;
    children: React.ReactNode;
    isPositionCacheOverride: boolean,
    handleClick: () => void,
    handleDoubleClick: () => void
}) {

    const isClickDisabled = useRef(false);
    const isDragging = useRef(false)

    const { setIsDragging, floorPlane, position, onDragPositionChange, children, isPositionCacheOverride, rotation, handleClick, handleDoubleClick } = props;

    const [hovered, setHovered] = useState(false);
    useCursor(hovered);

    let planeIntersectPoint = new THREE.Vector3();

    const [tmpPt, setTmpPt] = useState<{ x: number, z: number }>();

    useEffect(() => {

        setTmpPt({ x: position[0], z: position[2] })

    }, [isPositionCacheOverride, position])

    const floorOffset = useRef({x: 0, z: 0})

    const bind: any = useDrag((state) => { //TODO: any is a workaround

        const { active, timeStamp, tap } = state;

        const wasDragging = isDragging.current;

        isDragging.current = active;

        const event = state.event as unknown as ThreeEvent<PointerEvent> | ThreeEvent<MouseEvent> | ThreeEvent<DragEvent>;

        setIsDragging(active);

        if (isDragging.current && !wasDragging){
            const floorPt = event.ray.intersectPlane(floorPlane, planeIntersectPoint);

            if (floorPt){

                const offsetX = floorPt.x - pos[0];
                const offsetZ = floorPt.z - pos[2];

                //we are calculating the floor offset because the equipment models have some y height - therefore floor hitpoing might be misaligned
                floorOffset.current = {x: offsetX, z: offsetZ}
            }
            
        }

        if (tap) {
            isClickDisabled.current = false;
            return timeStamp;
        }

        if (active) {
            const hasPoint = event.ray.intersectPlane(floorPlane, planeIntersectPoint);

            if (hasPoint) {
                planeIntersectPoint.x -= floorOffset.current.x;
                planeIntersectPoint.z -= floorOffset.current.z;

                planeIntersectPoint = snapToGrid(planeIntersectPoint);
                setTmpPt(planeIntersectPoint)
            }

        }
        else {
            if (tmpPt)
                onDragPositionChange([tmpPt.x, 0, tmpPt.z]);
        }

        if (active) {
            isClickDisabled.current = true;
        }

        return timeStamp;
    },
        {
            delay: 0
        })

    let pos = isPositionCacheOverride || !tmpPt ? [position[0], 0, position[2]] : [tmpPt!.x, 0, tmpPt!.z];

    const childGroupRef = useRef<THREE.Mesh>();
    const containerRef = useRef<THREE.Mesh>();

    const debouncedHandleClick = (handler: () => void) => {
        const wasDragging = isClickDisabled.current;

        isClickDisabled.current = false;

        if (!wasDragging) {
            handler()
        }
    }

    if (containerRef.current) {
        containerRef.current.position.x = pos[0];
        containerRef.current.position.y = pos[1];
        containerRef.current.position.z = pos[2];
    }

    const [hitBoxSize, setHitboxSize] = useState<Vector3 | null>(null)

    useFrame(() => {
        if (!hitBoxSize) {

            let modelBbox = new THREE.Box3();

            if (childGroupRef.current) {
                modelBbox.setFromObject(childGroupRef.current);
                const _size = new Vector3();
                modelBbox.getSize(_size);
                setHitboxSize(_size);
            }


        }
    })





    return (

        <mesh ref={containerRef as any} castShadow>

            <Suspense fallback={<LoadingCube position={new Vector3(0, 0, 0)} />}>


                {hitBoxSize != null && <mesh {...bind()} onDoubleClick={() => debouncedHandleClick(handleDoubleClick)} onClick={() => debouncedHandleClick(handleClick)} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}
                    position={[0, pos[1] + hitBoxSize.y / 2, 0]}>

                    <boxBufferGeometry args={[hitBoxSize.x, hitBoxSize.y, hitBoxSize.z]} />
                    <meshPhongMaterial color="#ffffff" opacity={0.0} transparent />
                </mesh>}


                <mesh rotation={rotation} ref={childGroupRef as any}>
                    {children}
                </mesh>

            </Suspense>

        </mesh>


    );
}


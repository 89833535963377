import { useEffect, useState } from "react";
import { IProduct } from "../../../../application/models/IProduct";
import { IProductService } from "../../../../application/services/ProductService";
import { User } from "../../../auth/User";
import { TableRequest } from "../../../../infrastructure/paging/paging";
import { IFilteredListResult } from "../pagination/pagination";

export function useProductSlice(
  user: User | undefined,
  services: { productService: IProductService }
): IProductSlice {
  const { productService } = services;

  //products
  const [pending, setPending] = useState(true);
  const [items, setItems] = useState<IProduct[]>([]);

  const deleteItem = async function (id: string): Promise<void> {
    await productService.delete(id);
  };

  const fetch = () => {
    setPending(true);
  };

  const update = async function (products: IProduct[]): Promise<void> {
    await productService.updateList(products);
    setPending(true);
  };

  useEffect(() => {
    if (user && pending) {
      productService.getAll().then((fetched) => {
        setItems(fetched);
        setPending(false);
      });
    }
  }, [pending, user]);

  return {
    state: {
      items,
      pending,
    },
    dispatch: {
      fetch,
      deleteItem,
      update,
      getPage: (request) => productService.getList(request),
    },
  };
}

export interface IProductState {
  pending: boolean;
  items: IProduct[];
}

export interface IProductDispatch {
  deleteItem: (id: string) => Promise<void>;
  fetch: () => void;
  update: (products: IProduct[]) => Promise<void>;
  getPage: (
    request: TableRequest<IProduct>
  ) => Promise<IFilteredListResult<IProduct>>;
}

export interface IProductSlice {
  dispatch: IProductDispatch;
  state: IProductState;
}

import { Grid, Typography } from "@mui/material";
import { ReactNode } from "react";


export interface BaseHeaderProps {
  title: string;
  subtitle?: string;
}

export const BaseHeader = ({
  title, subtitle, children,
}: BaseHeaderProps & {
  children?: ReactNode;
}) => {
  return (
    <div className="sale-header">
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item>
          <Typography component={"h3"} id="sale-heading">{title}</Typography>
          {subtitle && (
            <Typography
              paragraph
              sx={{ marginBottom: 0, color: "#475467", fontSize: "1rem" }}
            >
              {subtitle}
            </Typography>
          )}
        </Grid>
        <Grid item>{children}</Grid>
      </Grid>
    </div>
  );
};

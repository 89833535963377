import { FormLabel, Grid, SxProps, Theme } from "@mui/material";
import { OptionalRecord } from "../../../common/types/OptionalRecord";
import { buildFieldState } from "./fieldMeta/buildFieldState";
import { RenderableFieldMeta } from "./fieldMeta/RenderableFieldMeta";
import { ExtractKeys } from "../../../common/types/ExtractKeys";
import { FormikProps } from "formik";
import { isContainerDefinition } from "./isContainerDefinition";
import { isFieldDefinition } from "./isFieldDefinition";
import { FieldMeta } from "./fieldMeta/fieldMeta";
import { metaHasKey } from "./metaHasKey";
import { SingleField } from "./components/SingleField";

/**Will render multiple standard fields as MUI <Grid /> items, based on 'fieldMetas'. Expects the parent to be a MUI <Grid>*/
export function Fields<
  TValues extends OptionalRecord<ExtractKeys<TValues>, any> & {
    id?: string;
  }
>({
  fieldMetas,
  fieldContainerSx,
  formik,
}: {
  fieldMetas: FieldMeta<TValues>[];
  fieldContainerSx?: SxProps<Theme> | undefined;
  formik: FormikProps<Partial<TValues>>;
}) {
  return (
    <>
      {fieldMetas.map((fieldMeta) => {
        //here we traverse through field metas and we will render appropriate field component
        const fieldState = metaHasKey(fieldMeta)
          ? buildFieldState<TValues>(fieldMeta.key as string, formik)
          : undefined;

        if (fieldMeta instanceof RenderableFieldMeta) {
          if (!fieldState) {
            throw new Error("keyed form field has to have FormState");
          }
          const renderedCell = fieldMeta.renderCell(fieldState);
          return (
            <Grid
              sx={{ ...fieldContainerSx }}
              className="standardForm-fieldContainer"
              item
              xs={12}
              display={"flex"}
            >
              {renderedCell}
            </Grid>
          );
        } else if (isFieldDefinition(fieldMeta)) {
          if (!fieldState) {
            throw new Error("keyed form field has to have FormState");
          }
          return (
            <Grid
              sx={{ ...fieldContainerSx }}
              className="standardForm-fieldContainer"
              item
              xs={12}
              display={fieldMeta.label ? "" : "flex"}
            >
              {fieldMeta.label && <FormLabel>{fieldMeta.label}</FormLabel>}
              <SingleField
                label={fieldMeta.label}
                fieldMeta={fieldMeta}
                fieldState={fieldState}
              />
            </Grid>
          );
        } else if (isContainerDefinition(fieldMeta)) {
          //fields can be nested within container
          const containedFields = fieldMeta.fields;

          if (
            fieldMeta.container === "column" ||
            fieldMeta.container === "row"
          ) {
            return (
              <Grid
                direction={fieldMeta.container}
                sx={{ ...fieldContainerSx }}
                className="standardForm-fieldContainer"
                item
                xs={fieldMeta.containerGridSize ?? 12}
                display={"flex"}
              >
                {fieldMeta.label && <FormLabel>{fieldMeta.label}</FormLabel>}
                <Fields
                  fieldMetas={fieldMeta.fields}
                  fieldContainerSx={fieldContainerSx}
                  formik={formik}
                />
              </Grid>
            );
          } else {
            const ContainerComponent = fieldMeta.container;

            //here we recursively render the nested fields
            return (
              <ContainerComponent>
                <Fields
                  fieldMetas={containedFields}
                  fieldContainerSx={fieldContainerSx}
                  formik={formik}
                />
              </ContainerComponent>
            );
          }
        }

        return <></>;
      })}
    </>
  );
}

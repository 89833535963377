import { injected } from "brandi";
import { count } from "console";
import DI_TOKENS from "../../DI_TOKENS";
import { IEquipmentBomItem, IProductBomItem } from "../models/IBomItem";
import { IWorkstationInstance } from "../models/IWorkstationInstance";
import { IPriceCalculationService } from "./PriceCalculationService";
import { IProductService } from "./ProductService";
import { IWorkstationTypeService } from "./WorkstationTypeService";
import { groupBy } from "common/collection/groupBy";

export interface ICheckoutService {
  getProductBom(
    workstations: IWorkstationInstance[]
  ): Promise<IProductBomItem[]>;

  getEquipmentBoM(workstations: IWorkstationInstance[]): Promise<IEquipmentBomItem[]>;
}

export class CheckoutService implements ICheckoutService {
  private _priceCalculationService: IPriceCalculationService;
  private _workstationTypeService: IWorkstationTypeService;
  private _productService: IProductService;

  constructor(
    priceCalculationService: IPriceCalculationService,
    workstationTypeService: IWorkstationTypeService,
    productService: IProductService
  ) {
    this._priceCalculationService = priceCalculationService;
    this._workstationTypeService = workstationTypeService;
    this._productService = productService;
  }

  async getProductBom(
    workstations: IWorkstationInstance[]
  ): Promise<IProductBomItem[]> {
    const result: IProductBomItem[] = [];

    const wsTypes = await this._workstationTypeService.GetAll();

    let allProductIds = workstations
      .flatMap((ws) => {
        const wsType = wsTypes.find((x) => x.id == ws.workstationTypeId);

        const includedProducts = ws.assembly.map((c) => {
          const partDef = wsType?.components?.find(
            (cd) => cd.id == c.definitionId
          );
          return {
            productId: partDef?.productId,
            isIncluded: c.isIncluded,
          };
        });

        return includedProducts;
      })
      .filter((x) => x.isIncluded && x.productId != null)
      .map((x) => x.productId!);

    workstations.forEach((ws) => {
      const wsType = wsTypes.find((x) => x.id == ws.workstationTypeId);

      if (wsType) {
        allProductIds = allProductIds.concat(
          wsType.components
            .filter((c) => !c.isConfigurable)
            .map((c) => c.productId)
        );
      }
    });

    const distinctIds = Array.from(new Set(allProductIds));

    const countMap = distinctIds.map((productId) => {
      const count = allProductIds.filter((x) => x === productId).length;

      return {
        productId,
        count,
      };
    });

    for (const { count, productId } of countMap) {
      const product = await this._productService.get(productId);

      const unitPrice = await this._priceCalculationService.getProductPrice(
        productId
      );

      if (unitPrice != null) {
        const totalPrice = unitPrice.amount * count;

        result.push({
          productId: productId,
          quantity: count,
          sku: product!.sku,
          title: product!.name,
          total: totalPrice,
          unitPrice: unitPrice.amount,
          currencySymbol: unitPrice.currencySymbol,
        });
      }
    }

    return result;
  }

  async getEquipmentBoM(
    workstations: IWorkstationInstance[]
  ) {
    const equipmentTypes = await this._workstationTypeService.GetAll();

    const promises = workstations.map(async (ws) => {
      const wsType = equipmentTypes.find((x) => x.id == ws.workstationTypeId);
      if (wsType) {
        return {
          equipmentId: wsType.id,
          equipmentName: wsType.name,
          price:
            await this._priceCalculationService.getWorkstationInstancePrice(ws),
        };
      }
    });

    const pricedEquipments = (await Promise.all(promises))
      .filter((x) => x != null)
      .map((x) => x!);

    const groups = groupBy(pricedEquipments, (x) => x.equipmentId);

    const lineItems = groups.map((g) => {
      const title = g.items[0].equipmentName;
      const totalAmount = g.items.reduce(
        (sum, item) => sum + item.price.amount,
        0
      );
      const quantity = g.items.length;

      const lineItem: IEquipmentBomItem = {
        currencySymbol: "USD",
        equipmentId: g.key,
        quantity,
        title,
        totalAmount,
      };

      return lineItem;
    });

    return lineItems;
  }
}

injected(
  CheckoutService,
  DI_TOKENS.IPriceCalculationService.Value,
  DI_TOKENS.IWorkstationTypeService.Value,
  DI_TOKENS.IProductService.Value
);

import { useEffect, useMemo } from "react";
import { IWorkstationInstanceSlice } from "../workstationInstance/slice";
import { IQuotingDispatch, IQuotingSlice, IQuotingState } from "./slice";
import { IEquipmentDefinitionSlice } from "../equipmentDefinition/slice";
import { IProductSlice } from "../product/slice";
import { IProductBomItem } from "../../../../application/models/IBomItem";
import { IOrderEmail } from "application/models/IOrderEmail";
import { ICheckoutService } from "../../../../application/services/CheckoutService";
import { ISendOrderService } from "../../../../application/services/SendOrderService";
import { IPriceCalculationService } from "../../../../application/services/PriceCalculationService";
import { Price } from "../../../../common/models/price";
import { CustomerDetails } from "../../../models/customerDetails";
import { StrictOmit } from "common/types/strictOmit";

export function useQuotingReducer(
  services: {
    priceCalculationService: IPriceCalculationService;
    checkoutService: ICheckoutService;
    sendOrderService: ISendOrderService;
  },
  quotingSlice: IQuotingSlice,
  workstationSlice: IWorkstationInstanceSlice,
  equipmentDefinitionSlice: IEquipmentDefinitionSlice,
  productSlice: IProductSlice
): IQuotingReducer {
  const { priceCalculationService, sendOrderService, checkoutService } =
    services;
  const { workstationInstances } = workstationSlice.state;
  const { productionLineTotalPrice, pricingCache } = quotingSlice.state;
  const { workstationTypes } = equipmentDefinitionSlice.state;
  const { items: products } = productSlice.state;

  const { setProductionLineTotal, updatePricingCache } = quotingSlice.dispatch;

  const includedComponentDefinitionsMap = useMemo(() => {
    const serialized = JSON.stringify(
      workstationInstances.map((workstation) => {
        return {
          parentId: workstation.id,
          items: workstation.assembly.map((part) => ({
            definitionId: part.definitionId,
            isIncluded: part.isIncluded,
          })),
        };
      })
    );
    return serialized;
  }, [workstationInstances]);

  async function getWorkstationInstancePrice(id: string) {
    const match = workstationInstances.find((x) => x.id === id);

    if (match) {
      const price = await priceCalculationService.getWorkstationInstancePrice(
        match
      );

      return price;
    }

    return null;
  }

  async function getBoM() {
    return await checkoutService.getProductBom(workstationInstances);
  }

  useEffect(() => {
    (async () => {
      const totalPrice =
        await priceCalculationService.getProductionLineTotalPrice(
          workstationInstances
        );
      setProductionLineTotal(totalPrice);
    })();
  }, [includedComponentDefinitionsMap]);

  useEffect(() => {
    updatePricingCache(undefined);
  }, [workstationTypes, products]);

  function getItemPrice(definitionId: string) {
    //TODO: this caching is a bit convoluted
    const cached = pricingCache?.find((x) => x.id === definitionId);

    if (cached) {
      return cached;
    } else {
      const definitions = workstationTypes.flatMap((x) => x.components);

      const productId = definitions.find(
        (x) => x.id == definitionId
      )?.productId;

      if (productId) {
        priceCalculationService.getProductPrice(productId).then((price) => {
          if (price) {
            updatePricingCache((prevState) => {
              let updatedState = prevState ?? [];

              let match = updatedState?.find((x) => x.id == definitionId);

              const item = {
                id: definitionId,
                currencySymbol: price.currencySymbol,
                amount: price.amount,
              };

              if (!match) {
                updatedState = [...updatedState, item];
              } else {
                Object.assign(match, item);
              }

              return updatedState;
            });
          }
        });
      }
    }

    return null;
  }

  return {
    dispatch: {
      ...quotingSlice.dispatch,
      getBoM,
      getItemPrice,
      getWorkstationInstancePrice,
    },
    state: quotingSlice.state,
  };
}

interface IExtraActions {
  getWorkstationInstancePrice: (id: string) => Promise<Price | null>;
  getItemPrice:
    | ((
        componentDefinitionId: string
      ) => { amount: number; currencySymbol: string } | null)
    | undefined;
  getBoM(): Promise<IProductBomItem[]>;
}

export interface IQuotingReducer {
  state: IQuotingState;
  dispatch: StrictOmit<IQuotingDispatch, "setProductionLineTotal"> &
    IExtraActions;
}

import * as THREE from "three";
import { useFrame, useThree } from "@react-three/fiber";
import { useContext, useEffect, useState } from "react";
import type { OrbitControls as OrbitControlsImpl } from "three-stdlib";
import { Vector3 } from "three";
import { useBounds } from "@react-three/drei";
import { FactoryViewportContext } from "../FactoryViewportContext";

export function CameraControlsManager({
  focusPoint,
  controls,
  position,
}: {
  focusPoint?: { x: number; y: number; z: number };
  position?: { x: number; y: number; z: number };
  controls: React.RefObject<OrbitControlsImpl>;
}) {
  const [isRefocusDone, setIsRefocusDone] = useState(true);
  const [isInit, setIsInit] = useState(false);

  const context = useContext(FactoryViewportContext);

  const camera = useThree(({ camera }) => camera);
  const ctrls = controls.current;
  //const cameraPosition = new THREE.Vector3();
  const look = new THREE.Vector3();

  // const { scene } = useThree();

  useEffect(() => {
    setIsRefocusDone(false);
    camera.updateProjectionMatrix();

    ctrls?.update();
  }, [focusPoint]);

  if (ctrls) {
    ctrls.addEventListener("start", (e) => {
      setIsRefocusDone(true);
    });

    ctrls.addEventListener("end", (e) => {
      setIsRefocusDone(true);
      camera.updateProjectionMatrix();
      ctrls?.update();
    });
  }

  //TODO:clean it up: camera pointing at object is mixed with 2D view
  return useFrame((state, delta) => {
    if (ctrls && !focusPoint && !isInit) {
      camera.position.set(
        position?.x ?? 40,
        position?.y ?? 40,
        position?.z ?? 40
      );
      ctrls.target = new Vector3(0, 0, 0);
      camera.updateProjectionMatrix();
      setIsInit(true);
    }

    //todo: if position present it means we do 2d mode

    if (focusPoint && !isRefocusDone) {

      if (!position){
        look.set(focusPoint.x, focusPoint.y + 1, focusPoint.z);
      }
      else {
        look.set(focusPoint.x, focusPoint.y, focusPoint.z);
      }
      
      const interpolationStep = 0.1;

      //the multiplication is for removing orhtographic camera cutoff
      const postitionToTargetLine = new THREE.Vector3(
        focusPoint.x - 0.8,
        focusPoint.y + 1.8,
        focusPoint.z + 1.2
      )
        .sub(look)
        .multiplyScalar(100);

      const desiredPosition = position
        ? new Vector3(position.x, 1000, position.z)
        : postitionToTargetLine.add(look);

      if (ctrls) {
        const interpolatedLook = ctrls.target
          .clone()
          .lerp(look, interpolationStep);

        ctrls.target = interpolatedLook;
      }

      let fitZoom = 300;

      if (context.getModelBounds) {
        const modelBounds = context.getModelBounds();
        const sizeVector = new Vector3();
        modelBounds.getSize(sizeVector);

        const widthFactor = state.size.width/sizeVector.x
        const heightFactor = state.size.height/sizeVector.z

        fitZoom = Math.min(widthFactor,heightFactor)*0.5;

      }

      const desiredZoom = position != null ? fitZoom : 300;

      //clunky way of interpolating a scalar
      const interpolatedZoom = new Vector3(
        state.camera.zoom,
        state.camera.zoom,
        state.camera.zoom
      ).lerp(
        new Vector3(desiredZoom, desiredZoom, desiredZoom),
        interpolationStep
      ).x;

      

      const interpolatedPosition = state.camera.position
        .clone()
        .lerp(desiredPosition, interpolationStep);

      const az = ctrls?.getAzimuthalAngle();
      const azv = new Vector3(az, az, az);

      const interpolatedAngle = azv.lerp(
        new Vector3(0, 0, 0),
        interpolationStep
      );

      if (interpolatedPosition.distanceTo(desiredPosition) <= 0.1 && (!position || (Math.abs(interpolatedAngle.x) <= 0.001 && Math.abs(interpolatedZoom - desiredZoom) <= 0.1 ))) {
        setIsRefocusDone(true);
      }

      state.camera.position.set(
        interpolatedPosition.x,
        interpolatedPosition.y,
        interpolatedPosition.z
      );

      if (position) {
        state.camera.setRotationFromAxisAngle(new Vector3(0, 1, 0), 0);
        ctrls?.setAzimuthalAngle(interpolatedAngle.x);
      }

      if (!position) {
       
      }

      state.camera.zoom = interpolatedZoom;

      state.camera.updateProjectionMatrix();
    }

    if (ctrls) return ctrls.update();
  });
}

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../app-routes/routeConfig";
import TICK from "../../../../assets/tick.png";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: "0.75rem",
  boxShadow: 24,
  p: 4,
  zIndex: 9999,
};

interface SuccessModalProps {
  templateName: string,
  open: boolean;
  setOpen: Function;
}

export default function TemplateSuccessModal({ open, setOpen, templateName }: SuccessModalProps) {
  const handleClose = () => setOpen(!open);
  const navigate = useNavigate();

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEscapeKeyDown
        hideBackdrop
        sx={{ background: "rgba(0,0,0,0.5)" }}
      >
        <Box component="div" sx={style}>
          <Typography
            component="p"
            sx={{ textAlign: "center", marginBottom: "1.8rem" }}
          >
            <img src={TICK} alt="tick" />
          </Typography>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              fontSize: "1.125rem",
              fontWeight: "600",
              textAlign: "center",
              marginBottom: "1rem",
            }}
          >
            Template Created Succesfully
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{
              color: "#6F7074",
              fontSize: "1rem",
              marginBottom: "3.44rem",
              textAlign: "center",
            }}
          >
            {`“${templateName}” template has been saved`}
          </Typography>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{
              width: "100%",
              padding: "0.625rem 1.125rem",
              borderRadius: "0.5rem",
              border: "1px solid #0074a7",
              background: "#0074a7",
              boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
              fontSize: "1rem",
              fontWeight: 600,
              color: "#ffffff",
              textTransform: "capitalize",
              marginTop: 0,
              flex: 1,
            }}
            onClick={() => navigate(ROUTES.CATALOGUE.CONFIGURATION_TEMPLATE.LIST.path)}
          >
            Continue
          </Button>
        </Box>
      </Modal>
    </div>
  );
}

import { Link, useNavigate } from "react-router-dom";
import BG from "../../../assets/login_bg.png";
import LOGO from "../../../assets/logo.png";
import USER from "../../../assets/user_sso.png";
import ARROW from "../../../assets/arrow_down.svg";
import "./Login.scss";
import { Grid } from "@mui/material";
import { ROUTES } from "../../app-routes/routeConfig";
import ArrowForward from "@mui/icons-material/ArrowForward";

const LoginSSO = () => {
  const navigate = useNavigate();

  return (
    <div className="authentication">
      <Grid container spacing={2} alignItems="center">
        <Grid item md={6} sx={{ display: { xs: "none", md: "block" } }}>
          <img src={BG} alt="" className="bgImg" />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <div className="login_selector_main_wrapper">
            <img src={LOGO} alt="" className="img-fluid" />
            <h3 className="mt-3">Welcome back!</h3>
            <p className="para_color">Log in to your account</p>
            <div className="form">
              <div className="sso_user">
                <div className="d-flex align-items-center">
                  <img src={USER} alt="" className="img-fluid user-img" />
                  <div>
                    <h6 className="mb-0 text-start">Sheldon Cooper</h6>
                    <p className="mb-0">sheldon@odin.co</p>
                  </div>
                </div>
                <img src={ARROW} alt="" className="img-fluid" />
              </div>
              <button
                type="button"
                className="mt-3"
                onClick={() => navigate(ROUTES.WELCOME_SCREEN.path)}
              >
                Continue with SSO
              </button>
            </div>
            <p className="para_color">
              Don't have an account? <Link to="/">Contact Admin</Link>
            </p>
            <Link to="/login" className="ssoLogin">
              Login with Email <ArrowForward fontSize="small" />
            </Link>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default LoginSSO;

import { createContext, useContext } from "react";
import nameof from "ts-nameof.macro";
import { AppContext, useAppContext } from "../../../app/context";
import { IProduct } from "../../../../application/models/IProduct";
import { TableRequest } from "../../../../infrastructure/paging/paging";
import { IFilteredListResult } from "../../../app/store/pagination/pagination";
import { IProductReducer } from "ui/app/store/product/reducer";

export interface IPricingSheetContext {
  products: IProductReducer;
  getPage: (
    request: TableRequest<IProduct>
  ) => Promise<IFilteredListResult<IProduct>>;
  updateProducts: (products: IProduct[]) => Promise<void>;
  deleteProduct: (id: string) => Promise<void>;
}

export const PricingSheetContext = createContext<
  IPricingSheetContext | undefined
>(undefined);

export function usePricingSheetContext() {
  const name = nameof(PricingSheetContext);
  const ctx = useContext(PricingSheetContext);

  if (!ctx) {
    throw new Error(`${name} must be requested from its ${name}Provider!!!`);
  }

  return ctx;
}

export function PricingSheetContextProvider(props: {
  children: JSX.Element | JSX.Element[];
}) {
  const product = useAppContext().product;

  const { children } = props;
  const { update, deleteItem, getPage } = product.dispatch;

  const ctxImplementation: IPricingSheetContext = {
    products: product,
    getPage,
    updateProducts: update,
    deleteProduct: deleteItem,
  };

  return (
    <PricingSheetContext.Provider value={ctxImplementation}>
      {children}
    </PricingSheetContext.Provider>
  );
}

import { useEffect, useState } from "react";
import { IWorkstationType } from "../../../../application/models/IWorkstationType";
import { IWorkstationTypeService } from "../../../../application/services/WorkstationTypeService";
import { User } from "../../../auth/User";

export function useEquipmentDefinitionSlice(
  user: User | undefined,
  services: {
    workstationTypeService: IWorkstationTypeService;
  }
): IEquipmentDefinitionSlice {
  const { workstationTypeService } = services;

  const [workstationTypes, updateworkstationTypes] = useState<
    IWorkstationType[]
  >([]);

  const [dragAddedEquipmentTypeId, setDragAddedEquipmentTypeId] = useState<
    string | null
  >(null);

  function acceptEquipmentDragAddition(): void {
    setDragAddedEquipmentTypeId(null);
  }

  const beginEquipmentDragAddition = (equipmentTypeId: string) =>
    setDragAddedEquipmentTypeId(equipmentTypeId);

  const [pending, setPending] = useState(true);

  async function updateDefiniton(
    definition: IWorkstationType
  ): Promise<boolean> {
    await workstationTypeService.update(definition);
    //setGeometryModelsPending(true); TODO - in other reducer
    setPending(true);
    return true;
  }

  async function createDefiniton(
    definition: IWorkstationType
  ): Promise<IWorkstationType | undefined> {
    const result = await workstationTypeService.create(definition);
    //setGeometryModelsPending(true); TODO - in other reducer
    setPending(true);
    return result;
  }

  async function deleteDefiniton(id: string): Promise<void> {
    await workstationTypeService.delete(id);
  }

  function fetchWorkstationTypes() {
    setPending(true);
  }

  useEffect(() => {
    if (pending && user)
      workstationTypeService.GetAll().then((fetched) => {
        updateworkstationTypes(fetched);
        setPending(false);
      });
  }, [user, pending]);

  return {
    state: {
      dragAddedEquipmentTypeId,
      isWorkstationTypesPending: pending,
      workstationTypes,
    },
    dispatch: {
      acceptEquipmentDragAddition,
      beginEquipmentDragAddition,
      createDefiniton,
      deleteDefiniton,
      fetchWorkstationTypes,
      updateDefiniton,
      updateWorkstationTypes: updateworkstationTypes,
    },
  };
}

export interface IEquipmentDefinitionDispatch {
  acceptEquipmentDragAddition(): void;
  beginEquipmentDragAddition: (equipmentTypeId: string) => void;
  fetchWorkstationTypes: () => void;
  updateWorkstationTypes: React.Dispatch<
    React.SetStateAction<IWorkstationType[]>
  >;
  updateDefiniton: (definition: IWorkstationType) => Promise<boolean>;
  createDefiniton: (
    definition: IWorkstationType
  ) => Promise<IWorkstationType | undefined>;
  deleteDefiniton: (id: string) => Promise<void>;
}

export interface IEquipmentDefinitionState {
  dragAddedEquipmentTypeId: string | null;
  workstationTypes: IWorkstationType[];
  isWorkstationTypesPending: boolean;
}

export interface IEquipmentDefinitionSlice {
  dispatch: IEquipmentDefinitionDispatch;
  state: IEquipmentDefinitionState;
}

import { injected } from "brandi";
import axios from "axios";
import { IStorageContext } from "../../infrastructure/storage/mockSeedStorageContext";
import { IGeometryModel } from "../models/IGeometryModel";
import DI_TOKENS from "../../DI_TOKENS";
import { IGeometryModelRepository } from "../repositories/IGeometryModelRepository";
import { Model } from "@jmk/ar-toolbox-core/core";
import { GLTFWriter } from "@jmk/ar-toolbox-core/conversion";
import { generateUUID } from "three/src/math/MathUtils";
import { HttpStatusCode } from "../../common/constants/HttpStatusCode";
import { StrictOmit } from "common/types/strictOmit";

export interface IGeometryModelService {
  getList(): Promise<IGeometryModel[]>;

  uploadToBucket(
    modelData: Model,
    fileName: string
  ): Promise<{ resourceUri: string; modelId: string }>;
}

export class GeometryModelService implements IGeometryModelService {
  private _storageContext: IStorageContext;
  private _repository: IGeometryModelRepository;
  private _userId = "";

  public constructor(
    storageContext: IStorageContext,
    repository: IGeometryModelRepository
  ) {
    this._storageContext = storageContext;
    this._repository = repository;
  }

  async uploadToBucket(
    modelData: Model,
    fileName: string
  ): Promise<{ resourceUri: string; modelId: string }> {
    const glb = await new GLTFWriter().toBinaryGLB(modelData);

    if (!glb) {
      throw new Error("Error with converting to .glb");
    }

    const modelId = generateUUID();

    const requestModel: StrictOmit<IGeometryModel, "uri"> = {
      extension: glb.extension,
      fileName: fileName,
      id: modelId,
    };

    const dataBuffer = glb.binary;

    const { uploadUrl, resourceUri } =
      await this._repository.getPresignedUploadUrl(requestModel);

    const response = await axios.put(uploadUrl, dataBuffer, {
      headers: {
        "Content-Type": "model/gltf-binary",
      },
    });

    const isSuccess =
      response.status == HttpStatusCode.OK ||
      response.status == HttpStatusCode.CREATED;

    if (!isSuccess) {
      throw new Error("Error uploading to bucket");
    }

    return { resourceUri, modelId };
  }
  async getList(): Promise<IGeometryModel[]> {
    let result = (await this._repository.getList(this._userId))?.items ?? [];

    if (!result || result.length <= 0) {
      const mocks = await this._storageContext.getGeometryModels();
      result = mocks;
      await this._repository.updateList(mocks, this._userId);
    }

    return result;
  }
}

injected(
  GeometryModelService,
  DI_TOKENS.IStorageContext.Value,
  DI_TOKENS.IGeometryModelRepository.Value
);

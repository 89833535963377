import { IProductDispatch, IProductSlice, IProductState } from "./slice";

export function useProductReducer(productSlice: IProductSlice) : IProductReducer {
  const {state, dispatch} = productSlice;

  let reducer = {state, dispatch}
  return reducer
}

interface IExtraActions {
  
}

export interface IProductReducer {

  state: IProductState, 
  dispatch: IProductDispatch

}



import { StandardTextInput } from "../standardFieldComponents/StandardTextInput";
import { OptionalRecord } from "../../../../common/types/OptionalRecord";
import { IFieldState } from "../fieldMeta/IFieldState";
import { ExtractKeys } from "../../../../common/types/ExtractKeys";
import { SingleFieldMeta } from "../SingleFieldMeta";


export function SingleField<
  TValues extends OptionalRecord<ExtractKeys<TValues>, any> & {
    id?: string;
  }
>({
  label, fieldMeta, fieldState,
}: {
  label?: string;
  fieldMeta: SingleFieldMeta<TValues>;
  fieldState: IFieldState<TValues[keyof TValues]>;
}) {
  const fieldType = fieldMeta.field;

  switch (fieldType) {
    case "text-input":
      return (
        <StandardTextInput
          key={fieldMeta.key}
          label={label}
          fieldName={fieldMeta.key}
          readOnly={fieldMeta.readOnly}
          {...fieldState}
          error={fieldState.error as string | undefined} />
      );

    default:
      throw new Error(fieldType + " not implemented!!");
  }
}

import React, { useEffect, useRef, useState } from 'react';
import { useAuthContext } from '../context';

const SsoLogin = () => {
  const { loginWithSso } = useAuthContext(); 

  const isInProgress = useRef(false);

  useEffect(() => {

    if (!isInProgress.current) {
        console.log("ATTEMPTING LOGIN")
        isInProgress.current = true;
        loginWithSso();
    }
    

  },[isInProgress]);

  // render some loading state until the token is processed
  return <div>Loading...</div>;
}

export default SsoLogin;

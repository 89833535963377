import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  InputLabel,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import Draggable, { ControlPosition } from "react-draggable";
import { Link, useLocation, useMatch, useNavigate } from "react-router-dom";
import { ROUTES, DESIGN_FRAGMENTS } from "../../../app-routes/routeConfig";
import { useWorkPanelContext } from "../context";
import GridIcon from "@mui/icons-material/ViewModule";
import ListIcon from "@mui/icons-material/ViewList";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchIcon from "@mui/icons-material/Search";
import { IWorkstationType } from "../../../../application/models/IWorkstationType";
import SpannerIcon from "../../../../assets/spanner.svg";
import { IPartDefinition } from "application/models/IPartDefinition";

export function WorkstationTypeTiles() {
  const navigate = useNavigate();
  const context = useWorkPanelContext();
  const {
    setScreen,
    clearConnectorSelection,
    isAdditionByConnectorMode,
    addEquipmentByConnector,
  } = context;
  const [dir, setDir] = useState<
    "row" | "row-reverse" | "column" | "column-reverse"
  >("row");

  const workstationTypes = context.workstationTypes;
  const [selectedTileId, setSelectedTileId] = useState(
    workstationTypes.length > 0 ? workstationTypes[0].id : undefined
  );

  const [filteredItems, setFilteredItems] =
    useState<IWorkstationType[]>(workstationTypes);

  const searchEquipments = (e: { target: { value: string } }) => {
    const value = e.target.value;
    const filtered = workstationTypes.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredItems(filtered);
  };

  function handleConfirmClick() {
    if (selectedTileId != null) addEquipmentByConnector(selectedTileId).then(() => {
      setScreen('equipmentInstanceList')
    });
  }

  function handleBackClick() {
    setScreen("equipmentInstanceList");
    clearConnectorSelection();
  }

  return (
    <div className="widget-inside">
      <div className="header">
        <h5>Equipments</h5>
        <Box component={"div"} sx={{ display: "flex", alignItems: "center" }}>
          <Button
            onClick={() =>
              navigate(ROUTES.CATALOGUE.CONFIGURATION_TEMPLATE.LIST.path)
            }
          >
            Templates
          </Button>
          <div className="grid-list">
            <IconButton
              color={dir === "row" ? "success" : "primary"}
              onClick={() => setDir("row")}
            >
              <GridIcon />
            </IconButton>
            <IconButton
              color={dir === "column" ? "success" : "primary"}
              onClick={() => setDir("column")}
            >
              <ListIcon />
            </IconButton>
          </div>
        </Box>
      </div>
      <FormControl sx={{ m: 1 }} variant="outlined" className="search">
        <InputLabel htmlFor="outlined-adornment-password">
          Search for equipments
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          type="text"
          endAdornment={
            <InputAdornment position="end">
              <IconButton edge="end">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
          label="Search for equipments"
          className="search-input"
          onChange={searchEquipments}
        />
      </FormControl>
      {/* {!isConnectorMode && <div>Drag below items into the 3D viewport:</div>} */}
      <div
        className={
          dir === "row"
            ? "equipment-list list-row"
            : "equipment-list list-column"
        }
      >
        {filteredItems.length > 0 ? (
          <Grid container direction={dir} width={"100%"}>
            {filteredItems.map((t, i) => {
              return (
                <Grid
                  onClick={() => {
                    setSelectedTileId(t.id);
                  }}
                  xs={6}
                  item
                >
                  <MediaCard
                    title={t.name}
                    isSelected={t.id === selectedTileId}
                    imageUrl={
                      "/" + (t.imageUri ?? "images/generic_equipment.jpg")
                    }
                    onDragStart={() => {
                      !isAdditionByConnectorMode &&
                        context.beginEquipmentDragAddition(t.id);
                    }}
                    description={t.description}
                    components={t.components}
                  />
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <p>No equipments found</p>
        )}
      </div>

      <div className="equipment-list-btns">
        {isAdditionByConnectorMode && selectedTileId && (
          <Button
            onClick={handleConfirmClick}
            variant="contained"
            color="primary"
          >
            OK
          </Button>
        )}
        <IconButton
          color="primary"
          onClick={handleBackClick}
          className="btn-back"
        >
          <ArrowBackIcon /> <span>{"Back"}</span>
        </IconButton>
      </div>
    </div>
  );
}

function MediaCard(props: {
  imageUrl: string;
  isSelected: boolean;
  title: string;
  description?: string;
  components?: IPartDefinition[];
  onDragStart: () => void;
}) {
  const context = useWorkPanelContext();

  const [pos, setPos] = useState<ControlPosition | undefined>(undefined);

  const getAllPrice = () => {
    const amount = props.components?.map((item) =>
      context.getAssemblyComponentPrice(item.id)
    );

    return amount?.reduce((sum, item) => sum + (item?.amount ?? 0), 0);
  };
  const {isAdditionByConnectorMode} = context;
  const isDraggable = !isAdditionByConnectorMode;

  return (
    <Draggable
      disabled={!isDraggable}
      defaultPosition={{ x: 0, y: 0 }}
      position={pos}
      onStart={(e) => {
        props.onDragStart();
      }}
      onStop={(e, data) => {
        context.acceptEquipmentDragAddition();
          setPos({ x: 0, y: 0 });
      }}
    >

      <Card
        sx={{ m: 2, p: 1 }}
        style={context.isDragAdditionInProgress ? { opacity: 0.1 } : {}}
      >
        <CardMedia
          component="img"
          height="120"
          image={props.imageUrl}
          alt="thumbnail"
          style={{ objectFit: "contain" }}
          draggable={false}
        />

        <CardContent>
          <div className="grid-title">
            <Typography
              style={{
                color: props.isSelected ? "red" : "black",
              }}
              gutterBottom
              variant="h6"
              component="div"
            >
              {props.title}
            </Typography>
            <Typography component={"h6"} className="price">
              ${getAllPrice()}
            </Typography>
          </div>
          <Typography
            variant="body2"
            color="text.secondary"
            className="description"
          >
            {props.description ?? "No description"}
          </Typography>
          <Box
            component={"div"}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            className="price-customize"
          >
            <Typography component={"h6"} className="price">
              ${getAllPrice()}
            </Typography>
            <Box
              component={"div"}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
              className="customize-btn"
            >
              <IconButton color="inherit">
                <img src={SpannerIcon} alt="spanner" />
              </IconButton>
              <Typography component={"h6"} className="customize-txt">
                Customizable
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
      
    </Draggable>
  );
}

/* tslint:disable */
/* eslint-disable */
/**
 * Your API Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AuthenticateResponse
 */
export interface AuthenticateResponse {
    /**
     * 
     * @type {string}
     * @memberof AuthenticateResponse
     */
    'accessTokenExpiration'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticateResponse
     */
    'refreshTokenExpiration'?: string;
    /**
     * 
     * @type {number}
     * @memberof AuthenticateResponse
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AuthenticateResponse
     */
    'userName'?: string;
    /**
     * 
     * @type {RoleEnum}
     * @memberof AuthenticateResponse
     */
    'role'?: RoleEnum;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ComparisonType = {
    None: 0,
    Equals: 1,
    NotEquals: 2,
    GreaterThan: 3,
    GreaterThanOrEqual: 4,
    LessThan: 5,
    LessThanOrEqual: 6,
    Contains: 7
} as const;

export type ComparisonType = typeof ComparisonType[keyof typeof ComparisonType];


/**
 * 
 * @export
 * @interface ComponentDefinitionDto
 */
export interface ComponentDefinitionDto {
    /**
     * 
     * @type {string}
     * @memberof ComponentDefinitionDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentDefinitionDto
     */
    'productId': string;
    /**
     * 
     * @type {string}
     * @memberof ComponentDefinitionDto
     */
    'equipmentDefinitionId': string;
    /**
     * 
     * @type {string}
     * @memberof ComponentDefinitionDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentDefinitionDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ComponentDefinitionDto
     */
    'nodeId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ComponentDefinitionDto
     */
    'isConfigurable': boolean;
}
/**
 * 
 * @export
 * @interface ComponentDefinitionResponse
 */
export interface ComponentDefinitionResponse {
    /**
     * 
     * @type {string}
     * @memberof ComponentDefinitionResponse
     */
    'productId': string;
    /**
     * 
     * @type {string}
     * @memberof ComponentDefinitionResponse
     */
    'equipmentDefinitionId': string;
    /**
     * 
     * @type {string}
     * @memberof ComponentDefinitionResponse
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentDefinitionResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ComponentDefinitionResponse
     */
    'nodeId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ComponentDefinitionResponse
     */
    'isConfigurable': boolean;
    /**
     * 
     * @type {string}
     * @memberof ComponentDefinitionResponse
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface ConfigurationFullDto
 */
export interface ConfigurationFullDto {
    /**
     * 
     * @type {string}
     * @memberof ConfigurationFullDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationFullDto
     */
    'createdTS'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationFullDto
     */
    'updatedTS'?: string;
    /**
     * 
     * @type {number}
     * @memberof ConfigurationFullDto
     */
    'totalAmount': number;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationFullDto
     */
    'currencySymbol': string;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationFullDto
     */
    'screenshotUrl'?: string;
    /**
     * 
     * @type {Array<WorkstationInstanceDto>}
     * @memberof ConfigurationFullDto
     */
    'equipments'?: Array<WorkstationInstanceDto>;
    /**
     * 
     * @type {Array<PartInstanceDto>}
     * @memberof ConfigurationFullDto
     */
    'parts'?: Array<PartInstanceDto>;
}
/**
 * 
 * @export
 * @interface ConfigurationSimpleResponse
 */
export interface ConfigurationSimpleResponse {
    /**
     * 
     * @type {string}
     * @memberof ConfigurationSimpleResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationSimpleResponse
     */
    'createdTS'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationSimpleResponse
     */
    'updatedTS'?: string;
    /**
     * 
     * @type {number}
     * @memberof ConfigurationSimpleResponse
     */
    'totalAmount': number;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationSimpleResponse
     */
    'currencySymbol': string;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationSimpleResponse
     */
    'screenshotUrl'?: string;
}
/**
 * 
 * @export
 * @interface ConfigurationTemplateAddUpdateRequest
 */
export interface ConfigurationTemplateAddUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof ConfigurationTemplateAddUpdateRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationTemplateAddUpdateRequest
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationTemplateAddUpdateRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationTemplateAddUpdateRequest
     */
    'imgUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationTemplateAddUpdateRequest
     */
    'id': string;
    /**
     * 
     * @type {ConfigurationFullDto}
     * @memberof ConfigurationTemplateAddUpdateRequest
     */
    'configuration': ConfigurationFullDto;
}
/**
 * 
 * @export
 * @interface ConfigurationTemplateResponse
 */
export interface ConfigurationTemplateResponse {
    /**
     * 
     * @type {string}
     * @memberof ConfigurationTemplateResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationTemplateResponse
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationTemplateResponse
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationTemplateResponse
     */
    'imgUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationTemplateResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationTemplateResponse
     */
    'configurationId': string;
}
/**
 * 
 * @export
 * @interface ConfigurationUpdateRequest
 */
export interface ConfigurationUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof ConfigurationUpdateRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationUpdateRequest
     */
    'createdTS'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationUpdateRequest
     */
    'updatedTS'?: string;
    /**
     * 
     * @type {number}
     * @memberof ConfigurationUpdateRequest
     */
    'totalAmount': number;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationUpdateRequest
     */
    'currencySymbol': string;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationUpdateRequest
     */
    'screenshotUrl'?: string;
    /**
     * 
     * @type {Array<WorkstationInstanceDto>}
     * @memberof ConfigurationUpdateRequest
     */
    'equipments'?: Array<WorkstationInstanceDto>;
    /**
     * 
     * @type {Array<PartInstanceDto>}
     * @memberof ConfigurationUpdateRequest
     */
    'parts'?: Array<PartInstanceDto>;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationUpdateRequest
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface EquipmentDefinitionAddUpdateRequest
 */
export interface EquipmentDefinitionAddUpdateRequest {
    /**
     * 
     * @type {Array<ComponentDefinitionDto>}
     * @memberof EquipmentDefinitionAddUpdateRequest
     */
    'components': Array<ComponentDefinitionDto>;
    /**
     * 
     * @type {string}
     * @memberof EquipmentDefinitionAddUpdateRequest
     */
    'modelId': string;
    /**
     * 
     * @type {string}
     * @memberof EquipmentDefinitionAddUpdateRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof EquipmentDefinitionAddUpdateRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EquipmentDefinitionAddUpdateRequest
     */
    'imageUri'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EquipmentDefinitionAddUpdateRequest
     */
    'isPublic'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EquipmentDefinitionAddUpdateRequest
     */
    'organizationId'?: number;
    /**
     * 
     * @type {string}
     * @memberof EquipmentDefinitionAddUpdateRequest
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface EquipmentDefinitionListUpdateRequest
 */
export interface EquipmentDefinitionListUpdateRequest {
    /**
     * 
     * @type {Array<EquipmentDefinitionAddUpdateRequest>}
     * @memberof EquipmentDefinitionListUpdateRequest
     */
    'items': Array<EquipmentDefinitionAddUpdateRequest>;
}
/**
 * 
 * @export
 * @interface EquipmentDefinitionResponse
 */
export interface EquipmentDefinitionResponse {
    /**
     * 
     * @type {Array<ComponentDefinitionResponse>}
     * @memberof EquipmentDefinitionResponse
     */
    'components': Array<ComponentDefinitionResponse>;
    /**
     * 
     * @type {string}
     * @memberof EquipmentDefinitionResponse
     */
    'modelId': string;
    /**
     * 
     * @type {string}
     * @memberof EquipmentDefinitionResponse
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof EquipmentDefinitionResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EquipmentDefinitionResponse
     */
    'imageUri'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EquipmentDefinitionResponse
     */
    'isPublic'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EquipmentDefinitionResponse
     */
    'organizationId'?: number;
    /**
     * 
     * @type {string}
     * @memberof EquipmentDefinitionResponse
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface GeometryModelAddUpdateRequest
 */
export interface GeometryModelAddUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof GeometryModelAddUpdateRequest
     */
    'extension': string;
    /**
     * 
     * @type {string}
     * @memberof GeometryModelAddUpdateRequest
     */
    'fileName': string;
    /**
     * 
     * @type {boolean}
     * @memberof GeometryModelAddUpdateRequest
     */
    'isPublic'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GeometryModelAddUpdateRequest
     */
    'organizationId'?: number;
    /**
     * 
     * @type {string}
     * @memberof GeometryModelAddUpdateRequest
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GeometryModelAddUpdateRequest
     */
    'resourceKey': string;
    /**
     * 
     * @type {string}
     * @memberof GeometryModelAddUpdateRequest
     */
    'uri': string;
}
/**
 * 
 * @export
 * @interface GeometryModelListUpdateRequest
 */
export interface GeometryModelListUpdateRequest {
    /**
     * 
     * @type {Array<GeometryModelAddUpdateRequest>}
     * @memberof GeometryModelListUpdateRequest
     */
    'items': Array<GeometryModelAddUpdateRequest>;
}
/**
 * 
 * @export
 * @interface GeometryModelResponse
 */
export interface GeometryModelResponse {
    /**
     * 
     * @type {string}
     * @memberof GeometryModelResponse
     */
    'extension': string;
    /**
     * 
     * @type {string}
     * @memberof GeometryModelResponse
     */
    'fileName': string;
    /**
     * 
     * @type {boolean}
     * @memberof GeometryModelResponse
     */
    'isPublic'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GeometryModelResponse
     */
    'organizationId'?: number;
    /**
     * 
     * @type {string}
     * @memberof GeometryModelResponse
     */
    'id': string;
    /**
     * 
     * @type {GeometryModelUploadStatus}
     * @memberof GeometryModelResponse
     */
    'uploadStatus'?: GeometryModelUploadStatus;
    /**
     * 
     * @type {string}
     * @memberof GeometryModelResponse
     */
    'uri': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const GeometryModelUploadStatus = {
    Undefined: 0,
    Pending: 1,
    Done: 2
} as const;

export type GeometryModelUploadStatus = typeof GeometryModelUploadStatus[keyof typeof GeometryModelUploadStatus];


/**
 * 
 * @export
 * @interface GetGeometryModelUploadUrlRequest
 */
export interface GetGeometryModelUploadUrlRequest {
    /**
     * 
     * @type {string}
     * @memberof GetGeometryModelUploadUrlRequest
     */
    'extension': string;
    /**
     * 
     * @type {string}
     * @memberof GetGeometryModelUploadUrlRequest
     */
    'fileName': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetGeometryModelUploadUrlRequest
     */
    'isPublic'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetGeometryModelUploadUrlRequest
     */
    'organizationId'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetGeometryModelUploadUrlRequest
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface IFilterCriteria
 */
export interface IFilterCriteria {
    /**
     * 
     * @type {string}
     * @memberof IFilterCriteria
     */
    'column'?: string;
    /**
     * 
     * @type {string}
     * @memberof IFilterCriteria
     */
    'value'?: string;
    /**
     * 
     * @type {ComparisonType}
     * @memberof IFilterCriteria
     */
    'comparisonType'?: ComparisonType;
}


/**
 * 
 * @export
 * @interface OrderAddUpdateRequest
 */
export interface OrderAddUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrderAddUpdateRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAddUpdateRequest
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAddUpdateRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAddUpdateRequest
     */
    'telephone'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderAddUpdateRequest
     */
    'company'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderAddUpdateRequest
     */
    'totalAmount': number;
    /**
     * 
     * @type {string}
     * @memberof OrderAddUpdateRequest
     */
    'currencySymbol': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAddUpdateRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAddUpdateRequest
     */
    'id': string;
    /**
     * 
     * @type {ConfigurationFullDto}
     * @memberof OrderAddUpdateRequest
     */
    'configuration': ConfigurationFullDto;
}
/**
 * 
 * @export
 * @interface OrderResponse
 */
export interface OrderResponse {
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    'telephone'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    'company'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderResponse
     */
    'totalAmount': number;
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    'currencySymbol': string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    'configurationId': string;
}
/**
 * 
 * @export
 * @interface PartInstanceDto
 */
export interface PartInstanceDto {
    /**
     * 
     * @type {string}
     * @memberof PartInstanceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartInstanceDto
     */
    'workstationInstanceId': string;
    /**
     * 
     * @type {string}
     * @memberof PartInstanceDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PartInstanceDto
     */
    'nodeName': string;
    /**
     * 
     * @type {boolean}
     * @memberof PartInstanceDto
     */
    'isIncluded': boolean;
    /**
     * 
     * @type {string}
     * @memberof PartInstanceDto
     */
    'definitionId': string;
}
/**
 * 
 * @export
 * @interface PasswordAuthenticateRequest
 */
export interface PasswordAuthenticateRequest {
    /**
     * 
     * @type {string}
     * @memberof PasswordAuthenticateRequest
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof PasswordAuthenticateRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface PresignedBucketUrl
 */
export interface PresignedBucketUrl {
    /**
     * 
     * @type {string}
     * @memberof PresignedBucketUrl
     */
    'uploadUrl': string;
    /**
     * 
     * @type {string}
     * @memberof PresignedBucketUrl
     */
    'resourceKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof PresignedBucketUrl
     */
    'expiration'?: string;
    /**
     * 
     * @type {string}
     * @memberof PresignedBucketUrl
     */
    'resourceUri'?: string;
}
/**
 * 
 * @export
 * @interface ProductAddUpdateRequest
 */
export interface ProductAddUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductAddUpdateRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductAddUpdateRequest
     */
    'imageUri'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductAddUpdateRequest
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ProductAddUpdateRequest
     */
    'price': number;
    /**
     * 
     * @type {string}
     * @memberof ProductAddUpdateRequest
     */
    'currencyIso': string;
    /**
     * 
     * @type {string}
     * @memberof ProductAddUpdateRequest
     */
    'sku': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductAddUpdateRequest
     */
    'isPublic'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductAddUpdateRequest
     */
    'organizationId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductAddUpdateRequest
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface ProductListUpdateRequest
 */
export interface ProductListUpdateRequest {
    /**
     * 
     * @type {Array<ProductAddUpdateRequest>}
     * @memberof ProductListUpdateRequest
     */
    'items': Array<ProductAddUpdateRequest>;
}
/**
 * 
 * @export
 * @interface ProductResponse
 */
export interface ProductResponse {
    /**
     * 
     * @type {string}
     * @memberof ProductResponse
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductResponse
     */
    'imageUri'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductResponse
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ProductResponse
     */
    'price': number;
    /**
     * 
     * @type {string}
     * @memberof ProductResponse
     */
    'currencyIso': string;
    /**
     * 
     * @type {string}
     * @memberof ProductResponse
     */
    'sku': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductResponse
     */
    'isPublic'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductResponse
     */
    'organizationId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductResponse
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface ProductResponseTableListResponse
 */
export interface ProductResponseTableListResponse {
    /**
     * 
     * @type {Array<ProductResponse>}
     * @memberof ProductResponseTableListResponse
     */
    'items': Array<ProductResponse>;
    /**
     * 
     * @type {number}
     * @memberof ProductResponseTableListResponse
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof ProductResponseTableListResponse
     */
    'currentPage'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductResponseTableListResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductResponseTableListResponse
     */
    'filteredCount': number;
}
/**
 * 
 * @export
 * @interface ProductTableRequest
 */
export interface ProductTableRequest {
    /**
     * 
     * @type {number}
     * @memberof ProductTableRequest
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductTableRequest
     */
    'pageSize'?: number;
    /**
     * 
     * @type {Array<SortingInfo>}
     * @memberof ProductTableRequest
     */
    'sort'?: Array<SortingInfo>;
    /**
     * 
     * @type {string}
     * @memberof ProductTableRequest
     */
    'searchTerm'?: string;
    /**
     * 
     * @type {Array<IFilterCriteria>}
     * @memberof ProductTableRequest
     */
    'filters'?: Array<IFilterCriteria>;
}
/**
 * 
 * @export
 * @interface RevokeTokenRequest
 */
export interface RevokeTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof RevokeTokenRequest
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RoleEnum = {
    SalesPerson: 0,
    OrganizationAdmin: 1,
    ProductAdmin: 2
} as const;

export type RoleEnum = typeof RoleEnum[keyof typeof RoleEnum];


/**
 * 
 * @export
 * @enum {string}
 */

export const SortOrder = {
    Ascending: 0,
    Descending: 1
} as const;

export type SortOrder = typeof SortOrder[keyof typeof SortOrder];


/**
 * 
 * @export
 * @interface SortingInfo
 */
export interface SortingInfo {
    /**
     * 
     * @type {string}
     * @memberof SortingInfo
     */
    'column'?: string;
    /**
     * 
     * @type {SortOrder}
     * @memberof SortingInfo
     */
    'order'?: SortOrder;
}


/**
 * 
 * @export
 * @interface WeatherForecast
 */
export interface WeatherForecast {
    /**
     * 
     * @type {string}
     * @memberof WeatherForecast
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof WeatherForecast
     */
    'temperatureC'?: number;
    /**
     * 
     * @type {number}
     * @memberof WeatherForecast
     */
    'temperatureF'?: number;
    /**
     * 
     * @type {string}
     * @memberof WeatherForecast
     */
    'summary'?: string;
}
/**
 * 
 * @export
 * @interface WorkstationInstanceDto
 */
export interface WorkstationInstanceDto {
    /**
     * 
     * @type {string}
     * @memberof WorkstationInstanceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkstationInstanceDto
     */
    'workstationTypeId': string;
    /**
     * 
     * @type {number}
     * @memberof WorkstationInstanceDto
     */
    'insertPointX': number;
    /**
     * 
     * @type {number}
     * @memberof WorkstationInstanceDto
     */
    'insertPointY': number;
    /**
     * 
     * @type {number}
     * @memberof WorkstationInstanceDto
     */
    'insertPointZ': number;
    /**
     * 
     * @type {number}
     * @memberof WorkstationInstanceDto
     */
    'rotationRad': number;
    /**
     * 
     * @type {string}
     * @memberof WorkstationInstanceDto
     */
    'previousInstanceId'?: string;
}

/**
 * ConfigurationTemplatesApi - axios parameter creator
 * @export
 */
export const ConfigurationTemplatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configurationTemplatesDelete: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ConfigurationTemplates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configurationTemplatesGet: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ConfigurationTemplates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configurationTemplatesListGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ConfigurationTemplates/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ConfigurationTemplateAddUpdateRequest} [configurationTemplateAddUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configurationTemplatesPost: async (configurationTemplateAddUpdateRequest?: ConfigurationTemplateAddUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ConfigurationTemplates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(configurationTemplateAddUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ConfigurationTemplateAddUpdateRequest} [configurationTemplateAddUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configurationTemplatesPut: async (configurationTemplateAddUpdateRequest?: ConfigurationTemplateAddUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ConfigurationTemplates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(configurationTemplateAddUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigurationTemplatesApi - functional programming interface
 * @export
 */
export const ConfigurationTemplatesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConfigurationTemplatesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async configurationTemplatesDelete(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.configurationTemplatesDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async configurationTemplatesGet(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigurationTemplateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.configurationTemplatesGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async configurationTemplatesListGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConfigurationTemplateResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.configurationTemplatesListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ConfigurationTemplateAddUpdateRequest} [configurationTemplateAddUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async configurationTemplatesPost(configurationTemplateAddUpdateRequest?: ConfigurationTemplateAddUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigurationTemplateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.configurationTemplatesPost(configurationTemplateAddUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ConfigurationTemplateAddUpdateRequest} [configurationTemplateAddUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async configurationTemplatesPut(configurationTemplateAddUpdateRequest?: ConfigurationTemplateAddUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigurationTemplateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.configurationTemplatesPut(configurationTemplateAddUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConfigurationTemplatesApi - factory interface
 * @export
 */
export const ConfigurationTemplatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConfigurationTemplatesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configurationTemplatesDelete(id?: string, options?: any): AxiosPromise<void> {
            return localVarFp.configurationTemplatesDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configurationTemplatesGet(id?: string, options?: any): AxiosPromise<ConfigurationTemplateResponse> {
            return localVarFp.configurationTemplatesGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configurationTemplatesListGet(options?: any): AxiosPromise<Array<ConfigurationTemplateResponse>> {
            return localVarFp.configurationTemplatesListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ConfigurationTemplateAddUpdateRequest} [configurationTemplateAddUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configurationTemplatesPost(configurationTemplateAddUpdateRequest?: ConfigurationTemplateAddUpdateRequest, options?: any): AxiosPromise<ConfigurationTemplateResponse> {
            return localVarFp.configurationTemplatesPost(configurationTemplateAddUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ConfigurationTemplateAddUpdateRequest} [configurationTemplateAddUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configurationTemplatesPut(configurationTemplateAddUpdateRequest?: ConfigurationTemplateAddUpdateRequest, options?: any): AxiosPromise<ConfigurationTemplateResponse> {
            return localVarFp.configurationTemplatesPut(configurationTemplateAddUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConfigurationTemplatesApi - object-oriented interface
 * @export
 * @class ConfigurationTemplatesApi
 * @extends {BaseAPI}
 */
export class ConfigurationTemplatesApi extends BaseAPI {
    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationTemplatesApi
     */
    public configurationTemplatesDelete(id?: string, options?: AxiosRequestConfig) {
        return ConfigurationTemplatesApiFp(this.configuration).configurationTemplatesDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationTemplatesApi
     */
    public configurationTemplatesGet(id?: string, options?: AxiosRequestConfig) {
        return ConfigurationTemplatesApiFp(this.configuration).configurationTemplatesGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationTemplatesApi
     */
    public configurationTemplatesListGet(options?: AxiosRequestConfig) {
        return ConfigurationTemplatesApiFp(this.configuration).configurationTemplatesListGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ConfigurationTemplateAddUpdateRequest} [configurationTemplateAddUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationTemplatesApi
     */
    public configurationTemplatesPost(configurationTemplateAddUpdateRequest?: ConfigurationTemplateAddUpdateRequest, options?: AxiosRequestConfig) {
        return ConfigurationTemplatesApiFp(this.configuration).configurationTemplatesPost(configurationTemplateAddUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ConfigurationTemplateAddUpdateRequest} [configurationTemplateAddUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationTemplatesApi
     */
    public configurationTemplatesPut(configurationTemplateAddUpdateRequest?: ConfigurationTemplateAddUpdateRequest, options?: AxiosRequestConfig) {
        return ConfigurationTemplatesApiFp(this.configuration).configurationTemplatesPut(configurationTemplateAddUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConfigurationsApi - axios parameter creator
 * @export
 */
export const ConfigurationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configurationsDelete: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Configurations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configurationsGet: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Configurations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configurationsListGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Configurations/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ConfigurationFullDto} [configurationFullDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configurationsPost: async (configurationFullDto?: ConfigurationFullDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Configurations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(configurationFullDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ConfigurationUpdateRequest} [configurationUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configurationsPut: async (configurationUpdateRequest?: ConfigurationUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Configurations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(configurationUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigurationsApi - functional programming interface
 * @export
 */
export const ConfigurationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConfigurationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async configurationsDelete(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.configurationsDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async configurationsGet(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigurationFullDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.configurationsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async configurationsListGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConfigurationSimpleResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.configurationsListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ConfigurationFullDto} [configurationFullDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async configurationsPost(configurationFullDto?: ConfigurationFullDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigurationSimpleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.configurationsPost(configurationFullDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ConfigurationUpdateRequest} [configurationUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async configurationsPut(configurationUpdateRequest?: ConfigurationUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigurationSimpleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.configurationsPut(configurationUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConfigurationsApi - factory interface
 * @export
 */
export const ConfigurationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConfigurationsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configurationsDelete(id?: string, options?: any): AxiosPromise<void> {
            return localVarFp.configurationsDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configurationsGet(id?: string, options?: any): AxiosPromise<ConfigurationFullDto> {
            return localVarFp.configurationsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configurationsListGet(options?: any): AxiosPromise<Array<ConfigurationSimpleResponse>> {
            return localVarFp.configurationsListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ConfigurationFullDto} [configurationFullDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configurationsPost(configurationFullDto?: ConfigurationFullDto, options?: any): AxiosPromise<ConfigurationSimpleResponse> {
            return localVarFp.configurationsPost(configurationFullDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ConfigurationUpdateRequest} [configurationUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configurationsPut(configurationUpdateRequest?: ConfigurationUpdateRequest, options?: any): AxiosPromise<ConfigurationSimpleResponse> {
            return localVarFp.configurationsPut(configurationUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConfigurationsApi - object-oriented interface
 * @export
 * @class ConfigurationsApi
 * @extends {BaseAPI}
 */
export class ConfigurationsApi extends BaseAPI {
    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationsApi
     */
    public configurationsDelete(id?: string, options?: AxiosRequestConfig) {
        return ConfigurationsApiFp(this.configuration).configurationsDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationsApi
     */
    public configurationsGet(id?: string, options?: AxiosRequestConfig) {
        return ConfigurationsApiFp(this.configuration).configurationsGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationsApi
     */
    public configurationsListGet(options?: AxiosRequestConfig) {
        return ConfigurationsApiFp(this.configuration).configurationsListGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ConfigurationFullDto} [configurationFullDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationsApi
     */
    public configurationsPost(configurationFullDto?: ConfigurationFullDto, options?: AxiosRequestConfig) {
        return ConfigurationsApiFp(this.configuration).configurationsPost(configurationFullDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ConfigurationUpdateRequest} [configurationUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationsApi
     */
    public configurationsPut(configurationUpdateRequest?: ConfigurationUpdateRequest, options?: AxiosRequestConfig) {
        return ConfigurationsApiFp(this.configuration).configurationsPut(configurationUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EquipmentDefinitionApi - axios parameter creator
 * @export
 */
export const EquipmentDefinitionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentDefinitionDelete: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/EquipmentDefinition`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentDefinitionListGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/EquipmentDefinition/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EquipmentDefinitionListUpdateRequest} equipmentDefinitionListUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentDefinitionListPut: async (equipmentDefinitionListUpdateRequest: EquipmentDefinitionListUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'equipmentDefinitionListUpdateRequest' is not null or undefined
            assertParamExists('equipmentDefinitionListPut', 'equipmentDefinitionListUpdateRequest', equipmentDefinitionListUpdateRequest)
            const localVarPath = `/EquipmentDefinition/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(equipmentDefinitionListUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EquipmentDefinitionAddUpdateRequest} [equipmentDefinitionAddUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentDefinitionPost: async (equipmentDefinitionAddUpdateRequest?: EquipmentDefinitionAddUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/EquipmentDefinition`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(equipmentDefinitionAddUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EquipmentDefinitionAddUpdateRequest} [equipmentDefinitionAddUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentDefinitionPut: async (equipmentDefinitionAddUpdateRequest?: EquipmentDefinitionAddUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/EquipmentDefinition`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(equipmentDefinitionAddUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EquipmentDefinitionApi - functional programming interface
 * @export
 */
export const EquipmentDefinitionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EquipmentDefinitionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async equipmentDefinitionDelete(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.equipmentDefinitionDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async equipmentDefinitionListGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EquipmentDefinitionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.equipmentDefinitionListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EquipmentDefinitionListUpdateRequest} equipmentDefinitionListUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async equipmentDefinitionListPut(equipmentDefinitionListUpdateRequest: EquipmentDefinitionListUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.equipmentDefinitionListPut(equipmentDefinitionListUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EquipmentDefinitionAddUpdateRequest} [equipmentDefinitionAddUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async equipmentDefinitionPost(equipmentDefinitionAddUpdateRequest?: EquipmentDefinitionAddUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentDefinitionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.equipmentDefinitionPost(equipmentDefinitionAddUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EquipmentDefinitionAddUpdateRequest} [equipmentDefinitionAddUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async equipmentDefinitionPut(equipmentDefinitionAddUpdateRequest?: EquipmentDefinitionAddUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentDefinitionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.equipmentDefinitionPut(equipmentDefinitionAddUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EquipmentDefinitionApi - factory interface
 * @export
 */
export const EquipmentDefinitionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EquipmentDefinitionApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentDefinitionDelete(id?: string, options?: any): AxiosPromise<void> {
            return localVarFp.equipmentDefinitionDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentDefinitionListGet(options?: any): AxiosPromise<Array<EquipmentDefinitionResponse>> {
            return localVarFp.equipmentDefinitionListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EquipmentDefinitionListUpdateRequest} equipmentDefinitionListUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentDefinitionListPut(equipmentDefinitionListUpdateRequest: EquipmentDefinitionListUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.equipmentDefinitionListPut(equipmentDefinitionListUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EquipmentDefinitionAddUpdateRequest} [equipmentDefinitionAddUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentDefinitionPost(equipmentDefinitionAddUpdateRequest?: EquipmentDefinitionAddUpdateRequest, options?: any): AxiosPromise<EquipmentDefinitionResponse> {
            return localVarFp.equipmentDefinitionPost(equipmentDefinitionAddUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EquipmentDefinitionAddUpdateRequest} [equipmentDefinitionAddUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentDefinitionPut(equipmentDefinitionAddUpdateRequest?: EquipmentDefinitionAddUpdateRequest, options?: any): AxiosPromise<EquipmentDefinitionResponse> {
            return localVarFp.equipmentDefinitionPut(equipmentDefinitionAddUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EquipmentDefinitionApi - object-oriented interface
 * @export
 * @class EquipmentDefinitionApi
 * @extends {BaseAPI}
 */
export class EquipmentDefinitionApi extends BaseAPI {
    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentDefinitionApi
     */
    public equipmentDefinitionDelete(id?: string, options?: AxiosRequestConfig) {
        return EquipmentDefinitionApiFp(this.configuration).equipmentDefinitionDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentDefinitionApi
     */
    public equipmentDefinitionListGet(options?: AxiosRequestConfig) {
        return EquipmentDefinitionApiFp(this.configuration).equipmentDefinitionListGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EquipmentDefinitionListUpdateRequest} equipmentDefinitionListUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentDefinitionApi
     */
    public equipmentDefinitionListPut(equipmentDefinitionListUpdateRequest: EquipmentDefinitionListUpdateRequest, options?: AxiosRequestConfig) {
        return EquipmentDefinitionApiFp(this.configuration).equipmentDefinitionListPut(equipmentDefinitionListUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EquipmentDefinitionAddUpdateRequest} [equipmentDefinitionAddUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentDefinitionApi
     */
    public equipmentDefinitionPost(equipmentDefinitionAddUpdateRequest?: EquipmentDefinitionAddUpdateRequest, options?: AxiosRequestConfig) {
        return EquipmentDefinitionApiFp(this.configuration).equipmentDefinitionPost(equipmentDefinitionAddUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EquipmentDefinitionAddUpdateRequest} [equipmentDefinitionAddUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentDefinitionApi
     */
    public equipmentDefinitionPut(equipmentDefinitionAddUpdateRequest?: EquipmentDefinitionAddUpdateRequest, options?: AxiosRequestConfig) {
        return EquipmentDefinitionApiFp(this.configuration).equipmentDefinitionPut(equipmentDefinitionAddUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GeometryModelApi - axios parameter creator
 * @export
 */
export const GeometryModelApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GetGeometryModelUploadUrlRequest} getGeometryModelUploadUrlRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geometryModelGetUploadUrlPost: async (getGeometryModelUploadUrlRequest: GetGeometryModelUploadUrlRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getGeometryModelUploadUrlRequest' is not null or undefined
            assertParamExists('geometryModelGetUploadUrlPost', 'getGeometryModelUploadUrlRequest', getGeometryModelUploadUrlRequest)
            const localVarPath = `/GeometryModel/get-upload-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getGeometryModelUploadUrlRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geometryModelListGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/GeometryModel/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GeometryModelListUpdateRequest} geometryModelListUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geometryModelListPut: async (geometryModelListUpdateRequest: GeometryModelListUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'geometryModelListUpdateRequest' is not null or undefined
            assertParamExists('geometryModelListPut', 'geometryModelListUpdateRequest', geometryModelListUpdateRequest)
            const localVarPath = `/GeometryModel/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(geometryModelListUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GeometryModelApi - functional programming interface
 * @export
 */
export const GeometryModelApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GeometryModelApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {GetGeometryModelUploadUrlRequest} getGeometryModelUploadUrlRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async geometryModelGetUploadUrlPost(getGeometryModelUploadUrlRequest: GetGeometryModelUploadUrlRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PresignedBucketUrl>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.geometryModelGetUploadUrlPost(getGeometryModelUploadUrlRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async geometryModelListGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GeometryModelResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.geometryModelListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GeometryModelListUpdateRequest} geometryModelListUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async geometryModelListPut(geometryModelListUpdateRequest: GeometryModelListUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.geometryModelListPut(geometryModelListUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GeometryModelApi - factory interface
 * @export
 */
export const GeometryModelApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GeometryModelApiFp(configuration)
    return {
        /**
         * 
         * @param {GetGeometryModelUploadUrlRequest} getGeometryModelUploadUrlRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geometryModelGetUploadUrlPost(getGeometryModelUploadUrlRequest: GetGeometryModelUploadUrlRequest, options?: any): AxiosPromise<PresignedBucketUrl> {
            return localVarFp.geometryModelGetUploadUrlPost(getGeometryModelUploadUrlRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geometryModelListGet(options?: any): AxiosPromise<Array<GeometryModelResponse>> {
            return localVarFp.geometryModelListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GeometryModelListUpdateRequest} geometryModelListUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geometryModelListPut(geometryModelListUpdateRequest: GeometryModelListUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.geometryModelListPut(geometryModelListUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GeometryModelApi - object-oriented interface
 * @export
 * @class GeometryModelApi
 * @extends {BaseAPI}
 */
export class GeometryModelApi extends BaseAPI {
    /**
     * 
     * @param {GetGeometryModelUploadUrlRequest} getGeometryModelUploadUrlRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeometryModelApi
     */
    public geometryModelGetUploadUrlPost(getGeometryModelUploadUrlRequest: GetGeometryModelUploadUrlRequest, options?: AxiosRequestConfig) {
        return GeometryModelApiFp(this.configuration).geometryModelGetUploadUrlPost(getGeometryModelUploadUrlRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeometryModelApi
     */
    public geometryModelListGet(options?: AxiosRequestConfig) {
        return GeometryModelApiFp(this.configuration).geometryModelListGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GeometryModelListUpdateRequest} geometryModelListUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeometryModelApi
     */
    public geometryModelListPut(geometryModelListUpdateRequest: GeometryModelListUpdateRequest, options?: AxiosRequestConfig) {
        return GeometryModelApiFp(this.configuration).geometryModelListPut(geometryModelListUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * JmkOdinCfgWebApiVersion1000CultureneutralPublicKeyTokennullApi - axios parameter creator
 * @export
 */
export const JmkOdinCfgWebApiVersion1000CultureneutralPublicKeyTokennullApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routecheckGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Routecheck`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JmkOdinCfgWebApiVersion1000CultureneutralPublicKeyTokennullApi - functional programming interface
 * @export
 */
export const JmkOdinCfgWebApiVersion1000CultureneutralPublicKeyTokennullApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = JmkOdinCfgWebApiVersion1000CultureneutralPublicKeyTokennullApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rootGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rootGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routecheckGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routecheckGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * JmkOdinCfgWebApiVersion1000CultureneutralPublicKeyTokennullApi - factory interface
 * @export
 */
export const JmkOdinCfgWebApiVersion1000CultureneutralPublicKeyTokennullApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JmkOdinCfgWebApiVersion1000CultureneutralPublicKeyTokennullApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootGet(options?: any): AxiosPromise<string> {
            return localVarFp.rootGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routecheckGet(options?: any): AxiosPromise<string> {
            return localVarFp.routecheckGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * JmkOdinCfgWebApiVersion1000CultureneutralPublicKeyTokennullApi - object-oriented interface
 * @export
 * @class JmkOdinCfgWebApiVersion1000CultureneutralPublicKeyTokennullApi
 * @extends {BaseAPI}
 */
export class JmkOdinCfgWebApiVersion1000CultureneutralPublicKeyTokennullApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JmkOdinCfgWebApiVersion1000CultureneutralPublicKeyTokennullApi
     */
    public rootGet(options?: AxiosRequestConfig) {
        return JmkOdinCfgWebApiVersion1000CultureneutralPublicKeyTokennullApiFp(this.configuration).rootGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JmkOdinCfgWebApiVersion1000CultureneutralPublicKeyTokennullApi
     */
    public routecheckGet(options?: AxiosRequestConfig) {
        return JmkOdinCfgWebApiVersion1000CultureneutralPublicKeyTokennullApiFp(this.configuration).routecheckGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrdersApi - axios parameter creator
 * @export
 */
export const OrdersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersDelete: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersGet: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersListGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Orders/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OrderAddUpdateRequest} [orderAddUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPost: async (orderAddUpdateRequest?: OrderAddUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderAddUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OrderAddUpdateRequest} [orderAddUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPut: async (orderAddUpdateRequest?: OrderAddUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderAddUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrdersApi - functional programming interface
 * @export
 */
export const OrdersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrdersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersDelete(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersGet(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersListGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {OrderAddUpdateRequest} [orderAddUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersPost(orderAddUpdateRequest?: OrderAddUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersPost(orderAddUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {OrderAddUpdateRequest} [orderAddUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersPut(orderAddUpdateRequest?: OrderAddUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersPut(orderAddUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrdersApi - factory interface
 * @export
 */
export const OrdersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrdersApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersDelete(id?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ordersDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersGet(id?: string, options?: any): AxiosPromise<OrderResponse> {
            return localVarFp.ordersGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersListGet(options?: any): AxiosPromise<Array<OrderResponse>> {
            return localVarFp.ordersListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrderAddUpdateRequest} [orderAddUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPost(orderAddUpdateRequest?: OrderAddUpdateRequest, options?: any): AxiosPromise<OrderResponse> {
            return localVarFp.ordersPost(orderAddUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrderAddUpdateRequest} [orderAddUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPut(orderAddUpdateRequest?: OrderAddUpdateRequest, options?: any): AxiosPromise<OrderResponse> {
            return localVarFp.ordersPut(orderAddUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrdersApi - object-oriented interface
 * @export
 * @class OrdersApi
 * @extends {BaseAPI}
 */
export class OrdersApi extends BaseAPI {
    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersDelete(id?: string, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersGet(id?: string, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersListGet(options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersListGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrderAddUpdateRequest} [orderAddUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersPost(orderAddUpdateRequest?: OrderAddUpdateRequest, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersPost(orderAddUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrderAddUpdateRequest} [orderAddUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersPut(orderAddUpdateRequest?: OrderAddUpdateRequest, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersPut(orderAddUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductApi - axios parameter creator
 * @export
 */
export const ProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productDelete: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productGet: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProductTableRequest} [productTableRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productGetListPost: async (productTableRequest?: ProductTableRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Product/get-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productTableRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProductListUpdateRequest} productListUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productListPut: async (productListUpdateRequest: ProductListUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productListUpdateRequest' is not null or undefined
            assertParamExists('productListPut', 'productListUpdateRequest', productListUpdateRequest)
            const localVarPath = `/Product/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productListUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductApi - functional programming interface
 * @export
 */
export const ProductApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productDelete(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productGet(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProductTableRequest} [productTableRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productGetListPost(productTableRequest?: ProductTableRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductResponseTableListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productGetListPost(productTableRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProductListUpdateRequest} productListUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productListPut(productListUpdateRequest: ProductListUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productListPut(productListUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductApi - factory interface
 * @export
 */
export const ProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productDelete(id?: string, options?: any): AxiosPromise<void> {
            return localVarFp.productDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productGet(id?: string, options?: any): AxiosPromise<ProductResponse> {
            return localVarFp.productGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductTableRequest} [productTableRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productGetListPost(productTableRequest?: ProductTableRequest, options?: any): AxiosPromise<ProductResponseTableListResponse> {
            return localVarFp.productGetListPost(productTableRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductListUpdateRequest} productListUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productListPut(productListUpdateRequest: ProductListUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.productListPut(productListUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductApi - object-oriented interface
 * @export
 * @class ProductApi
 * @extends {BaseAPI}
 */
export class ProductApi extends BaseAPI {
    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productDelete(id?: string, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).productDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productGet(id?: string, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).productGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductTableRequest} [productTableRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productGetListPost(productTableRequest?: ProductTableRequest, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).productGetListPost(productTableRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductListUpdateRequest} productListUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productListPut(productListUpdateRequest: ProductListUpdateRequest, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).productListPut(productListUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PasswordAuthenticateRequest} [passwordAuthenticateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAuthenticatePost: async (passwordAuthenticateRequest?: PasswordAuthenticateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/User/authenticate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordAuthenticateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/User`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userIdGet', 'id', id)
            const localVarPath = `/User/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRefreshTokenPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/User/refresh-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RevokeTokenRequest} [revokeTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRevokeTokenPost: async (revokeTokenRequest?: RevokeTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/User/revoke-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(revokeTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSsoLoginPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/User/sso-login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PasswordAuthenticateRequest} [passwordAuthenticateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userAuthenticatePost(passwordAuthenticateRequest?: PasswordAuthenticateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userAuthenticatePost(passwordAuthenticateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userRefreshTokenPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userRefreshTokenPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RevokeTokenRequest} [revokeTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userRevokeTokenPost(revokeTokenRequest?: RevokeTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userRevokeTokenPost(revokeTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userSsoLoginPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userSsoLoginPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {PasswordAuthenticateRequest} [passwordAuthenticateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAuthenticatePost(passwordAuthenticateRequest?: PasswordAuthenticateRequest, options?: any): AxiosPromise<AuthenticateResponse> {
            return localVarFp.userAuthenticatePost(passwordAuthenticateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGet(options?: any): AxiosPromise<void> {
            return localVarFp.userGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userIdGet(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.userIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRefreshTokenPost(options?: any): AxiosPromise<AuthenticateResponse> {
            return localVarFp.userRefreshTokenPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RevokeTokenRequest} [revokeTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRevokeTokenPost(revokeTokenRequest?: RevokeTokenRequest, options?: any): AxiosPromise<void> {
            return localVarFp.userRevokeTokenPost(revokeTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSsoLoginPost(options?: any): AxiosPromise<AuthenticateResponse> {
            return localVarFp.userSsoLoginPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {PasswordAuthenticateRequest} [passwordAuthenticateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userAuthenticatePost(passwordAuthenticateRequest?: PasswordAuthenticateRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userAuthenticatePost(passwordAuthenticateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userIdGet(id: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userRefreshTokenPost(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userRefreshTokenPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RevokeTokenRequest} [revokeTokenRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userRevokeTokenPost(revokeTokenRequest?: RevokeTokenRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userRevokeTokenPost(revokeTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userSsoLoginPost(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userSsoLoginPost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WeatherForecastApi - axios parameter creator
 * @export
 */
export const WeatherForecastApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeatherForecast: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/WeatherForecast`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WeatherForecastApi - functional programming interface
 * @export
 */
export const WeatherForecastApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WeatherForecastApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWeatherForecast(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WeatherForecast>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWeatherForecast(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WeatherForecastApi - factory interface
 * @export
 */
export const WeatherForecastApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WeatherForecastApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeatherForecast(options?: any): AxiosPromise<Array<WeatherForecast>> {
            return localVarFp.getWeatherForecast(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WeatherForecastApi - object-oriented interface
 * @export
 * @class WeatherForecastApi
 * @extends {BaseAPI}
 */
export class WeatherForecastApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeatherForecastApi
     */
    public getWeatherForecast(options?: AxiosRequestConfig) {
        return WeatherForecastApiFp(this.configuration).getWeatherForecast(options).then((request) => request(this.axios, this.basePath));
    }
}



import React from "react";
import Box from "@mui/material/Box";
import { Grid, SxProps, Theme } from "@mui/material";

const LayoutBox = ({
  children,
  direction = "vertical",
  sx,
  className,
}: {
  children: React.ReactNode;
  direction?: "horizontal" | "vertical";
  sx?: SxProps<Theme> | undefined;
  className?: string;
}) => {
  const isHorizontal = direction === "horizontal";
  const flexDirection = isHorizontal ? "row" : "column";
  const marginProp = isHorizontal ? { mr: 2 } : { mb: 2 };

  return (
    <Box
      className={`layoutBox-root ${className ?? ""}`}
      component={"div"}
      display="flex"
      width={"100%"}
      flexDirection={flexDirection}
      justifyContent="flex-end"
      sx={{ "& > *:not(:last-child)": marginProp, p: 2, ...sx }}
    >
      {children}
    </Box>
  );
};

export default LayoutBox;

export function GridBox({
  children,
  sx,
  className
}: {
  children: React.ReactNode;
  sx?: SxProps<Theme> | undefined;
  className?: string;
}) {
  return (
    <Grid display={"flex"} container spacing={2} sx={sx} className={`gridBox-root ${className ?? ""}`} >
      {children}
    </Grid>
  );
}

import { Link, useMatch, useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import Add from "@mui/icons-material/Add";
import { ROUTES } from "../../app-routes/routeConfig";
import { newTemplateButtonStyle } from "./styles";
import { BaseHeader } from "./BaseHeader";
import { useAppContext } from "ui/app/context";

export function CatalogueHeader() {
  const appContext = useAppContext();
  const { clearOrder } = appContext.order.dispatch;
  const { clearTemplate } = appContext.predefinedLayout.dispatch;
  const navigate = useNavigate();

  const isProductsMatch = useMatch(ROUTES.ADMIN.PRICING_SHEET);
  const isTemplateMatch = useMatch(
    ROUTES.CATALOGUE.CONFIGURATION_TEMPLATE.LIST
  );
  const isEquipmentMatch = useMatch(ROUTES.ADMIN.EQUIPMENT_DEFINITION.LIST);

  return (
    <BaseHeader
      title={"Catalogue"}
      subtitle={"manage your equipments and templates here"}
    >
      <Box component="div" sx={{ display: "flex", gap: "0.75rem" }}>
        {isProductsMatch && (
          <Button
            component={Link}
            to={`${ROUTES.ADMIN.PRICING_SHEET.path}?type=create`}
            startIcon={<Add />}
            sx={newTemplateButtonStyle}
          >
            Add Products
          </Button>
        )}

        {isTemplateMatch && (
          <Button
            onClick={() => {
              clearTemplate() //todo: move such logic to reducer
              navigate(
                ROUTES.CATALOGUE.CONFIGURATION_TEMPLATE.DESIGN.CREATE.path
              );
            }}
            startIcon={<Add />}
            sx={newTemplateButtonStyle}
          >
            New Template
          </Button>
        )}

        {isEquipmentMatch && (
          <Button
            component={Link}
            to={ROUTES.ADMIN.EQUIPMENT_DEFINITION.LIST.CREATE.path}
            startIcon={<Add />}
            sx={newTemplateButtonStyle}
          >
            Add Equipment
          </Button>
        )}
        {/* <Button
                    component={type === "modal" ? "div" : Link}
                    to={
                      type === "modal"
                        ? "#"
                        : ROUTES.ADMIN.EQUIPMENT_DEFINITION.CREATE.path
                    }
                    startIcon={<Add />}
                    sx={addEquipmentButtonStyle}
                    onClick={() => (type === "modal" ? ()  => {throw new Error("set modal used here")} : {})}
                  >
                    Add Equipment
                  </Button> */}
      </Box>
    </BaseHeader>
  );
}

import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useNavigate } from "react-router-dom";
import { IConfiguration } from "../../../../application/models/IProject";
import { useOrdersContext } from "../context";
import { DataTable } from "../../../library/DataTable";
import { BaseHeader } from "../../../shared/headers/BaseHeader";
import { useFetchData } from "ui/app/hooks/useFetchData";

export function SaleList() {
  const context = useOrdersContext();

  const [orders, status, fetchOrders] = useFetchData(context.getOrders);

  const { handleContinueOrder } = context;

  const handleDeleteClick = (projectId: string) => {};

  const sortedOrders = orders?.sort((a, b) => b.name.localeCompare(a.name));

  return (
    <>
      <div className="container-new">
        <BaseHeader
          title="Projects"
          subtitle="Track your sales, orders, & stats here"
        />
        <DataTable
          data={sortedOrders ?? []}
          columns={["name", 'lastName', 'email', 'company', "totalAmount", "currencySymbol"]}
          handleContinue={({ id }) => {
            handleContinueOrder(id);
          }}
          sx={{ padding: 0, marginTop: "2.25rem" }}
          checkboxSelection={true}
        />
      </div>
    </>
  );
}

export function ProjectCard(
  props: IConfiguration & {
    totalAmount: number;
    handleLoadClick: () => void;
    handleDeleteClick: () => void;
  }
) {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Card sx={{ m: 2, p: 1 }}>
      <Grid container>
        <Grid item md={8}>
          <CardContent>
            <Typography
              align={"left"}
              gutterBottom
              variant="h6"
              component="div"
            >
              {props.name}
            </Typography>
            <Typography
              align={"left"}
              gutterBottom
              variant="h6"
              component="div"
            >
              {`${props.totalAmount} ZAR`}
            </Typography>
            <Typography align={"left"} variant="body2" color="text.secondary">
              {props.updatedTS.toLocaleDateString()}
            </Typography>
          </CardContent>
        </Grid>

        <Grid item container md={4}>
          <Grid item container md={12} justifyContent={"flex-end"}>
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={props.handleDeleteClick}
            >
              <DeleteForeverIcon />
            </IconButton>
          </Grid>
          <Grid item container md={12} justifyContent="flex-end">
            <CardMedia
              component="img"
              height="120"
              image={props.screenshotUrl}
              alt="thumbnail"
              style={{ objectFit: "contain", maxWidth: 120 }}
              draggable={false}
            />
          </Grid>
        </Grid>

        <Grid item container padding={1} md={12} justifyContent={"flex-end"}>
          <Button
            onClick={() => {
              props.handleLoadClick();
              goBack();
            }}
            variant="outlined"
          >
            Load
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
}

import { useFrame } from "@react-three/fiber";
import { useRef } from "react";
import * as THREE from 'three';
import { Vector3 } from "three";

export function LoadingCube(props: { position: Vector3; }) {

    const meshRef = useRef<THREE.Mesh>();
    const materialRef = useRef<THREE.MeshStandardMaterial>();

    const opacity = useRef(0.1);

    let position = props.position;

    if (position) {
        position.y = 1;
    }

    useFrame((state, delta) => {

        const elapsed = state.clock.elapsedTime;

        let sinVal = Math.sin(elapsed * 5) * 0.05;

        if (meshRef?.current) {
            meshRef.current.rotation.y += 0.05;
            meshRef.current.position.y = meshRef.current.position.y += sinVal * 0.2;

        }

        if (materialRef?.current) {
            sinVal = Math.sin(elapsed * 3) * 0.05;
            materialRef.current.opacity = 0.3 + sinVal * 4;
        }

    });

    return (
        <mesh ref={meshRef as any} position={position}>
            <boxBufferGeometry />
            <meshStandardMaterial color={'green'} ref={materialRef as any} transparent />
        </mesh>
    );
}

import { ReactNode } from "react";
import { Box, IconButton, Modal, SxProps, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TICK from "../../../assets/tick.png";

export function AppModal({
  isOpen,
  handleClose,
  children,
  title,
  subTitle,
  type,
}: {
  isOpen: boolean;
  handleClose: () => void;
  children: ReactNode;
  title: string;
  subTitle: string;
  type?: string;
}) {
  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        component="div"
        sx={
          type === "confirm" ? modalContainerConfirmStyle : modalContainerStyle
        }
      >
        {type === "confirm" ? (
          <Box component={"div"} sx={{marginTop: 5}}>
            <Typography
              component="p"
              sx={{ textAlign: "center", marginBottom: "1.8rem" }}
            >
              <img src={TICK} alt="tick" />
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={titleTextStyle}
            >
              {title}
            </Typography>
            <Typography id="modal-modal-description" sx={subTitleTextStyle}>
              {subTitle}
            </Typography>
          </Box>
        ) : (
          <Box
            component="div"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            borderBottom="1px solid #EAECF0"
            padding="1.5rem 1.5rem 1.25rem 1.5rem"
          >
            <Box component="div">
              <Typography component="h3" sx={headerStyle}>
                {title}
              </Typography>
              <Typography component="p" sx={{ marginBottom: 0 }}>
                {subTitle}
              </Typography>
            </Box>
            <IconButton edge="end" onClick={handleClose}>
              <CloseIcon sx={{ color: "#4B5966" }} />
            </IconButton>
          </Box>
        )}
        <Box
          component="div"
          sx={{ p: "1.44rem 1.5rem", maxHeight: '80vh', overflowY: 'auto' }}
          className="equipments-form"
        >
          {children}
        </Box>
      </Box>
    </Modal>
  );
}

export const headerStyle: SxProps = {
  color: "#1E1E1E",
  fontSize: "1.125rem",
  fontWeight: 600,
  marginBottom: 0,
};

export const modalContainerStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "0.75rem",
};

export const modalContainerConfirmStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "0.75rem",
};

const titleTextStyle: SxProps = {
  fontSize: "1.125rem",
  fontWeight: "600",
  textAlign: "center",
  marginBottom: "1rem",
};

const subTitleTextStyle: SxProps = {
  color: "#6F7074",
  fontSize: "1rem",
  marginBottom: "3.44rem",
  textAlign: "center",
};

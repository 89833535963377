import { IFieldState } from "./IFieldState";
import { RenderableFieldMeta } from "./RenderableFieldMeta";

export class RenderableField<
  TRecord,
  K extends keyof TRecord
> extends RenderableFieldMeta<TRecord> {
  key: K;
  renderCell: (params: IFieldState<TRecord[keyof TRecord]>) => React.ReactNode;

  constructor(
    key: K,
    renderCell: (params: IFieldState<TRecord[K]>) => React.ReactNode
  ) {
    super();
    this.renderCell = (x: any) => renderCell(x);
    this.key = key;
  }
}

import { Link, useNavigate } from "react-router-dom";
import BG from "../../../assets/login_bg.png";
import LOGO from "../../../assets/logo.png";
import BACK from "../../../assets/arrow_back.svg";
import "./Login.scss";
import { Grid, Input } from "@mui/material";
import { ROUTES } from "../../app-routes/routeConfig";

const LoginPassword = () => {
  const navigate = useNavigate();

  return (
    <div className="authentication">
      <Grid container spacing={2} alignItems="center">
        <Grid item md={6} sx={{ display: { xs: "none", md: "block" } }}>
          <img src={BG} alt="" className="bgImg" />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <div className="login_selector_main_wrapper">
            <img src={LOGO} alt="" className="img-fluid" />
            <h3>Log in to your account</h3>
            <p className="para_color">
              Welcome back! Please enter your details.
            </p>
            <div className="form">
              <Input
                type="password"
                placeholder="Enter your password"
                className="input"
              />
              <button
                type="button"
                onClick={() => navigate(ROUTES.WELCOME_SCREEN.path)}
              >
                Login
              </button>
              <p className="para_color text-end-forgot">
                <Link to="/">Forgot password</Link>
              </p>
            </div>
            <p className="goback">
              <Link to="/login">
                <img src={BACK} alt="back" className="img-fluid" /> Go Back
              </Link>
            </p>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default LoginPassword;

import React, { useEffect, useRef, useState } from "react";

import "./App.scss";
import { Container } from "@mui/material";
import { IAppContext } from "./app/IAppContext";
import { AppContext } from "./app/context";
import { useAuthContext } from "./auth/context";
import { useServices } from "./app/hooks/useServices";
import { NavBar } from "./app/components/NavBar";
import { AppRouter } from "./app/AppRouter";
import { useStore } from "./app/store/useStore";
import { CustomerDetails } from "./models/customerDetails";

function App() {
  console.log("App rerender");
  const getScreenshotRef = React.useRef<() => { imgUrl: string }>();
  const workPanelOverlayRef = useRef<HTMLDivElement | null>(null);
  const appHolderRef = React.useRef<HTMLDivElement>(null);
  const set2DViewRef = React.useRef<() => void>();

  const services = useServices();

  const { user } = useAuthContext();

  const {
    geometryModel,
    equipmentDefinition,
    product,
    project,
    workstation,
    quoting,
    predefinedLayout,
    order
  } = useStore(user, services, getScreenshotRef);

  //TODO improve initial data fetches. should be handled by store?
  useEffect(() => {
    if (user) {
      geometryModel.dispatch.fetchGeometryModels();
      equipmentDefinition.dispatch.fetchWorkstationTypes();
      product.dispatch.fetch();
      project.dispatch.fetch();
    }
  }, [user?.id]);

  //TODO improve async data fetching, improve waiting for user
  useEffect(() => {
    if (
      !geometryModel.state.pending &&
      !product.state.pending &&
      equipmentDefinition.state.isWorkstationTypesPending &&
      user
    )
      (async () => {
        //TODO: make sure the data is fetched after refactor
        equipmentDefinition.dispatch.fetchWorkstationTypes();
      })();
  }, [
    equipmentDefinition.state.isWorkstationTypesPending,
    workstation.state.pending,
    user?.id,
    geometryModel.state.geometryModels,
    geometryModel.state.pending,
    product.state.pending,
  ]);

  const initContext: IAppContext = {
    product,
    workstation,
    equipmentDefinition,
    geometryModel,
    quoting,
    predefinedLayout,
    user,
    order,
   
    handleRequest2DView: () => {
      if (set2DViewRef.current) {
        set2DViewRef.current();
      }
    },

    uiRefs: {
      workPanelOverlayRef,
      set2DViewRef,
      getScreenshotRef,
      appHolderRef
    }
  };

  const appRouter = (
    <AppRouter />
  );

  return (
    <div
      ref={appHolderRef}
      //this kind of cursor x,y collection is done because of performance issues when dragging into 3D viewport
      onPointerMove={(e) => {
        if (appHolderRef.current) {
          //TODO use ref!!!
          (window as any).actualPointerX = e.clientX;
          (window as any).actualPointerY = e.clientY;
        }
      }}
      className="App"
    >
      <AppContext.Provider value={initContext}>
        <Container
          maxWidth={false}
          sx={{
            padding: "0px",
            margin: "0px",
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
          disableGutters={true}
        >
          {user?.id ? <NavBar>{appRouter}</NavBar> : appRouter}
        </Container>
      </AppContext.Provider>
    </div>
  );
}

export default App;

import { StrictOmit } from "common/types/strictOmit";

export function omit<T, K extends keyof T>(obj: T, ...keys: K[]): StrictOmit<T, K> {
  let result = {} as StrictOmit<T, K>;
  for (let key in obj) {
    if (!keys.includes(key as any as K)) {
      (result as any)[key] = obj[key];
    }
  }
  return result;
}

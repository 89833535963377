import { useNavigate } from "react-router-dom";
import {
  IWorkstationInstanceDispatch,
  IWorkstationInstanceSlice,
  IWorkstationInstanceState,
} from "./slice";

import { IEquipmentDefinitionSlice } from "../equipmentDefinition/slice";
import { IGeometryModelSlice } from "../geometryModel/slice";
import { IPartInstance } from "../../../../application/models/IPartInstance";
import { IWorkstationInstance } from "../../../../application/models/IWorkstationInstance";
import { IProductionLineService } from "../../../../application/services/ProductionLineService";
import OrthonormalVector from "../../../../common/math/OrthonormalVector";
import { Point } from "../../../../common/math/Point";
import { ROUTES, DESIGN_FRAGMENTS } from "../../../app-routes/routeConfig";


interface ExtraActions {
  addWorkstationInstance:
    | ((
        workstationTypeId: string,
        position?: Point
      ) => Promise<{ workstationId: string | undefined }>)
    | undefined;

  getSortedWorkstationInstances: () => {
    modelUrl: string;
    id: string;
    workstationTypeId: string;
    assembly: IPartInstance[];
    insertPointX: number;
    insertPointY: number;
    insertPointZ: number;
    rotationRad: number;
    previousInstanceId: string | undefined;
  }[];
}

export interface IWorkstationInstanceReducer {
  dispatch: IWorkstationInstanceDispatch & ExtraActions;
  state: IWorkstationInstanceState;
}

export function useWorkstationInstanceReducer(
  workstationInstanceSlice: IWorkstationInstanceSlice,
  equipmentTypeSlice: IEquipmentDefinitionSlice,
  geometryModelSlice: IGeometryModelSlice,
  services: { productionLineLayoutService: IProductionLineService }
): IWorkstationInstanceReducer {
  const { workstationInstances, selectedConnector } = workstationInstanceSlice.state;
  const { workstationTypes, dragAddedEquipmentTypeId } =
    equipmentTypeSlice.state;
  const { geometryModels } = geometryModelSlice.state;

  const { updateWorkstationInstances, setSelectedConnector } = workstationInstanceSlice.dispatch;

  const { productionLineLayoutService } = services;

  const navigate = useNavigate();

  const getSortedWorkstations = () => {
    if (workstationTypes.length <= 0) {
      return [];
    }
    const workstationsStartToFinish: IWorkstationInstance[] = [];

    for (let i = 0; i < workstationInstances.length; i++) {
      const prevId = workstationsStartToFinish.at(-1)?.id;

      let current: IWorkstationInstance | undefined = undefined;

      if (prevId == null) {
        const potentialHeads = workstationInstances.filter(
          (x) => x.previousInstanceId == null
        );

        if (potentialHeads.length > 1) {
          throw new Error(
            "Error with layout state - multiple start workstations for a production line"
          );
        }

        current = potentialHeads[0];
      } else {
        current = workstationInstances.find(
          (x) => x.previousInstanceId == prevId
        );
      }

      if (current != null) workstationsStartToFinish.push(current);
    }

    const result = workstationsStartToFinish.map((ws) => {
      const modelId = workstationTypes.find(
        (t) => t.id === ws.workstationTypeId
      )?.modelId!;

      const uri = geometryModels?.find((x) => x.id == modelId)?.uri;

      if (!uri) {
        throw new Error("model uri error for equipmentDefinition " + ws.id);
      }

      const modelUrl = uri;

      return { ...ws, modelUrl: modelUrl };
    });

    return result;
  };

  const addWorkstationInstance = async (
    workstationTypeId: string,
    position?: Point
  ) => {

    const workstationSequence = getSortedWorkstations();

    const previousWorkstationInstanceId = selectedConnector?.workstationId ?? workstationSequence.at(-1)?.id;

    let instance: IWorkstationInstance | null = null;

    if (previousWorkstationInstanceId != null) {
      instance = await productionLineLayoutService.addEquipmentInstance(
        previousWorkstationInstanceId,
        workstationTypeId,
        selectedConnector?.direction,
        workstationSequence,
        position
      );
    } else {
      instance = await productionLineLayoutService.addFirstEquipmentInstance(
        workstationTypeId,
        position
      );
    }
    if (instance != null) {
      updateWorkstationInstances!((s) => [...s, instance!]);
    }

    setSelectedConnector(undefined)

    return {
      workstationId: instance?.id,
    };
  };

  return {
    dispatch: {
      ...workstationInstanceSlice.dispatch,
      addWorkstationInstance,
      getSortedWorkstationInstances: getSortedWorkstations,
    },
    state: workstationInstanceSlice.state,
  };
}

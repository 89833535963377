import { Box, Button, Paper } from "@mui/material";
import { Overlay } from "./Overlay";
import { WorkPanelHolder } from "./WorkPanelHolder";
import { ReactNode } from "react";
import { useAppContext } from "../context";

export function ViewportContainer({
  workPanel,
  viewport,
  workpanelOverlayRef,
}: {
  workPanel: ReactNode;
  viewport: ReactNode;
  workpanelOverlayRef: React.MutableRefObject<HTMLDivElement | null>;
})


{
  const context = useAppContext();

  return (
    <Box
      className="above-canvas"
      key={"main-viewport-holder-container"}
      component={"div"}
      sx={{
        position: "relative",
        width: "100%",
        flexGrow: 1,
        display: "flex",
      }}
    >
      {viewport}
      <Overlay>
        <Paper
          onClick={() => context.handleRequest2DView()}
          style={{
            float: "right",
            width: "100px",
            height: "100px",
            minWidth: "100px",
            pointerEvents: "all",
          }}
        >
          <Button
            sx={{
              width: "100%",
              height: "100%",
              fontSize: "large",
            }}
            variant={"text"}
          >
            2D
          </Button>
        </Paper>

        <WorkPanelHolder
          ref={workpanelOverlayRef}
          sx={{
            height: `100%`,
            width: "25vw",
            pointerEvents: "all",
          }}
        >
          {workPanel}
        </WorkPanelHolder>
      </Overlay>
    </Box>
  );
}

import nameof from "ts-nameof.macro";
import IMockDependency from "./application/IMockDependency";
import { ICheckoutService } from "./application/services/CheckoutService";
import { IConfigurationService } from "./application/services/ConfigurationService";
import IMockService from "./application/services/IMockService";
import { ISendOrderService } from "./application/services/SendOrderService";
import { IPriceCalculationService } from "./application/services/PriceCalculationService";
import { IProductionLineService } from "./application/services/ProductionLineService";
import { IProductService } from "./application/services/ProductService";
import { IWorkstationInstanceService } from "./application/services/WorkstationInstanceService";
import { IWorkstationTypeService } from "./application/services/WorkstationTypeService";
import { KeyedToken } from "./infrastructure/ioc/KeyedToken";
import { IStorageContext } from "./infrastructure/storage/mockSeedStorageContext";
import { IUserService } from "./application/services/UserService";
import { IProductRepository } from "./application/repositories/generic";
import { IRepository } from "./application/repositories/IRepository";
import { IWorkstationType } from "./application/models/IWorkstationType";
import { IGeometryModelService } from "./application/services/GeometryModelService";
import { IGeometryModel } from "./application/models/IGeometryModel";
import { IGeometryModelRepository } from "./application/repositories/IGeometryModelRepository";
import { IOrderService } from "application/services/OrderService";
import { IOrderRepository } from "infrastructure/resource-providers/contracts/order";
import { IProjectRepository } from "infrastructure/resource-providers/contracts/project";
import { IConfigurationTemplateService } from "application/services/ConfigurationTemplateService";
import { IConfigurationTemplateRepository } from "infrastructure/resource-providers/contracts/configurationTemplate";

type IRepository_IWorkstationType = IRepository<IWorkstationType>;
type IRepository_IGeometryModel = IRepository<IGeometryModel>;

const DI_TOKENS = {
  IMockService: KeyedToken.For<IMockService>(nameof<IMockService>()),
  IMockDependency: KeyedToken.For<IMockDependency>(nameof<IMockDependency>()),

  IStorageContext: KeyedToken.For<IStorageContext>(nameof<IStorageContext>()),
  IWorkstationInstanceService: KeyedToken.For<IWorkstationInstanceService>(
    nameof<IWorkstationInstanceService>()
  ),
  IProductionLineService: KeyedToken.For<IProductionLineService>(
    nameof<IProductionLineService>()
  ),
  IPriceCalculationService: KeyedToken.For<IPriceCalculationService>(
    nameof<IPriceCalculationService>()
  ),

  //WORKSTATION TYPE
  IWorkstationTypeService: KeyedToken.For<IWorkstationTypeService>(
    nameof<IWorkstationTypeService>()
  ),
  IRepository_IWorkstationType: KeyedToken.For<IRepository_IWorkstationType>(
    nameof<IRepository_IWorkstationType>()
  ),

  //PRODUCT
  IProductService: KeyedToken.For<IProductService>(nameof<IProductService>()),
  IProductRepository: KeyedToken.For<IProductRepository>(
    nameof<IProductRepository>()
  ),

  ICheckoutService: KeyedToken.For<ICheckoutService>(
    nameof<ICheckoutService>()
  ),
  ISendOrderService: KeyedToken.For<ISendOrderService>(
    nameof<ISendOrderService>()
  ),
  IConfigurationService: KeyedToken.For<IConfigurationService>(
    nameof<IConfigurationService>()
  ),
  IProjectRepository: KeyedToken.For<IProjectRepository>(
    nameof<IProjectRepository>()
  ),

  IUserService: KeyedToken.For<IUserService>(nameof<IUserService>()),

  //MODEL
  IGeometryModelService: KeyedToken.For<IGeometryModelService>(
    nameof<IGeometryModelService>()
  ),
  IGeometryModelRepository: KeyedToken.For<IGeometryModelRepository>(
    nameof<IGeometryModelRepository>()
  ),

  //ORDER
  IOrderService: KeyedToken.For<IOrderService>(nameof<IOrderService>()),
  IOrderRepository: KeyedToken.For<IOrderRepository>(
    nameof<IOrderRepository>()
  ),

  //CONFIGURATION TEMPLATE:
  IConfigurationTemplateService: KeyedToken.For<IConfigurationTemplateService>(
    nameof<IConfigurationTemplateService>()
  ),
  IConfigurationTemplateRepository: KeyedToken.For<IConfigurationTemplateRepository>(
    nameof<IConfigurationTemplateRepository>()
  ),
};

const verifyTokens = () => {
  const errors: string[] = [];

  Object.entries(DI_TOKENS).forEach((entry) => {
    const value = entry[1];

    if (value.Key !== entry[0]) {
      errors.push(entry[0]);
    }
  });

  if (errors.length > 0) {
    throw new TypeError(
      `DI token field names are inconsitent for the following registrations: ${errors.join(
        ", "
      )}`
    );
  }
};

verifyTokens();

export default DI_TOKENS;

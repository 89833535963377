import { createApiProvider } from "./createApiProvider";
import { fromResponse } from "./helpers/fromResponse";
import { OrdersApi } from "infrastructure/api-client";
import { IOrderRepository } from "./contracts/order";
import { configurationMapper } from "application/mappers/configurationMapper";

export function buildOrderProvider(api: OrdersApi) {

  const apiProvider = createApiProvider({
    get: async (id, userId) => await fromResponse(api.ordersGet(id)),
    // getList: async (userId, request) => await fromResponse(api.productGetListPost(request)),
    getList: async (userId, request) => {
      const items = await fromResponse(api.ordersListGet());
      return {
        items,
        filteredCount: items.length,
        totalCount: items.length,
      };
    },
  });

  const repository: IOrderRepository = {
    ...apiProvider,
    create: async (x) => {
      const configurationRequest = configurationMapper.mapModelToRequest(x.configuration);
      const response = await fromResponse(
        api.ordersPost({
          ...x,
          configuration: configurationRequest,
        })
      );
      return response;
    },
    update: async (x) => {
      const configurationRequest = configurationMapper.mapModelToRequest(x.configuration);
      const response = await fromResponse(
        api.ordersPut({
          ...x,
          configuration: configurationRequest,
        })
      );
      return response;
    },
  };

  return repository;
}

import { createContext, useContext } from "react";
import { useAppContext } from "ui/app/context";
import { useNavigate } from "react-router-dom";
import { IOrder } from "application/models/IOrder";
import { ROUTES } from "ui/app-routes/routeConfig";

export interface IOrdersContext {
  handleContinueOrder(orderId: string): void;
  getOrders(): Promise<IOrder[]> //(IConfiguration & { totalAmount: number })[];
  saveCurrentOrder(): void;
}

export const OrdersContext = createContext<IOrdersContext | undefined>(
  undefined
);

export function ProjectsContextProvider(props: {
  children: JSX.Element | JSX.Element[];
}) {
  const navigate = useNavigate();
  const { children } = props;
  const parentContext = useAppContext();

  const { getList: getOrders, continueOrder } = parentContext.order.dispatch;

  const ctxImplementation: IOrdersContext = {
    getOrders,
    handleContinueOrder: (orderId: string) => {
      continueOrder(orderId)
      navigate(ROUTES.SALES_PROCESS.DESIGN.path)
    },
    saveCurrentOrder: () => {},
  };

  return (
    <OrdersContext.Provider value={ctxImplementation}>
      {children}
    </OrdersContext.Provider>
  );
}

export function useOrdersContext() {
  const context = useContext(OrdersContext);

  if (!context) {
    throw new Error(
      "ordersContext must be obtained within a OrdersContextProvider"
    );
  }

  return context;
}

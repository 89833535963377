import {
  IEquipmentDefinitionDispatch,
  IEquipmentDefinitionSlice,
  IEquipmentDefinitionState,
} from "./slice";

export function useEquipmentDefinitionReducer(
  equipmentDefinitionSlice: IEquipmentDefinitionSlice
): IEquipmentDefinitionReducer {
  return { ...equipmentDefinitionSlice };
}

export interface IEquipmentDefinitionReducer {
  state: IEquipmentDefinitionState;
  dispatch: IEquipmentDefinitionDispatch;
}

import { Price } from "../../common/models/price";

export function priceDisplay(input: Price | null | undefined) {
  if (input)
    return `${input.amount.toLocaleString("en-US", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2

    })} ${input.currencySymbol}`;

  return undefined;
}

import { Token, token } from 'brandi';

export class KeyedToken<T> {

  Key: string;
  Value: Token<T>;

  private constructor(key: string) {
    this.Key = key;
    this.Value = token<T>(key);
  }

  static For<T>(key: string): KeyedToken<T> {

    return new KeyedToken<T>(key);
  }
}

import { TextField } from "@mui/material";
import { splitAndCapitalizeCamelCase } from "../../helpers/splitAndCapitalizeCamelCase";
import { debounce } from "lodash";
import { useEffect, useState } from "react";

export function StandardTextInput(props: {
  fieldName: string;
  label?: string /** by default labelm is autogenerated from fieldName */;
  value: string | number | undefined;
  error: string | undefined;
  touched: boolean | undefined;
  handleChange: (v: any) => any;
  readOnly?: boolean;
}) {
  const { value, fieldName, label, error, touched, handleChange, readOnly } =
    props;

  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const debouncedHandler = debounce(handleChange, 300); // Adjust the debounce delay as needed

    // Call the debounced handler whenever the debounced value changes
    debouncedHandler(debouncedValue);

    // Clean up the debounced handler when the component unmounts
    return () => {
      debouncedHandler.cancel();
    };
  }, [debouncedValue, handleChange]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setDebouncedValue(newValue);
  };

  return (
    <TextField
      key={fieldName}
      fullWidth
      id={fieldName}
      name={fieldName}
      label={label ?? splitAndCapitalizeCamelCase(fieldName)}
      value={debouncedValue}
      onChange={handleInputChange}
      error={Boolean(error) ?? false}
      helperText={touched && error}
      inputProps={{
        readOnly,
      }}
      InputProps={{
        className: readOnly ? "Mui-disabled" : undefined,
      }}
    />
  );
}

import type { AxiosPromise } from "axios";
import { IFilteredListResult } from "../../../ui/app/store/pagination/pagination";

export async function fromResponse<T>(request: AxiosPromise<T>) {
    let{ data } = await request;
    data =  nullToUndefined(data)
    return data;
}

//TODO: temporary helper while migrating from getAll to paged results:
export async function getPageFromResponse<T>(request: AxiosPromise<T[]>) : Promise<IFilteredListResult<T>> {
    const items = await fromResponse(request);

    return {
        items,
        totalCount: items.length,
        filteredCount: items.length
    }
}


function nullToUndefined<T>(input: any): any {
    if (input === null) return undefined;
  
    if (Array.isArray(input)) {
        return input.map(nullToUndefined);
    }

    if (typeof input === 'object' && input !== null) {
        return Object.fromEntries(
            Object.entries(input).map(([key, value]) => [key, nullToUndefined(value)])
        );
    }

    return input;
}
import { generateUUID } from "three/src/math/MathUtils";
import { IProduct } from "../../application/models/IProduct";
import { IWorkstationType } from "../../application/models/IWorkstationType";
import products_json from "./mock-data/products.json";
import workstation_types_json from "./mock-data/workstation-types.json";
import { IGeometryModel } from "../../application/models/IGeometryModel";
import { getNameWithExtension } from "../../common/fileHelpers/getNameWithExtension";

let workstationTypeCache: IWorkstationType[] = [];
let productsCache: IProduct[] = [];
let GeometryModelsCache: IGeometryModelResource[] = [];

type IGeometryModelResource = IGeometryModel & {resourceKey: string};
export interface IStorageContext {
  getWorkstationTypes(): Promise<IWorkstationType[]>;

  getProducts(): Promise<IProduct[]>;

  getGeometryModels(): Promise<IGeometryModelResource[]>;
}

export class BrowserStaticStorageContext implements IStorageContext {
  constructor() {
    this.initialize();
  }

  async getGeometryModels(): Promise<IGeometryModelResource[]> {
    if (GeometryModelsCache.length > 0) {
      return GeometryModelsCache;
    }

    const deserialized = workstation_types_json;

    const result: IGeometryModelResource[] = deserialized.map((x) => {

      const [fname,extension] = getNameWithExtension(x.modelUrl);

      if (!extension){
        throw new Error('couldnt parse extension')
      }

      return {
        id: x.geometryModelId,
        uri: x.modelUrl,
        fileName: fname,
        extension,
        resourceKey: `${fname}.${extension}_${generateUUID()}`,
        isPublic: true
      };
    });

    GeometryModelsCache = result;

    return Promise.resolve(result);
  }



  async getProducts(): Promise<IProduct[]> {
    if (productsCache.length > 0) {
      return productsCache;
    }

    const deserialized: IProduct[] = products_json;

    deserialized.forEach(x => x.isPublic = true)

    productsCache = deserialized;

    return deserialized;
  }

  async getWorkstationTypes(): Promise<IWorkstationType[]> {
    if (workstationTypeCache.length > 0) {
      return workstationTypeCache;
    }

    const models = await this.getGeometryModels();

    const products = await this.getProducts();

    const deserialized = workstation_types_json;

    const result = deserialized.map((t) => {
      const workstationType: IWorkstationType = {
        name: t.name,
        id: t.id,
        modelId: models.find((x) => x.uri == t.modelUrl)?.id!,
        imageUri: t.imageUri,
        isPublic: true,

        components: t.components
          .map((componentDefinition) => {
            const product = products.find(
              (p) => p.id === componentDefinition.productId
            );

            if (product)
              return {
                id: componentDefinition.id,
                isConfigurable: componentDefinition.isConfigurable,
                productId: componentDefinition.productId,
                name: product.name,
                nodeId: componentDefinition.nodeName,
              };

            return null;
          })
          .filter((x) => x != null)
          .map((x) => x!),
      };

      return workstationType;
    });

    workstationTypeCache = result;

    return result;
  }

  private async initialize() {
    if (productsCache.length <= 0) {
      productsCache = await this.getProducts();
    }

    if (GeometryModelsCache.length <= 0) {
      GeometryModelsCache = await this.getGeometryModels();
    }

    if (workstationTypeCache.length <= 0) {
      workstationTypeCache = await this.getWorkstationTypes();
    }
  }
}


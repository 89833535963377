import { useInjection } from "brandi-react";
import DI_TOKENS from "../../../DI_TOKENS";


export function useServices() {

  const services = {
    workstationInstanceService: useInjection(
      DI_TOKENS.IWorkstationInstanceService.Value
    ),
    productionLineLayoutService: useInjection(
      DI_TOKENS.IProductionLineService.Value
    ),
    workstationTypeService: useInjection(
      DI_TOKENS.IWorkstationTypeService.Value
    ),
    priceCalculationService: useInjection(
      DI_TOKENS.IPriceCalculationService.Value
    ),
    checkoutService: useInjection(DI_TOKENS.ICheckoutService.Value),
    sendOrderService: useInjection(DI_TOKENS.ISendOrderService.Value),
    configurationService: useInjection(DI_TOKENS.IConfigurationService.Value),
    productService: useInjection(DI_TOKENS.IProductService.Value),
    geometryModelService: useInjection(
      DI_TOKENS.IGeometryModelService.Value
    ),
    orderService: useInjection(DI_TOKENS.IOrderService.Value),
    configurationTemplateService: useInjection(DI_TOKENS.IConfigurationTemplateService.Value)
  };

  return services;

}

import DI_TOKENS from "DI_TOKENS";
import { configurationMapper } from "application/mappers/configurationMapper";
import { IConfigurationState } from "application/models/IConfigurationState";
import { IOrder } from "application/models/IOrder";
import { IConfiguration } from "application/models/IProject";
import { injected } from "brandi";
import { IOrderRepository } from "infrastructure/resource-providers/contracts/order";
import { generateUUID } from "three/src/math/MathUtils";
import { CustomerDetails } from "ui/models/customerDetails";

export interface IOrderService {
  getList(userId: string): Promise<IOrder[]>;
  get(
    orderId: string,
    userId: string
  ): Promise<(IOrder & { configurationId: string }) | undefined>;
  create(
    order: IOrder,
    configurationState: IConfigurationState & {
      id?: string;
    },
    userId: string
  ): Promise<IOrder | undefined>;
  update(
    order: IOrder,
    configurationState: IConfigurationState & {
      id?: string;
    },
    userId: string
  ): Promise<IOrder | undefined>;
}

export class OrderService implements IOrderService {
  private _repository: IOrderRepository;

  public constructor(repository: IOrderRepository) {
    this._repository = repository;
  }
  public async get(orderId: string, userId: string) {
    const order = await this._repository.get(orderId, userId);
    return order;
  }
  public async getList(userId: string): Promise<IOrder[]> {
    const { items } = await this._repository.getList(userId);
    return items;
  }

  public async create(
    order: IOrder,
    state: IConfigurationState & {
      id?: string;
    },
    userId: string
  ) {
    return this.createUpdateOrder(order, state, userId, true);
  }

  public async update(
    order: IOrder,
    state: IConfigurationState & {
      id?: string;
    },
    userId: string
  ) {
    return this.createUpdateOrder(order, state, userId, false);
  }

  private async createUpdateOrder(
    order: IOrder,
    state: IConfigurationState & {
      id?: string;
    },
    userId: string,
    isCopy: boolean = false
  ) {
    let updatedOrder: IOrder | undefined;
    const mappedConfiguration = configurationMapper.mapStateToModel(
      state,
      isCopy
    );

    const configuration = { ...mappedConfiguration };
    const model: OrderWithConfiguration = {
      ...order,
      configuration,
    };

    if (isCopy) {
      updatedOrder = await this._repository.create(model, userId);
    } else {
      updatedOrder = await this._repository.update(model, userId);
    }

    return updatedOrder;
  }
}

type OrderWithConfiguration = IOrder & { configuration: IConfiguration };

injected(OrderService, DI_TOKENS.IOrderRepository.Value);
